import React, { Fragment } from "react";
import {
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

class buttonsWebhook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateModal: false,
      URLwebhook: "",
    };
  }

  componentDidMount() {
    this.setState({
      URLwebhook: this.props.url_webhook,
    });
  }

  openModal = () => {
    this.setState({
      stateModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      stateModal: false,
    });
  };

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.stateModal}
          toggle={this.closeModal} //
          centered
        >
          <MDBModalHeader
            toggle={this.closeModal}
            className="color_select white-text"
            titleClass="w-100"
          >
            URL webhook
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              value={this.state.URLwebhook}
            />
          </MDBModalBody>
        </MDBModal>
        <MDBContainer className="center-button">
          <MDBBtn
            tag="a"
            className="btn-square color_btn-d "
            color="infoa"
            onClick={this.openModal}
          >
            <MDBIcon far icon="eye" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default buttonsWebhook;
