export default [
  {
    label: "Time",
    field: "hora_actividad",
    sort: "desc",
    minimal: "size-col-15",
  },
  {
    label: "Status",
    field: "estado",
    sort: "asc",
    minimal: "size-col-15",
  },
  {
    label: "Status_api",
    field: "estado_api",
    sort: "asc",
    minimal: "size-col-10",
  },
];
