import React from "react";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBSpinner,
  MDBModalBody,
  MDBCardHeader,
  MDBDataTable,
  MDBInput,
  MDBSelect,
} from "mdbreact";

import Config_table from "./06-config_table_webhook";

import api from "../../helpers/api";
import Buttons_webhook from "./07-buttons_webhook";

class User_webhook extends React.Component {
  state = {
    load_data_state: false,
    data_table: "",
  };

  async componentDidMount() {
    try {
      this.setState({
        load_data_state: false,
      });
      const res = await api("user_booking_system", "application/json");
      // console.log(res);
      let a = [];
      res.data.data.map((val) => {
        a.push({
          booking_system: val.booking_system,
          url_webhook: val.url_webhook,
          buttons: <Buttons_webhook url_webhook={val.url_webhook} />,
        });
      });
      this.setState({
        data_table: {
          columns: Config_table,
          rows: a,
        },
        load_data_state: true,
      });
    } catch (e) {
      // console.log("error",e)
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.load_data_state ? (
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select text-left">
              User webhook
            </MDBCardHeader>
            <MDBModalBody>
              <MDBDataTable
                striped
                bordered
                small
                paging={false}
                btn
                maxHeight="300px"
                // reponsiveSm
                className="table_warp"
                data={this.state.data_table}
                // custom={"table_warp"}
                fixed
              ></MDBDataTable>
            </MDBModalBody>
          </MDBCard>
        ) : (
          <MDBRow center style={{ height: "80vh" }}>
            <MDBCol lg="3">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        )}
      </React.Fragment>
    );
  }
}

export default User_webhook;
