import React, { useState, Fragment } from "react";
import getJWT from "../helpers/jwt";
import getJWT_admin from "../helpers/jwt_ADMIN";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBCard,
  MDBCardBody,
  ToastContainer,
} from "mdbreact";
import "../index.css";
// import {useForm} from 'react-hook-form';
import axios from "axios";
import { Redirect } from "react-router-dom";
import logo from "../logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "../source-pack/pro/Toast/Toast";
import moment from "moment";
import api from "../helpers/api";

import ReactGA from "react-ga";
import config from "../config";

const grecaptchaObject = window.grecaptcha;

const Login_ = ({ Component }) => {
  const jwt = getJWT();
  const jwt_ADMIN = getJWT_admin();
  const [email_value, email] = useState(null);
  const [password_value, password] = useState(null);
  const [text_user, setText_user] = useState(null);
  const [validated_class, setValidate] = useState("");
  const [token_, setToken_] = useState(false);
  const [token_admin, setToken_admin] = useState(false);
  const [token_captcha, setToken_captcha] = useState("");
  const [date_update, setDate_update] = useState("V1.0.101");
  const [validated_captcha, setValidated_captcha] = useState(true);
  const recaptchaRef = React.useRef();

  function change(e) {
    // email(e.target.value);
    //password(e.target.value);

    if (e.target.name === "email") {
      email(e.target.value);
    } else {
      password(e.target.value);
    }
  }

  function onChange(value) {
    setToken_captcha(value);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    event.target.className += " was-validated";
    const validate_email = validateEmail(email_value);

    if (validate_email && password && token_captcha !== "") {
      const res = await api("login", "application/json", {
        user_name: email_value,
        password: password_value,
        token_captcha: token_captcha,
      });

      if (email_value === "admin") {
        if (res.data.token) {
          localStorage.setItem("jwt_ADMIN", res.data.token);
          ReactGA.initialize(config.googleAnalytics);

          ReactGA.pageview("/login_admin" + " " + email_value);
          setToken_admin(true);
        } else {
          setText_user("no existe");
          setValidate("is-invalid");
          event.target.className = "";
          recaptchaRef.current.reset();
        }
      } else {
        if (res.data.token) {
          localStorage.setItem("jwt", res.data.token);
          localStorage.setItem("SMSStatus", false);
          ReactGA.initialize(config.googleAnalytics);

          ReactGA.pageview("/login_user" + " " + email_value);
          setToken_(true);
        } else {
          setText_user("no existe");
          setValidate("is-invalid");
          event.target.className = "";
          recaptchaRef.current.reset();
        }
      }
    }
    if (token_captcha === "") {
      toast.error("Captcha is necesary", {
        closeButton: true,
      });
    }
  };

  function validateEmail(email) {
    /*
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);*/
    return email;
  }

  if (token_ || jwt) {
    return (
      <div>
        <Redirect to="/a" />
      </div>
    );
  } else if (token_admin || jwt_ADMIN) {
    return (
      <div>
        <Redirect to="/admin" />
      </div>
    );
  } else {
    console.log("date_update :>> ", date_update);
    return (
      <Fragment>
        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />
        <MDBRow center style={{ height: "80vh" }}>
          <MDBCol sm="12" className="text-center" />
          <MDBCol sm="6" md="4" className="text-center">
            <MDBContainer>
              <MDBCard>
                <div>
                  <img
                    src={logo}
                    className="img-fluid"
                    style={{ justifyContent: "center", alignItems: "center" }}
                    alt={"logo"}
                  />
                </div>
                <div className="header pt-3 lighten-2 color_select">
                  <h3 className="white-text mt-3 mb-4 pb-1 mx-5 text-center">
                    Welcome
                  </h3>
                </div>

                <MDBCardBody className="mx-4">
                  <form
                    onSubmit={onSubmit}
                    className="needs-validation"
                    noValidate
                  >
                    <MDBInput
                      label="Username"
                      name="email"
                      onChange={change}
                      required
                      className={validated_class}
                    >
                      <div
                        className="invalid-feedback"
                        style={{ textAlign: "end" }}
                      >
                        {validated_class
                          ? "incorrect username o password try?"
                          : "Insert a valid username."}
                      </div>
                    </MDBInput>

                    <MDBInput
                      label="Password"
                      group
                      type="password"
                      containerClass="mb-0"
                      onChange={change}
                      name="password"
                      required
                      className={validated_class}
                    >
                      <div
                        className="invalid-feedback"
                        style={{ textAlign: "end" }}
                      >
                        {validated_class ? "" : "Insert password."}
                      </div>
                    </MDBInput>
                    <div>
                      <ReCAPTCHA
                        className="g-recaptcha"
                        sitekey="6LdfiBoaAAAAAGU0vepRDSgPxhb9bWf40EGUSTSJ"
                        onChange={onChange}
                        ref={recaptchaRef}
                      />
                    </div>

                    <div className="text-center mb-4 mt-5">
                      <MDBBtn
                        color="info"
                        type="submit"
                        className="btn-block z-depth-2 color_select"
                      >
                        Log in
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
              Version:{date_update}
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </Fragment>
    );
  }
};

export default Login_;

/**
 * 
 * self.addEventListener('install', event => {
  self.skipWaiting();

});

*/
