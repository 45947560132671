import axios from "axios";
import { config } from "../config/config";
import getJWT from "../helpers/jwt";
const api = config.backendApiUrl;

const ApiUser = async (point, contentType, data) => {
  console.log("api :>> ", api);
  console.log("point :>> ", point);
  const jwt = getJWT();
  try {
    return await axios.post(`${api}${point}`, data, {
      headers: {
        "Content-Type": contentType, //'application/json', multipart/form-data
        Authorization: `Bearer ${jwt}`,
      },
    });
  } catch (e) {
    if (e.response.data.message === "logout") {
      window.location.href = "/logout";
    }
  }
};

export default ApiUser;
