import React from "react";

import { toast, ToastContainer, MDBCol } from "mdbreact";

import "../index.css";
import SideNavPage from "../components/nav_side";
import TopNavigationAdmin from "../components/nav_topAdmin";


import ReportInformationPage from "../components/17-reports/00-monitoringReportsPage";
//import ReportInformationPage from "../components/17-reports/00-monitoringReportsPage"
//import ReportInformationPage from "../components/11-monitoringMicroservice/00-monitoringMicroservicePage";
import { Redirect } from "react-router-dom";
import SideNavPageAdmin from "../components/nav_sideAdmin";

class reportInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: "",
      sideNavToggled: false,
      breakWidth: 1024,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    const SMSUser = localStorage.getItem("SMSStatus");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  toggleSideNav = () => {
    // console.log("click on hamburger button,this.state.sideNavToggled)
    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled,
      });
    }
  };

  render() {
    const dynamicLeftPadding = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0",
    };
    return (
      <React.Fragment>
        <SideNavPageAdmin triggerOpening={this.state.sideNavToggled} />

        <div className="flexible-content white-skin">
          <TopNavigationAdmin onSideNavToggleClick={this.toggleSideNav} />
          <main style={{ ...dynamicLeftPadding, margin: "5rem 0% 6rem" }}>
            {this.state.redirect ? <Redirect to="/logout" /> : ""}

            <MDBCol size="12" className="text-center">
              <ReportInformationPage />
            </MDBCol>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default reportInformation;
