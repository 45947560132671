import React from "react";

import { MDBSideNav } from "mdbreact";

import CustomComponent from "../components/custom";

import moment from "moment";
import { config } from "../config/config";

class SideNavPage extends React.Component {
  state = {
    isOpen: false,
    admin: false,
    userIdSpecial: 15008023,
    userId: null,
  };

  componentDidMount() {
    const userId = localStorage.getItem("userId");

    this.setState({
      userId: parseInt(userId),
    });
  }
  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      sideNavToggled: true,
    });
  };

  handleClick = (e) => {
    e.stopPropagation();
    // Waves - Get Cursor Position
    let cursorPos = {
      top: e.clientY,
      left: e.clientX,
      time: Date.now(), // time indicates particular clicks
    };
    this.setState({ cursorPos: cursorPos });
  };
  //changes
  render() {
    let chatLink = config.chatAppUrl;
    if (this.state.userId === this.state.userIdSpecial) {
      chatLink = config.chat2AppUrl;
    }

    const { isOpen } = this.state;
    const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    return (
      <MDBSideNav
        logo="/logo.png"
        mask="strong"
        fixed
        breakWidth={1024}
        style={{ transition: "padding-left .3s" }}
        className="grey lighten-4"
        triggerOpening={this.props.triggerOpening}
        href="/"
      >
        <ul className="side-nav_p">
          <li className="waves-effect line_a ">
            <CustomComponent
              title="Automated Message"
              icon="bell"
              type="far"
              link="/a"
            />
          </li>

          <li className="waves-effect line_a">
            <CustomComponent title="Chatbot" icon="robot" type="fa" link="/b" />
          </li>

          <li className="waves-effect line_a">
            <CustomComponent
              title="Products"
              icon="product-hunt"
              type="fab"
              link="/products"
            />
          </li>

          <li className="waves-effect line_a">
            <CustomComponent
              title="Chat"
              icon="comments"
              type="far"
              link={chatLink}
            />
          </li>

          <li className="waves-effect line_a">
            <CustomComponent
              title="Broadcast Message"
              icon="intercom"
              type="fab"
              link="/broadcasting_message"
            />
          </li>

          <li className="waves-effect line_a">
            <CustomComponent
              title="Bookings"
              icon="calendar-check"
              type="far"
              link="/bookings"
            />
          </li>

          <li className="waves-effect line_a">
            <CustomComponent
              title="Status SMS"
              icon="envelope p-2"
              type="far"
              link="/status_sms"
            />
          </li>

          <li className="waves-effect line_a">
            {/* <CustomComponent
              title="Dashboard"
              icon="chart-bar p-2"
              type="far"
              link={`/dashboard/${startDate}/${endDate}`}
            /> */}
            <CustomComponent
              title="Dashboard"
              icon="chart-bar p-2"
              type="far"
              link={`/dashboard`}
            />
          </li>
        </ul>
      </MDBSideNav>
    );
  }
}

export default SideNavPage;
