const getJWTAdmin = () => {
  // console.log("execute jwt");
  const tk = localStorage.getItem("jwt_ADMIN"); //complete token
  if (tk === undefined || tk === null) {
    // console.log("--false");
    return false;
  } else {
    // console.log("--true", tk);
    return tk;
  }
};

export default getJWTAdmin;
