export default [
  {
    label: "Code / Internal",
    field: "productCode",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "System",
    field: "system",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Product Name",
    field: "productName",
    sort: "asc",
    minimal: "size-col-50",
  },
  {
    label: "Active",
    field: "active",
    sort: "asc",
    minimal: "size-col-10",
  },
  // {
  //   label: "Ignore Template MSG/Chatbot",
  //   field: "ignore",
  //   sort: "asc",
  //   minimal: "size-col-10",
  // },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-30 ",
  },
];
