import React from "react";
import getJWT from "../helpers/jwt";
import getJWT_admin from "../helpers/jwt_ADMIN";
// import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useJwt } from "react-jwt";
import api from "../helpers/api";
import moment from "moment";
// import Main from '../pages/main'
import ReactGA from "react-ga";
import config from "../config";

const Auth_ = ({ Component }) => {
  const jwt = getJWT();
  const { decodedToken, isExpired, reEvaluateToken } = useJwt(jwt);

  const adminUser = localStorage.getItem("adminUser");

  if (decodedToken !== null && adminUser !== null) {
    ReactGA.initialize(config.googleAnalytics);
    ReactGA.pageview(
      window.location.pathname +
        " " +
        adminUser +
        " " +
        decodedToken.user
    );
  }
  if (decodedToken !== null && adminUser === null) {
    ReactGA.initialize(config.googleAnalytics);
    ReactGA.pageview(window.location.pathname + " " + decodedToken.user);
  }

  //console.log(load_data());
  //    console.log("11111111",a)
  //   console.log("jwt 11111111111",jwt,Component,decodedToken);
  if (!jwt) {
    // console.log("jwt..",jwt)
    return <Redirect to="/login" />;
  }
  return <Component user_data={"demo"} />;
};

export default Auth_;
