import React from "react";

import { withRouter, Redirect } from "react-router-dom";

import getJWT from "../helpers/jwt";
import getJWT_admin from "../helpers/jwt_ADMIN";

const Logout_ = () => {
  // console.log("jwt--logout");
  localStorage.removeItem("jwt");
  localStorage.removeItem("jwt_ADMIN");
  localStorage.removeItem("SMSStatus");
  localStorage.removeItem("adminUser");
  localStorage.removeItem("productId");
  localStorage.removeItem("productName");
  const jwt = getJWT();
  const jwt_ADMIN = getJWT_admin();
  // console.log("jwt data",jwt)
  if (!jwt && !jwt_ADMIN) {
    // console.log("token revision",jwt)
    // this.props.history.goBack();
    return <Redirect from="/logout" to="/" />;
  }
  //
  return (
    <div>
      <h1>logout</h1>
    </div>
  );
};

export default withRouter(Logout_);
