import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBDataTable,
  MDBModalBody,
  MDBRow,
  MDBSpinner,
} from "mdbreact";

import moment from "moment";
import api from "../../../helpers/api";
import config_table from "./01-config_stars_table";

class InternalReviewTable extends React.Component {
  state = {
    loadData: false,
    data: { columns: config_table },
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    this.setState({
      loadData: true,
    });
    try {
      // console.log("internal---->", this.props.startDate, this.props.endDate);
      const endDate = moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD");

      const startDate = moment(endDate)
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      const query = {
        // initial_date: this.props.startDate,
        // finishing_date: this.props.endDate,
        initial_date: startDate,
        finishing_date: endDate,
      };
      const a = await api("review_counter", "application/json", query);

      // console.log("data dashboard", a.data);

      let data5Stars = a.data.dataFilter.dataFilter5;
      let data4Stars = a.data.dataFilter.dataFilter4;
      let data3Stars = a.data.dataFilter.dataFilter3;
      let data2Stars = a.data.dataFilter.dataFilter2;
      let data1Stars = a.data.dataFilter.dataFilter1;

      let data5New = await this.filterData(a.data.dataAll.dataAll5, data5Stars);
      // console.log("data5New", data5New);
      let totalData5 = data5Stars.concat(data5New);

      let data4New = await this.filterData(a.data.dataAll.dataAll4, data4Stars);
      let totalData4 = data4Stars.concat(data4New);

      let data3New = await this.filterData(a.data.dataAll.dataAll3, data3Stars);
      let totalData3 = data3Stars.concat(data3New);

      let data2New = await this.filterData(a.data.dataAll.dataAll2, data2Stars);
      let totalData2 = data2Stars.concat(data2New);

      let data1New = await this.filterData(a.data.dataAll.dataAll1, data1Stars);
      let totalData1 = data1Stars.concat(data1New);

      const counter5Stars = totalData5.length;
      const counter4Stars = totalData4.length;
      const counter3Stars = totalData3.length;
      const counter2Stars = totalData2.length;
      const counter1Stars = totalData1.length;
      // console.log(
      //   "counter",
      //   counter5Stars,
      //   counter4Stars,
      //   counter3Stars,
      //   counter2Stars,
      //   counter1Stars
      // );
      const totalStars =
        counter1Stars +
        counter2Stars +
        counter3Stars +
        counter4Stars +
        counter5Stars;

      let percent5Stars = "";
      let percent4Stars = "";
      let percent3Stars = "";
      let percent2Stars = "";
      let percent1Stars = "";

      if (totalStars === 0) {
        percent5Stars = "---";
        percent4Stars = "---";
        percent3Stars = "---";
        percent2Stars = "---";
        percent1Stars = "---";
      } else {
        percent5Stars = ((counter5Stars / totalStars) * 100).toFixed(2);
        percent4Stars = ((counter4Stars / totalStars) * 100).toFixed(2);
        percent3Stars = ((counter3Stars / totalStars) * 100).toFixed(2);
        percent2Stars = ((counter2Stars / totalStars) * 100).toFixed(2);
        percent1Stars = ((counter1Stars / totalStars) * 100).toFixed(2);
      }

      this.setState({
        data: {
          ...this.state.data,
          rows: [
            {
              rating: "5",
              quantity: counter5Stars,
              percent: percent5Stars.toString() + " %",
            },
            {
              rating: "4",
              quantity: counter4Stars,
              percent: percent4Stars.toString() + " %",
            },
            {
              rating: "3",
              quantity: counter3Stars,
              percent: percent3Stars.toString() + " %",
            },
            {
              rating: "2",
              quantity: counter2Stars,
              percent: percent2Stars.toString() + " %",
            },
            {
              rating: "1",
              quantity: counter1Stars,
              percent: percent1Stars.toString() + " %",
            },
            {
              rating: "Total",
              quantity: totalStars,
              percent: "",
            },
          ],
        },
        loadData: false,
      });

      // console.log("end load");
    } catch (e) {}
  };

  filterData = (allData, dataVerify) => {
    return new Promise((resolve, reject) => {
      // if (allData.length>0) {
      let dataNoVerifyFilter1 = [];

      // console.log("filterData-->", allData, dataVerify);

      allData.map((val) => {
        let noFilterData = true;
        dataVerify.map((val1) => {
          if (val1.id_sms_message === val.id_sms_message) {
            noFilterData = false;
          }
        });
        if (noFilterData) {
          dataNoVerifyFilter1.push(val);
        }
      });

      // console.log("dataNoVerifyFilter1", dataNoVerifyFilter1);

      let dataNoVerifyFilter2 = [];

      dataNoVerifyFilter1.map((val) => {
        let totalNumbers = val.body.replace(/[^0]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^1]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^2]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^3]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^4]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^5]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^6]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^7]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^8]/g, "").length;
        totalNumbers = totalNumbers + val.body.replace(/[^9]/g, "").length;
        if (totalNumbers === 1) {
          dataNoVerifyFilter2.push(val);
        }
      });

      // console.log("dataNoVerifyFilter2", dataNoVerifyFilter2);

      let dataNoVerifyFilter3 = [];

      dataNoVerifyFilter2.map((val) => {
        if (val.body.length <= 50) {
          dataNoVerifyFilter3.push(val);
        }
      });

      // console.log("dataNoVerifyFilter3", dataNoVerifyFilter3);
      resolve(dataNoVerifyFilter3);
      // }
    });
  };

  render() {
    // console.log(this.state.data);
    return (
      <React.Fragment>
        <MDBCard className="text-left">
          <MDBCardHeader className="white-text color_select text-left">
            Internal Ratings from the past 30 days
          </MDBCardHeader>
          <MDBCardBody>
            {this.state.loadData ? (
              <MDBRow center style={{ height: "8vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow>
                <MDBCol md="12">
                  <MDBDataTable
                    striped
                    searching={false}
                    bordered
                    small
                    paging={false}
                    btn
                    maxHeight="300px"
                    className="table_warp"
                    data={this.state.data}
                    fixed
                    sortable={false}
                    noBottomColumns
                  ></MDBDataTable>
                </MDBCol>
              </MDBRow>
            )}
          </MDBCardBody>
        </MDBCard>
      </React.Fragment>
    );
  }
}

export default InternalReviewTable;
