import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert,
} from "mdbreact";
import imageCompression from "browser-image-compression";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import path from "path";
import api from "../../helpers/api";

class New_automated_msg extends React.Component {
  constructor(props) {
    super(props);

    const html = JSON.parse(this.props.data_edit.message);
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        locacion: "",
        horizontal: {
          state: "origin",
          file: undefined,
        },
        vertical: {
          state: "origin",
          file: undefined,
        },
        id_automated: this.props.id_,
        map: "http://maps.google.com/maps?q=-0.230788,-78.5234213&z=15&output=embed",
        latitude: "",
        longitude: "",
        showmap: false,
        attached: true,
        file: undefined,
        start_compresion: false,
        progress_compresion: 0,
        ext: "",
        html_msg: "",
        valid_html_msg: true,
        incompatible: false,
        max_size_file: "3000000",
        max_size: false,
        alert: {
          show: false,
          msg: "",
        },
        save: false,
        saving: false,
        command_data: "",
        command_data_location: "",
        command_data_original: "",
        options: this.props.new_options_,
        title: "",
        number_time: this.props.data_edit.qty,
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.props.data_edit.time,
        },
        modal: false,
        postcard: false,
        open_modal_file: false,
        file_name_vertical: this.props.data_edit.vertical,
        file_name_horizontal: this.props.data_edit.horizontal,
        ver_img: "http://localhost:6900/img/",
        modal_file_1: "",
        vertical_file: "",
        horizontal_file: "",
      };
    }
  }

  selec_type = ["time"];

  options = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "After booking",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "Before activity",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "During activity",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "4",
        text: "After activity",
      },
    ],
  };

  type = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "message",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "location",
      },
    ],
  };

  topic = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "@firstmsg",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "@recomendations",
      },
    ],
  };

  async componentDidMount() {
    // console.log("jwt-load form-api", jwt);
    // console.log("data props----->dg", this.props.data_edit);
    if (this.props.data_edit.vertical === "false") {
      this.setState({
        file_name_vertical: "Upload your file",
      });
    } else {
      this.setState({
        file_name_vertical: this.props.data_edit.vertical,
      });
    }
    if (this.props.data_edit.horizontal === "false") {
      this.setState({
        file_name_horizontal: "Upload your file",
      });
    } else {
      this.setState({
        file_name_horizontal: this.props.data_edit.horizontal,
      });
    }
    if (this.props.data_edit.vertical) {
      this.setState({
        vertical_file: "true",
      });
    }

    if (this.props.data_edit.horizontal) {
      this.setState({
        horizontal_file: "true",
      });
    }

    try {
      const a = await api("userdata_commands", "application/json");

      var data_command = [];
      var location_command_data = [];
      var count = 0;
      var data_ = a.data.data.commands.map((value, index) => {
        // console.log("data value", value.tema);
        data_command.push({
          checked: false,
          disabled: false,
          icon: null,
          value: `${index + 1}`,
          text: value.tema,
        });
        //command_data_location
        if (
          value.tema !== "@postcard" &&
          value.tema !== "@firstmsg" &&
          value.tema !== "@review" &&
          value.tema !== "@includes"
        ) {
          count++;
          location_command_data.push({
            checked: false,
            disabled: false,
            icon: null,
            value: `${count}`,
            text: value.tema,
          });
        }
      });
      this.setState({
        command_data: {
          options: data_command,
        },
        command_data_location: {
          options: location_command_data,
        },
        command_data_original: {
          options: data_command,
        },
      });
      // console.log("command data", data_command);
      // console.log(
      //   "command_location data",
      //   this.state.command_data_location
      // );
      // console.log(this.state.command_data);
    } catch (e) {
      // console.log("load data error", e);
    }
  }

  toggle = () => {
    // console.log("click toggle");
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.saving === false) {
      // console.log("validated");
      const msg_html = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const ht = msg_html.trim();
      // console.log("validation_html:", ht);
      if (ht === "<p></p>") {
        this.setState({
          valid_html_msg: false,
        });
      }
      this.setState({
        html_msg: msg_html,
      });
      event.target.className += " was-validated";

      var a = await this.select_box();
      var b = await this.input_fields();
      var c = await this.validate_postal();

      if (a && b && c.state) {
        if (c.state !== false) {
          const datos = {
            time: this.state.time.data,
            number_time: this.state.number_time,
            message: msg_html,
            c,
          };
          this.send_api(datos);
        } else {
          const datos = {
            time: this.state.time.data,
            number_time: this.state.number_time,
            message: msg_html,
          };
          this.send_api(datos);
        }
        // console.log("all validated-send api");
      }
    } else {
      // console.log("invalid");
    }
  };

  input_fields = () => {
    const ht = this.state.html_msg.trim();
    return new Promise((resolve, reject) => {
      if (this.state.number_time !== "" && ht !== "<p></p>") {
        // console.log("validated");
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      }
    });
  };

  validate_postal = () => {
    return new Promise((resolve, reject) => {
      if (
        this.state.horizontal.state === true &&
        this.state.vertical.state === true
      ) {
        // console.log("update postcard");
        resolve({
          state: true,
          horizontal: this.state.horizontal.file,
          vertical: this.state.vertical.file,
        });
      }
      if (
        this.state.horizontal.state === "origin" &&
        this.state.vertical.state === true
      ) {
        // console.log("update only vertical postcard");
        resolve({
          state: true,
          vertical: this.state.vertical.file,
        });
      }

      if (
        this.state.horizontal.state === true &&
        this.state.vertical.state === "origin"
      ) {
        // console.log("update only horizontal postcard");
        resolve({
          state: true,
          horizontal: this.state.horizontal.file,
        });
      }
      // console.log(this.state.horizontal);
      if (
        this.state.horizontal.state === false ||
        this.state.vertical.state === false
      ) {
        // console.log("error someone postcard");
        resolve({
          state: false,
        });
      }

      if (
        this.state.horizontal.state === "origin" &&
        this.state.vertical.state === "origin"
      ) {
        // console.log("any postcard update");
        resolve({
          state: true,
        });
      }
    });
  };

  select_box = () => {
    // console.log("before promise");
    return new Promise((resolve, reject) => {
      // console.log("run");
      var b = false;
      this.selec_type.map((valor, index) => {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok");
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach");
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
          if (index === 0) {
            // console.log("select_box validated");
            resolve(true);
          }
        }
      });
    });
  };

  send_api = async (data) => {
    // console.log("jwt-to api", jwt);
    // console.log("send api:", data);
    this.setState({
      save: true,
      saving: true,
    });
    const formData = new FormData();

    formData.append("id_automated", this.state.id_automated);
    formData.append("time", data.time);
    formData.append("number_time", data.number_time);
    formData.append("message", data.message);
    if (data.c) {
      if (data.c.hasOwnProperty("horizontal")) {
        // console.log("HORIZONTAL add");
        formData.append("horizontal", this.state.horizontal.file);
      }
      if (data.c.hasOwnProperty("vertical")) {
        // console.log("VERTICAL add");
        formData.append("vertical", this.state.vertical.file);
      }
    }

    try {
      const res = await api(
        "edit_automated_msg_postal",
        "multipart/form-data",
        formData
      );
      // console.log("response:", res.data);
      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        this.props.refresh_();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("query error", e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
    // console.log("select", source, data);
  };

  setSelect_type = (source, data) => {
    // console.log("data type:", source, data);
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
    if (data === "message") {
      this.setState({
        command_data: this.state.command_data_original,
        attached: true,
        showmap: false,
      });
      // console.log("load data:", this.state.command_data_original);
    }
    if (data === "location") {
      this.setState({
        command_data: this.state.command_data_location,
        attached: false,
        showmap: true,
      });
      // console.log("load data location:", this.state.attached);
    }
  };

  setSelect_command = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });

    if (this.state.type.data === "message" && data === "@postcard") {
      this.setState({
        attached: false,
        postcard: true,
      });
      // console.log(
      //   "load data message + postcard:",
      //   this.state.command_data_location
      // );
    }

    if (
      (this.state.type.data === "message" && data === "@firstmsg") ||
      (this.state.type.data === "message" && data === "@recomendations") ||
      (this.state.type.data === "message" && data === "@attractive") ||
      (this.state.type.data === "message" && data === "@reminder") ||
      (this.state.type.data === "message" && data === "@includes") ||
      (this.state.type.data === "message" && data === "@review") ||
      (this.state.type.data === "message" && data === "@notheme")
    ) {
      this.setState({
        attached: true,
        postcard: false,
      });
      // console.log("load data location:", this.state.command_data_original);
    }
  };

  progress = (a) => {
    // console.log("progress", a);
    this.setState({
      progress_compresion: a,
    });
    if (a === 100) {
      this.setState({
        progress_compresion: 0,
        start_compresion: false,
      });
      // console.log("finished compress");
    }
  };

  fileInputHandler = async (e, origin) => {
    // console.log("file", e, origin);

    if (e[0] !== undefined) {
      if (e[0].size <= this.state.max_size_file) {
        const ext = path.extname(e[0].name);
        if (
          ext === ".jpg" ||
          ext === ".jpeg" ||
          ext === ".png" ||
          ext === ".gif" ||
          ext === ".mp4" ||
          ext === ".pdf"
        ) {
          // console.log(e[0]);
          if (e[0].type === "image/png") {
            this.setState({
              start_compresion: true,
            });
            const options = {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
              onProgress: (a) => this.progress(a),
            };
            const compress_file_a = await imageCompression(e[0], options);

            this.setState({
              file: compress_file_a,
              ext: "jpg",
            });
          } else {
            this.setState({
              file: e[0],
            });
          }
          this.setState({
            incompatible: false,
          });
        } else {
          // console.log("incompatible file");
          this.setState({
            incompatible: true,
          });
        }
        this.setState({
          max_size: false,
        });
      } else {
        /* file max exceed*/
        // console.log("max size file");
        this.setState({
          max_size: true,
        });
      }
    }
  };

  fileInputHandler_postcard = (e, origin) => {
    // console.log(e, origin);
    if (e[0] !== undefined) {
      const ext = path.extname(e[0].name);
      var img = document.createElement("img");
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      var a = reader.readAsDataURL(e[0]);
      img.onload = () =>
        this.update_state_postal(img.width, img.height, e, origin);
    } else {
      // console.log("can't empty");
    }
  };

  update_state_postal = (width, height, e, origin) => {
    // console.log("size", width, height, e);
    var origin_ =
      origin === "horizontal"
        ? {
            h: 1920,
            v: 1080,
          }
        : {
            h: 1080,
            v: 1920,
          };

    if (width === origin_.h && height === origin_.v) {
      // console.log("valid size");
      this.setState({
        [origin]: {
          state: true,
          file: e[0],
        },
      });
    } else {
      // console.log("invalid size");
      this.setState({
        [origin]: {
          state: false,
          file: e[0],
        },
      });
    }

    // console.log("state:", this.state.horizontal, this.state.vertical);
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const msg_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const ht = msg_html.trim();
    // console.log("change editor html", ht);
    if (ht !== "<p></p>") {
      this.setState({
        valid_html_msg: true,
      });
    }
  };

  update_map = () => {
    // console.log("map state");
    this.setState({
      map: `http://maps.google.com/maps?q=${this.state.latitude},${this.state.longitude}&z=15&output=embed`,
    });
  };

  modal_file = (e) => {
    if (e === "horizontal") {
      this.setState({
        open_modal_file: true,
        modal_file_1: "true",
      });
    }
    if (e === "vertical") {
      this.setState({
        open_modal_file: true,
      });
    }
    if (e === "cerrar") {
      this.setState({
        open_modal_file: false,
        modal_file_1: "",
      });
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.open_modal_file}
          toggle={() => this.modal_file("cerrar")}
          position="right"
        >
          <MDBModalHeader toggle={() => this.modal_file("cerrar")}>
            File
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.modal_file_1 === "true" ? (
              <img
                src={this.state.ver_img + this.state.file_name_vertical}
                className="img-fluid"
                alt=""
              />
            ) : (
              <img
                src={this.state.ver_img + this.state.file_name_horizontal}
                className="img-fluid"
                alt=""
              />
            )}
          </MDBModalBody>
        </MDBModal>

        <form onSubmit={this.submitHandler} noValidate className="text-left">
          {this.state.alert.show ? (
            <MDBAlert color="danger" dismiss>
              {this.state.alert.msg}
            </MDBAlert>
          ) : (
            ""
          )}

          <div className="md-form text-center">
            <h6>
              Title:{this.props.data_edit.title} / Command:
              {this.props.data_edit.command}
            </h6>
            <h6>
              Type: {this.props.data_edit.type} / Moment:
              {this.props.data_edit.moment}
            </h6>
          </div>

          <MDBRow className="espace">
            <MDBCol md="6">
              <div className="md-form">
                <MDBSelect
                  options={this.state.options}
                  color="primary"
                  label="Time"
                  name="time"
                  className="text_1"
                  getTextContent={this.setSelect.bind(this, "time")}
                />

                <div className={this.state.time.class_novalid}>
                  Please select unity.
                </div>
              </div>
            </MDBCol>

            <MDBCol md="6">
              <div className="md-form">
                <MDBInput
                  label="Qty"
                  type="number"
                  name="number_time"
                  value={this.state.number_time}
                  onChange={this.changeHandler}
                  required
                >
                  <div className="invalid-feedback">Insert time</div>
                </MDBInput>
              </div>
            </MDBCol>
          </MDBRow>

          <div className="md-form">
            <Editor
              handlePastedText={() => false}
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                inline: {
                  options: ["bold", "italic", "underline", "monospace"],
                },
                list: {
                  options: [],
                },
                textAlign: { options: [] },
                link: { options: [] },
                blockType: {
                  inDropdown: true,
                  options: [],
                  className: "ocultar",
                },
                fontSize: {
                  options: [],
                  className: "ocultar",
                },
                fontFamily: {
                  className: "ocultar",
                },
                embedded: {
                  className: "ocultar",
                },
                image: {
                  className: "ocultar",
                },
                remove: {
                  className: "ocultar",
                },
                colorPicker: {
                  popupClassName: "popup",
                  className: "ocultar",
                  colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
                },
              }}
            />
            {this.state.valid_html_msg ? (
              ""
            ) : (
              <div className="novalidate_html">Insert a body message</div>
            )}
          </div>

          <div className="text-center">
            <MDBBtn color="deep-orange" type="submit" className="btn_save">
              {this.state.save ? (
                <div className="d-flex flex-row">
                  <div className="p-1">Save</div>
                  <div className="p-1">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-1">Save</div>
              )}
            </MDBBtn>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default New_automated_msg;
