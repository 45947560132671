import React, { Fragment } from "react";
import PropTypes from 'prop-types';

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBProgress,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";

import moment from "moment";

class Card_ms extends React.Component {
  state = {
    buttonStyle: {
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0",
    },
    process_1_pm2: "",
    modal:false,
    modal_data:"",
    modal_title:"",
    process_number:""
  };

  componentDidUpdate(){
 
  }

  componentWillUpdate(){
    if(this.props.socket_active){
        if(this.props.data_socket.number==this.props.name_process){
            console.log("--->",this.props.name_process,this.props.data_socket,"<--"); 
            this.setState({
                process_number:this.props.data_socket.number
            })

        }   
    } 
  }
  openmodal=(title)=>{
    console.log("open modal")
    this.setState({
        modal : true,
        modal_title:this.props.title,
        modal_data: <p>{this.props.data_list}</p>
      })

  }



  closemodal=()=>{
    console.log("--CLOSE MODAL")
    this.setState({
      modal : false
    })
  }
  render() {
    const fn = (a) => {
      var ini = moment().toISOString();
      var fin = moment(
        moment(this.state.data_process1_3.data[0].send).toISOString()
      );
      var ms = fin.diff(ini);
      var d = moment.duration(ms);
      var negative = d < 0 ? "-" : "+";
      return `${negative}${d.humanize()}`;
    };
    

    return (
        
        <Fragment>
        <MDBModal isOpen={this.state.modal} toggle={this.closemodal} size="lg">
                <MDBModalHeader toggle={this.closemodal}>{this.state.modal_title}</MDBModalHeader>
                <MDBModalBody>
                    {this.state.modal_data}
                </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn className={"color_btn-red"} color="primary-" onClick={this.closemodal}>Close</MDBBtn>
                </MDBModalFooter>
        </MDBModal>
        <MDBCol md="3">
            <MDBRow>
                <MDBCol size="12">
                    <MDBCard cascade className="cascading-admin-card">

                        <div className="admin-up" style={{"textAlign": "initial"}}>
                            <MDBBtn color="primary" className="bigbutton" onClick={this.openmodal}>
                                <MDBIcon
                                    icon="exclamation-triangle"
                                    className={this.props.alert?"color_select":"color_select_error"}
                                />
                            </MDBBtn>
                            <div className="data">
                                <p style={{ textTransform: "uppercase" }}>
                                    {this.props.socket_active?
                                        this.state.process_number
                                    :""
                                    }
                                   
                                </p>
                                <h4 className="font-weight-bold dark-grey-text">
                                    {this.props.data}
                                </h4>
                            </div>
                        </div>
                        
                              <MDBCardBody cascade style={{"textAlign":"initial"}}>
                                    <p className="card-text">
                                        {this.props.footer}
                                    </p>
                                </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBCol>

      </Fragment>
    );
  }
}

Card_ms.propTypes = {
    alert: PropTypes.bool,
    title: PropTypes.string,
    data: PropTypes.string,
    footer: PropTypes.string,
    socket_active:PropTypes.bool
}

Card_ms.defaultProps={
    socket_active:false,
    alert:false,
    title:"No title",
    data:"data",
    footer:"no footer",
    data_list:<ul>
                <li> 1 </li>
                <li> 2 </li>
            </ul>
}

export default Card_ms;

