import React from "react";
import { MDBBtn, MDBAlert } from "mdbreact";
import imageCompression from "browser-image-compression";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import path from "path";
import api from "../../../helpers/api";
import isgsm7 from "isgsm7";

class Edit_chatbot_msg extends React.Component {
  constructor(props) {
    super(props);
    const html = JSON.parse(this.props.data_edit.message);
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      const t = convertToRaw(editorState.getCurrentContent()).blocks;
      let counterCharactersSMS = 0;
      let counter1 = 0;
      let maxSMS = 153;
      t.map((e) => {
        if (!isgsm7(e.text)) {
          maxSMS = 67;
        }
        if (counter1 > 0) {
          counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
        } else {
          counterCharactersSMS = counterCharactersSMS + e.text.length;
        }
        counter1++;
      });
      const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
      let characters = counterCharactersSMS + "/" + totalSMS;
      this.state = {
        editorState,
        counterSMS: characters,
        idbb_chatbot_msg_products: this.props.id_,
        file: undefined,
        start_compresion: false,
        progress_compresion: 0,
        ext: "",
        html_msg: "",
        valid_html_msg: true,
        incompatible: false,
        max_size_file: "3000000",
        max_size: false,
        alert: {
          show: false,
          msg: "",
        },
        save: false,
        saving: false,
        options: this.props.new_options_,
        title: "",
        number_time: this.props.data_edit.qty,
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.props.data_edit.time,
        },

        modal: false,
      };
    }
  }

  selec_type = ["time"];

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    if (this.state.saving === false) {
      const msg_html = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const ht = msg_html.trim();
      if (ht === "<p></p>") {
        this.setState({
          valid_html_msg: false,
        });
      }
      this.setState({
        html_msg: msg_html,
      });
      var b = await this.input_fields(ht);

      if (b) {
        const datos = {
          file: this.state.file,
          message: msg_html,
        };
        this.send_api(datos);
      }
    } else {
    }
  };

  input_fields = (data) => {
    const ht = data.trim();
    return new Promise((resolve, reject) => {
      if (ht !== "<p></p>") {
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      }
    });
  };

  select_box = () => {
    return new Promise((resolve, reject) => {
      this.selec_type.map((valor, index) => {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
          if (index === 0) {
            resolve(true);
          }
        }
      });
    });
  };

  send_api = async (data) => {
    this.setState({
      save: true,
      saving: true,
    });
    const formData = new FormData();
    formData.append(
      "idbb_chatbot_msg_products",
      this.state.idbb_chatbot_msg_products
    );
    formData.append("file", data.file);
    formData.append("message", data.message);
    formData.append("ext", this.state.ext);

    try {
      const res = await api(
        "edit_bot_msg_product",
        "multipart/form-data",
        formData
      );
      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        // this.props.changeStateModal();
        this.props.refresh_();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {}
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  progress = (a) => {
    this.setState({
      progress_compresion: a,
    });
    if (a === 100) {
      this.setState({
        progress_compresion: 0,
        start_compresion: false,
      });
    }
  };

  fileInputHandler = async (e) => {
    if (e[0] !== undefined) {
      if (e[0].size <= this.state.max_size_file) {
        const ext = path.extname(e[0].name);
        if (
          ext === ".jpg" ||
          ext === ".jpeg" ||
          ext === ".png" ||
          ext === ".gif" ||
          ext === ".mp4" ||
          ext === ".pdf"
        ) {
          if (e[0].type === "image/png") {
            if (ext !== ".png") {
              this.setState({
                start_compresion: true,
              });
              const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                onProgress: (a) => this.progress(a),
              };
              const compress_file_a = await imageCompression(e[0], options);

              this.setState({
                file: compress_file_a,
                ext: "jpg",
              });
            } else {
              //if file is .png for a postal
              this.setState({
                file: e[0],
              });
            }
          } else {
            this.setState({
              file: e[0],
            });
          }
          this.setState({
            incompatible: false,
          });
        } else {
          this.setState({
            incompatible: true,
          });
        }
        this.setState({
          max_size: false,
        });
      } else {
        this.setState({
          max_size: true,
        });
      }
    }
  };

  onEditorStateChange = (editorState) => {
    const t = convertToRaw(editorState.getCurrentContent()).blocks;
    let counterCharactersSMS = 0;
    let counter1 = 0;
    let maxSMS = 153;
    t.map((e) => {
      if (!isgsm7(e.text)) {
        maxSMS = 67;
      }
      if (counter1 > 0) {
        counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
      } else {
        counterCharactersSMS = counterCharactersSMS + e.text.length;
      }
      counter1++;
    });
    const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
    let characters = counterCharactersSMS + "/" + totalSMS;
    this.setState({
      editorState,
      counterSMS: characters,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <form onSubmit={this.submitHandler} noValidate className="text-left">
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}

        <div className="md-form text-center">
          <h6>Intent:{this.props.data_edit.title}</h6>
          <h6>
            Intent:{this.props.data_edit.intent} / Followup:
            {this.props.data_edit.followup}
          </h6>
        </div>

        <div className="md-form">
          <Editor
            handlePastedText={() => false}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: {
                options: ["bold", "italic", "underline", "monospace"],
              },
              list: {
                options: [],
              },
              textAlign: { options: [] },
              link: { options: [] },
              blockType: {
                inDropdown: true,
                options: [],
                className: "ocultar",
              },
              fontSize: {
                options: [],
                className: "ocultar",
              },
              fontFamily: {
                className: "ocultar",
              },
              embedded: {
                className: "ocultar",
              },
              image: {
                className: "ocultar",
              },
              remove: {
                className: "ocultar",
              },
              colorPicker: {
                popupClassName: "popup",
                className: "ocultar",
                colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
              },
            }}
          />
          <p className="text-right">{this.state.counterSMS}</p>
          {this.state.valid_html_msg ? (
            ""
          ) : (
            <div className="novalidate_html">Insert a body message</div>
          )}
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Edit</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default Edit_chatbot_msg;
