import React, { Component } from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBAvatar,
  MDBBadge,
  MDBIcon,
  MDBBtn,
  MDBScrollbar,
  MDBInput,
  MDBSwitch,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

import "./ChatPage.css";
import api from "../../helpers/api";

import socketIOClient from "socket.io-client";
import ModalFilter from "./00-modalFilter";
//const socket = socketIOClient("http://localhost:7600");

class ChatPage extends Component {
  state = {
    msg_out: "",
    friendsToScroll: [
      {
        name: "sin cargar",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-8.jpg",
        message: "Hello, Are you there?",
        when: "Just now",
        toRespond: 1,
        seen: false,
        active: false,
        id_: 0,
      },
    ],
    friendsToScroll_backup: [
      {
        name: "sin cargar",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-8.jpg",
        message: "Hello, Are you there?",
        when: "Just now",
        toRespond: 1,
        seen: false,
        active: false,
        id_: 0,
      },
    ],
    friendsToScroll1: [
      {
        name: "John Doe",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-8.jpg",
        message: "Hello, Are you there?",
        when: "Just now",
        toRespond: 1,
        seen: false,
        active: false,
      },
      {
        name: "Danny Smith",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-1.jpg",
        when: "5 min ago",
        toRespond: 0,
        seen: true,
        active: false,
      },
      {
        name: "Alex Steward",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Ashley Olsen",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-3.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Kate Moss",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-4.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: true,
        active: false,
      },
      {
        name: "Lara Croft",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Brad Pitt",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg",
        when: "5 min ago",
        toRespond: 0,
        seen: true,
        active: false,
      },
      {
        name: "John Doeno",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-8.jpg",
        message: "Hello, Are you there?",
        when: "Just now",
        toRespond: 1,
        seen: false,
        active: false,
      },
      {
        name: "Danny Smithy",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-1.jpg",
        when: "5 min ago",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Alex Stewardo",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Marta Olsen",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-3.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Kate Boss",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-4.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Tomb Rider",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg",
        when: "Yesterday",
        toRespond: 0,
        seen: false,
        active: false,
      },
      {
        name: "Bradley Scott",
        message: "Lorem ipsum dolor sit",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg",
        when: "5 min ago",
        toRespond: 0,
        seen: true,
        active: false,
      },
    ],
    messagesToScroll: [
      {
        author: "Brad Pitt",
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg",
        when: "12 mins ago",
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
    ],
    all_msg: "",
    load_process: false,
    no_msg: false,
    active_chat: "",
    user: "",
    phone_user: "",
    checkboxDate: false,
    requestNumber: "",
    tourNumber: "",
    bookingNumber: "",
    phoneNumber: "",
    dateSearch: "",
    search_contact: "",
    state_search: false,
    modalFilter: false,
    loadDataFilter: false,
    messageFilter: "invalid-feedback",
    modals: {
      state: false,
      data: "",
      stateSwitch: false,
    },
  };

  async componentDidMount() {
    await this.loadChats_query("read_sms_all_message_chat");
  }

  click_new = (data) => {
    let stateSwitch = false;
    if (
      data.chat_value.estado === "active" ||
      data.chat_value.estado === "activo"
    ) {
      stateSwitch = true;
    }
    this.setState({
      modals: {
        ...this.state.modals,
        state: true,
        data: data.chat_value.idcliente_finales,
        stateSwitch,
      },
    });
  };

  loadChats_query = async (path, data) => {
    try {
      const res = await api(path, "application/json", data);
      console.log("res :>> ", res);

      if (res.data.status === "don`t msg") {
        this.setState({
          load_process: true,
          no_msg: true,
        });
      }

      if (res.data.status === "ok") {
        this.setState({
          all_msg: res.data.data,
          load_process: true,
          no_msg: false,
          user: res.data.data[0].usuario,
          phone_user: res.data.data[0].from_sms,
        });
        // console.log("--->all data", res.data.data);
        let unique = res.data.data
          .map((e) => {
            return e["to_sms"];
          })
          .map((e1, i, final) => {
            return final.indexOf(e1) === i && i;
          })
          .filter((obj) => res.data.data[obj])
          .map((e) => res.data.data[e]);

        let lowercase = unique.map((a, b) => {
          if (a.nombre_cliente !== undefined) {
            unique[b].nombre_cliente = a.nombre_cliente.toLowerCase();
          }
          if (a.apellido_cliente !== undefined) {
            const last_name = a.apellido_cliente;
            unique[b].apellido_cliente = last_name; // a.apellido_cliente.toLowerCase();
          }
        });
        this.loadChats(unique);
        this.load_msg_in_out(unique[0], this.state.all_msg, 0);
      }
    } catch (e) {
      console.log("load chats error:", e);
    }
  };

  add_in_msg = (msg) => {
    this.setState({
      messagesToScroll: [
        ...this.state.messagesToScroll,
        {
          author: msg.from,
          avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg",
          when: "17 mins ago",
          message: msg.body,
          side: "in",
        },
      ],
    });
  };

  scrollToLast = (selector) => {
    const message = document.querySelectorAll(selector);
    message[message.length - 1].scrollIntoView();
  };

  send_msg = (event) => {
    this.setState({
      msg_out: event,
    });
  };

  send_msg_action = () => {
    if (this.state.msg_out !== "") {
      const send_msg = {
        body: this.state.msg_out, // message
        from_sms: this.state.phone_user,
        to_sms: this.state.active_chat,
        user: this.state.user,
        status: "pending",
        type: "out",
        command: "",
        origin: "human",
      };

      const add_card_msg = {
        author: "Human",
        when: "Now",
        message: this.state.msg_out,
        side: "out",
        tour: "",
        webhook: "",
        reserva: "",
        notificacion: "",
        system: "",
      };
      var add_chat = [];
      this.state.messagesToScroll.map((a) => {
        add_chat.push(a);
      });
      add_chat.push(add_card_msg);
      this.setState({
        messagesToScroll: add_chat,
        msg_out: "",
      });
    }
  };

  searchContact = (search, state) => {
    if (this.state.load_process) {
      const filtercontact = this.state.friendsToScroll.filter((val) => {
        return (
          val.name.includes(search) || val.message.includes(search) // ||
          //val.chat_value.id_tour.includes(search)
        );
      });

      if (search !== "") {
        this.setState({
          friendsToScroll: filtercontact,
        });
      }
    }
  };

  componentDidUpdate() {
    if (this.state.load_process === true && this.state.no_msg === false) {
      this.scrollToLast(".chat-message");
    }
  }

  onKeyDown = (event) => {
    if (event.key === "Backspace") {
      this.setState(
        {
          friendsToScroll: this.state.friendsToScroll_backup,
          state_search: true,
        },
        () => {
          this.searchContact(this.state.search_contact, 1);
        }
      );
    }
  };

  loadChats = (data) => {
    var Chats = [];

    var sort_data = data.sort((a, b) => {
      return b.id_sms_message - a.id_sms_message;
    });

    data.map((valor, index) => {
      const a = {
        name: valor.to_sms,
        avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-8.jpg",
        message: `${valor.nombre_cliente} ${valor.apellido_cliente}`,
        when: valor.data_update,
        toRespond: 0,
        seen: false,
        active: false,
        chat_value: valor,
        id_: index,
        tour: "aaaa",
      };
      Chats.push(a);
    });
    // console.log("---->", Chats);
    this.setState({
      friendsToScroll: Chats,
      friendsToScroll_backup: Chats,
    });
  };

  change_format_name = (value) => {
    if (value !== undefined) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  };

  load_msg_in_out = (chat_value, all_msg, id) => {
    this.state.friendsToScroll.map((val) => {
      if (val.id_ === id) {
        val.active = true;
      } else {
        val.active = false;
      }
    });
    const filtro = this.state.all_msg
      .map((e) => {
        return e;
      })
      .filter((a, index) => {
        if (a.to_sms === chat_value.to_sms) {
          return { a, index };
        }
      });
    const chat_ = [];

    filtro.map((a) => {
      let temporalState = false;
      if (a.estado === "active" || a.estado === "activo") {
        temporalState = true;
      }
      let sistema = "";
      if (a.sistema) {
        sistema = a.sistema.charAt(0).toUpperCase() + a.sistema.slice(1);
      }

      const b = {
        author: a.type === "in" ? "client" : "bot",
        when: a.data_update,
        message: JSON.parse(a.body),
        side: a.type,
        tour: a.id_tour,
        webhook: a.id_webhook,
        reserva: a.reserva,
        notificacion: a.id_notification,
        system: sistema,
        origin_msg: a.origin_msg,
        activeService: temporalState,
        idClienteFinales: a.idcliente_finales,
        ordenNumber: a.numero_orden,
        dateBooking: a.hora_humana,
      };

      chat_.push(b);
    });

    this.setState({
      messagesToScroll: chat_,
      active_chat: chat_value.to_sms,
    });
    this.scrollToLast(".chat-message");
  };

  search_field = (event) => {
    this.setState(
      {
        search_contact: event.target.value,
      },
      () => {
        if (this.state.state_search) {
          this.setState(
            {
              friendsToScroll: this.state.friendsToScroll_backup,
            },
            () => {
              this.searchContact(this.state.search_contact, 0);
            }
          );
        } else {
          this.searchContact(this.state.search_contact, 0);
        }
      }
    );
  };

  checkboxStatus = (event) => {
    this.setState({
      checkboxDate: event.target.checked,
    });
  };

  requestNumber = (event) => {
    this.setState({
      requestNumber: event.target.value,
    });
  };

  tourNumber = (event) => {
    this.setState({
      tourNumber: event.target.value,
    });
  };

  bookingNumber = (event) => {
    this.setState({
      bookingNumber: event.target.value,
    });
  };

  phoneNumber = (event) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  dataFilter = async (data) => {
    this.setState({
      loadDataFilter: true,
      modalFilter: false,
    });
    const filter_query = {
      initialDate: data.initialDate,
      finishingDate: data.finishingDate,
    };
    this.setState({
      load_process: false,
    });

    await this.loadChats_query(
      "read_sms_all_message_chat_filter",
      filter_query
    );
  };

  openModal = () => {
    this.setState({
      modalFilter: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalFilter: false,
    });
  };

  closeModalActiveService = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        state: false,
      },
    });
  };

  switchChange = async (data) => {
    if (data !== "" || data !== undefined) {
      let stateSwitchTemp = this.state.modals.stateSwitch;
      // this.setState({
      //   modals: {
      //     ...this.state.modals,
      //     stateSwitch: !stateSwitchTemp,
      //   },
      // });
      try {
        const dataUpdate = {
          stateService: !stateSwitchTemp,
          idclienteFinales: data,
        };
        const res = await api(
          "change_active_service",
          "application/json",
          dataUpdate
        );
        this.setState({
          modals: {
            ...this.state.modals,
            state: false,
          },
          load_process: false,
        });
        await this.loadChats_query("read_sms_all_message_chat");
      } catch (e) {
        this.setState({
          modals: {
            ...this.state.modals,
            stateSwitch: !stateSwitchTemp,
          },
        });
      }
    }
  };

  render() {
    const { friends, messages, messagesToScroll, friendsToScroll, all_msg } =
      this.state;

    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.state.modals.state}
          toggle={this.closeModalActiveService} //
          // centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.closeModalActiveService}
            // className={}
            titleClass="w-100"
          >
            {this.state.modals.stateSwitch ? (
              <span className="title-active_service">
                This conversation is currently active
              </span>
            ) : (
              <span className="title-active_service">
                This conversation is inactive
              </span>
            )}
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {/* <MDBSwitch
              checked={this.state.modals.stateSwitch}
              onChange={this.switchChange(this.state.modals.data)}
            /> */}

            {this.state.modals.stateSwitch ? (
              <div className="inactive-service__button">
                <MDBBtn
                  onClick={() => this.switchChange(this.state.modals.data)}
                >
                  Inactivate
                </MDBBtn>
              </div>
            ) : (
              <div className="active-service__button">
                <MDBBtn
                  onClick={() => this.switchChange(this.state.modals.data)}
                >
                  Activate
                </MDBBtn>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
        <MDBCard>
          <MDBCardBody>
            <MDBCard className="grey lighten-3 chat-room">
              {this.state.load_process ? (
                this.state.no_msg ? (
                  <MDBCardBody>
                    <MDBBtn
                      color="infoa"
                      size="sm"
                      className="btn-square float-left mt-4 color_btn-g"
                      onClick={this.loadChats_query(
                        "read_sms_all_message_chat"
                      )}
                    >
                      Don´t msg Refresh
                    </MDBBtn>
                  </MDBCardBody>
                ) : (
                  <React.Fragment>
                    <MDBCardBody>
                      <MDBRow className="px-lg-2 px-2">
                        <MDBCol
                          md="6"
                          xl="4"
                          className="px-0 mb-4 mb-md-0 scrollable-friends-list"
                        >
                          <h6 className="font-weight-bold mb-3 text-lg-left">
                            <MDBRow>
                              <MDBCol md="8">
                                <div className="md-form">
                                  <MDBInput
                                    label="Search"
                                    value={this.state.search_contact}
                                    onChange={this.search_field}
                                    onKeyDown={this.onKeyDown}
                                  />
                                </div>
                              </MDBCol>
                              <MDBCol>
                                <div className="md-form">
                                  <MDBBtn
                                    color="infoa"
                                    size="sm"
                                    className="btn-square float-right mt-4 color_btn-g"
                                    onClick={this.openModal}
                                  >
                                    Filter
                                  </MDBBtn>
                                  <ModalFilter
                                    modalFilter={this.state.modalFilter}
                                    closeModal={this.closeModal}
                                    dataFilter={this.dataFilter}
                                    loadDataFilter={this.state.loadDataFilter}
                                    messageFilter={this.state.messageFilter}
                                  />
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </h6>
                          <MDBScrollbar className="white z-depth-1 p-3">
                            <MDBListGroup className="friend-list">
                              {friendsToScroll.map((friend) => (
                                <MDBListGroupItem
                                  className="d-flex justify-content-between p-2 border-light"
                                  style={{
                                    backgroundColor: friend.active
                                      ? "#eeeeee"
                                      : "",
                                  }}
                                  onClick={() =>
                                    this.load_msg_in_out(
                                      friend.chat_value,
                                      all_msg,
                                      friend.id_
                                    )
                                  }
                                  tag="button"
                                >
                                  <div style={{ fontSize: "0.8rem" }}>
                                    <strong>{friend.name}</strong>
                                    <p className="text-muted">
                                      {friend.message}
                                    </p>
                                  </div>
                                  <div>
                                    <p
                                      className="text-muted mb-0"
                                      style={{ fontSize: "0.75rem" }}
                                    >
                                      {friend.when}
                                    </p>
                                    {friend.seen ? (
                                      <span className="text-muted float-right">
                                        <MDBIcon
                                          icon="check"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : friend.toRespond ? (
                                      <MDBBadge
                                        color="danger"
                                        className="float-right"
                                      >
                                        {friend.toRespond}
                                      </MDBBadge>
                                    ) : (
                                      <span className="text-muted float-right">
                                        <MDBIcon
                                          icon="plus"
                                          aria-hidden="true"
                                          onClick={() => this.click_new(friend)}
                                        />
                                      </span>
                                    )}
                                  </div>
                                </MDBListGroupItem>
                              ))}
                            </MDBListGroup>
                          </MDBScrollbar>
                        </MDBCol>
                        <MDBCol
                          md="6"
                          xl="8"
                          className="pl-md-3 mt-4 mt-md-0 px-lg-auto"
                        >
                          <MDBScrollbar className="scrollable-chat">
                            <MDBListGroup className="list-unstyled px-3">
                              {messagesToScroll.map((message) => (
                                <li
                                  className={
                                    "chat-message justify-content-between mb-4 " +
                                    message.side
                                  }
                                >
                                  <MDBCard>
                                    <MDBCardBody>
                                      <div className="d-flex justify-content-between">
                                        <strong className="primary-font">
                                          {message.author}
                                        </strong>
                                        <small className="pull-right text-muted">
                                          <MDBIcon icon="clock" />{" "}
                                          {message.when}
                                        </small>
                                      </div>
                                      {/* <div className="d-flex">
                                        <small className="primary-font">
                                          TourID:{message.tour}
                                        </small>
                                        <small className="primary-font">
                                          {" "}
                                          | WebhookID:{message.webhook}
                                        </small>
                                        <small className="primary-font">
                                          <a
                                            href={`/products/automated_msg_booking/${message.reserva}`}
                                          >
                                            | BookingID:{message.reserva}
                                          </a>
                                        </small>
                                        <small className="primary-font">
                                          {" "}
                                          | NotificacionID:
                                          {message.notificacion}
                                        </small>
                                        <small className="primary-font">
                                          {" "}
                                          | System:{message.system}
                                        </small>
                                      </div> */}
                                      <div className="d-flex">
                                        <small className="primary-font">
                                          <a
                                            href={`/products/automated_msg_booking/${message.reserva}`}
                                          >
                                            TourOpp ID: {message.reserva}
                                          </a>
                                        </small>
                                        <small className="primary-font">
                                          | {message.system} ID:{" "}
                                          {message.ordenNumber}
                                        </small>
                                        <small className="primary-font">
                                          | Booking Date: {message.dateBooking}
                                        </small>
                                      </div>
                                      {/* <div className="d-flex">
                                        <small className="primary-font">
                                          Type: {message.origin_msg}
                                        </small>
                                      </div> */}
                                      {/* <hr /> */}

                                      <hr />
                                      <p className="mb-0">{message.message}</p>
                                    </MDBCardBody>
                                  </MDBCard>
                                </li>
                              ))}
                            </MDBListGroup>
                          </MDBScrollbar>

                          <div className="form-group basic-textarea">
                            <textarea
                              className="form-control pl-2 my-0"
                              id="exampleFormControlTextarea2"
                              rows="3"
                              placeholder="Soon available..."
                              value={this.state.msg_out}
                              //onChange={(e) => this.send_msg(e.target.value)}
                              name="msg_out"
                              disabled
                            />
                            <MDBBtn
                              color="infoa"
                              // rounded
                              size="sm"
                              className="btn-square float-right mt-4 color_btn-g"
                              onClick={this.send_msg_action}
                              disabled
                            >
                              Send
                            </MDBBtn>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </React.Fragment>
                )
              ) : (
                <MDBCardBody>
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </MDBCardBody>
              )}
            </MDBCard>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    );
  }
}

const clicka = (value, allchats) => {
  const b = new ChatPage();

  const message = {
    author: value.from,
    avatar: "https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg",
    when: "17 mins ago",
    message: value.body,
    side: value.fromMe ? "out" : "in",
    id_: "111",
  };

  this.ChatMessage(message);
  const filtro = allchats
    .map((e) => {
      return e;
    })
    .filter((a, index) => {
      if (a.to_sms === value.to_sms) {
        return { a, index };
      }
    });
};

const Friend = ({
  friend: { name, avatar, message, when, toRespond, seen, active, chat_value },
  all_a,
}) => (
  <MDBListGroupItem
    className="d-flex justify-content-between p-2 border-light"
    style={{ backgroundColor: active ? "#eeeeee" : "" }}
    onClick={() => clicka(chat_value, all_a)}
    tag="button"
  >
    <MDBAvatar
      tag="img"
      src={avatar}
      alt="avatar"
      circle
      className="mr-2 z-depth-1"
    />
    <div style={{ fontSize: "0.95rem" }}>
      <strong>{name}</strong>
      <p className="text-muted">{message}</p>
    </div>
    <div>
      <p className="text-muted mb-0" style={{ fontSize: "0.75rem" }}>
        {when}
      </p>
      {seen ? (
        <span className="text-muted float-right">
          <MDBIcon icon="check" aria-hidden="true" />
        </span>
      ) : toRespond ? (
        <MDBBadge color="danger" className="float-right">
          {toRespond}
        </MDBBadge>
      ) : (
        <span className="text-muted float-right">
          <MDBIcon icon="reply" aria-hidden="true" />
        </span>
      )}
    </div>
  </MDBListGroupItem>
);

const ChatMessage = ({ message: { author, avatar, when, message, side } }) => (
  <li className={"chat-message justify-content-between mb-4 " + side}>
    <MDBAvatar
      tag="img"
      src={avatar}
      alt="avatar"
      circle
      className="mx-2 z-depth-1"
    />
    <MDBCard>
      <MDBCardBody>
        <div className="d-flex justify-content-between">
          <strong className="primary-font">{author}</strong>
          <small className="pull-right text-muted">
            <MDBIcon icon="clock" /> {when}
          </small>
        </div>
        <hr />
        <p className="mb-0">{message}</p>
      </MDBCardBody>
    </MDBCard>
  </li>
);

export default ChatPage;
