import React from "react";

import { withRouter } from "react-router-dom";

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  MDBSwitch,
  toast,
  ToastContainer,
} from "mdbreact";

import "./styleProductEdit.css";
// import './scrollbar.css';
import countriesCities from "./01-countries_cities.json";
import api from "../../helpers/api";

class ProductEdit extends React.Component {
  state = {
    loadingDataTable: true,
    modal: {
      state: false,
      section: "",
    },
    listConfig: "1",
    classGeneral: "list-config__item-a-s",
    classLogistics: "list-config__item-a",
    classDetails: "list-config__item-a",
    classPolicies: "list-config__item-a",
    classConfiguration: "list-config__item-a",
    productName: "",
    internalProductCode: "",
    productCode: "",
    selectCountryOptions: [],
    selectCountry: "",
    selectCityOptions: [],
    selectCity: "",
    localTimeZoneOptions: [],
    localTimeZone: "",
    meetingPointName: "",
    meetingPointAddress: "",
    meetingPointURL: "",
    meetingTime: "",
    dropoff: "",
    websiteProductURL: "",
    includes: "",
    attractions: "",
    recommendationURL: "",
    parking: "",
    cancelationPolicity: "",
    changesPolicy: "",
    covidPolicy: "",
    foodAndBeveragePolicy: "",
    extraTimePolicy: "",
    addPeoplePolicy: "",
    ignoreTemplateAutomatedMessage: true,
    ignoreTemplateChatbot: true,
    // countriesCitiesJSON: {},
  };

  timeZone = [
    "GMT-12",
    "GMT-11",
    "GMT-10",
    "GMT-9",
    "GMT-8",
    "GMT-7",
    "GMT-6",
    "GMT-5",
    "GMT-4",
    "GMT-3",
    "GMT-2",
    "GMT-1",
    "GMT+0",
    "GMT+1",
    "GMT+2",
    "GMT+3",
    "GMT+4",
    "GMT+5",
    "GMT+6",
    "GMT+7",
    "GMT+8",
    "GMT+9",
    "GMT+10",
    "GMT+11",
    "GMT+12",
  ];

  componentDidMount() {
    // console.log("-->edit", this.props.location.data);

    // console.log(countriesCities.Ecuador);

    // const temp = JSON.parse(countriesCities);

    // console.log(temp);

    let productId = "";

    if (
      this.props.location.data === "" ||
      this.props.location.data === undefined
    ) {
      productId = localStorage.getItem("productId");
    } else {
      localStorage.setItem("productId", this.props.location.data.idusr_tours);
      productId = this.props.location.data.idusr_tours;
    }

    // console.log("-->pid", productId);

    this.loadData(productId);
  }

  loadData = async (productId) => {
    try {
      this.setState({
        loadingDataTable: true,
      });
      const data = {
        productId: productId,
      };
      const productData = await api(
        "read_data_productEdit",
        "application/json",
        data
      );

      // console.log("-->", productData);

      let templateBot = false;
      let templateMsg = false;

      if (productData.data.data[0].ignore_template_bot === 1) {
        // console.log("true 1");
        templateBot = true;
      }
      if (productData.data.data[0].ignore_template_msg === 1) {
        // console.log("true 2");
        templateMsg = true;
      }
      // console.log("1");
      const countries = Object.keys(countriesCities);

      let countriesArray = [];
      let countrySelect = "";
      let cities = [];

      // console.log(productData.data.data[0].pais);

      if (
        productData.data.data[0].pais === undefined ||
        productData.data.data[0].pais === "" ||
        productData.data.data[0].pais === null
      ) {
        countries.map((val, index) => {
          countriesArray.push({
            text: `${val}`,
            value: `${index + 1}`,
          });
        });
      } else {
        countries.map((val, index) => {
          let existCountry = false;
          if (
            val.toLowerCase() === productData.data.data[0].pais.toLowerCase()
          ) {
            existCountry = true;
          }
          if (existCountry) {
            countriesArray.push({
              text: `${val}`,
              value: `${index + 1}`,
              checked: true,
            });
            countrySelect = `${val}`;
            cities = countriesCities[val];
          } else {
            countriesArray.push({
              text: `${val}`,
              value: `${index + 1}`,
            });
          }
        });
      }

      let citiesArray = [];
      let citySelect = "";

      if (
        productData.data.data[0].ciudad !== undefined &&
        productData.data.data[0].ciudad !== "" &&
        productData.data.data[0].ciudad !== null &&
        cities.length > 0
      ) {
        cities.map((val, index) => {
          let existCity = false;
          if (
            val.toLowerCase() === productData.data.data[0].ciudad.toLowerCase()
          ) {
            existCity = true;
          }
          if (existCity) {
            citiesArray.push({
              text: `${val}`,
              value: `${index + 1}`,
              checked: true,
            });
            citySelect = `${val}`;
          } else {
            citiesArray.push({
              text: `${val}`,
              value: `${index + 1}`,
            });
          }
        });
      } else {
        cities.map((val, index) => {
          citiesArray.push({
            text: `${val}`,
            value: `${index + 1}`,
          });
        });
      }

      let timeZoneArray = [];
      let timeZoneSelect = "";

      if (
        productData.data.data[0].local_time_zone !== undefined &&
        productData.data.data[0].local_time_zone !== "" &&
        productData.data.data[0].local_time_zone !== null
      ) {
        this.timeZone.map((val, index) => {
          let existTimeZone = false;
          if (
            val.toLowerCase() ===
            productData.data.data[0].local_time_zone.toLowerCase()
          ) {
            existTimeZone = true;
          }
          if (existTimeZone) {
            timeZoneArray.push({
              text: `${val}`,
              value: `${index + 1}`,
              checked: true,
            });
            timeZoneSelect = `${val}`;
          } else {
            timeZoneArray.push({
              text: `${val}`,
              value: `${index + 1}`,
            });
          }
        });
      } else {
        this.timeZone.map((val, index) => {
          timeZoneArray.push({
            text: `${val}`,
            value: `${index + 1}`,
          });
        });
      }

      this.setState({
        productName: productData.data.data[0].productName,
        internalProductCode: productData.data.data[0].idusr_tours,
        productCode: productData.data.data[0].productCode,
        selectCountryOptions: countriesArray,
        selectCountry: countrySelect,
        selectCityOptions: citiesArray,
        selectCity: citySelect,
        localTimeZoneOptions: timeZoneArray,
        localTimeZone: timeZoneSelect,
        meetingPointName: productData.data.data[0].meeting_point_name,
        meetingPointAddress: productData.data.data[0].meeting_point_address,
        meetingPointURL: productData.data.data[0].meeting_point_URL,
        meetingTime: productData.data.data[0].meeting_point_time,
        dropoff: productData.data.data[0].dropoff,
        websiteProductURL: productData.data.data[0].website_product_URL,
        includes: productData.data.data[0].includes,
        attractions: productData.data.data[0].atractivos,
        recommendationURL: productData.data.data[0].recomendation_URL,
        parking: productData.data.data[0].parking,
        cancelationPolicity: productData.data.data[0].cancelation_policity,
        changesPolicy: productData.data.data[0].changes_policy,
        covidPolicy: productData.data.data[0].covid_policy,
        foodAndBeveragePolicy:
          productData.data.data[0].food_and_beverage_policy,
        extraTimePolicy: productData.data.data[0].extra_time_policy,
        addPeoplePolicy: productData.data.data[0].add_people_policy,
        ignoreTemplateAutomatedMessage: templateMsg,
        ignoreTemplateChatbot: templateBot,
        loadingDataTable: false,
      });

      // console.log("productData--->", productData);
    } catch (e) {}
  };

  leftMenuHandleClick = (e, data, classLink) => {
    e.preventDefault();
    let classTemp = {
      classGeneral: "list-config__item-a",
      classLogistics: "list-config__item-a",
      classDetails: "list-config__item-a",
      classPolicies: "list-config__item-a",
      classConfiguration: "list-config__item-a",
    };

    classTemp = {
      ...classTemp,
      [classLink]: "list-config__item-a-s",
    };
    this.setState({
      listConfig: data,
      classGeneral: classTemp.classGeneral,
      classLogistics: classTemp.classLogistics,
      classDetails: classTemp.classDetails,
      classPolicies: classTemp.classPolicies,
      classConfiguration: classTemp.classConfiguration,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  selectedCountry = (country) => {
    if (country !== "") {
      console.log("country", country);
      const cities = countriesCities[country];
      let citiesArray = [];
      cities.map((val, index) => {
        citiesArray.push({
          text: `${val}`,
          value: `${index + 1}`,
        });
      });
      this.setState({
        selectCountry: country,
        selectCityOptions: citiesArray,
        selectCity: "",
      });
    }
  };

  selectedCity = (city) => {
    if (city !== "") {
      console.log("city", city);
      this.setState({
        selectCity: city,
      });
    }
  };

  selectedTimeZone = (timeZone) => {
    if (timeZone !== "") {
      console.log("timeZone", timeZone);
      this.setState({
        localTimeZone: timeZone,
      });
    }
  };

  openModal = (section) => {
    this.setState({
      modal: {
        ...this.state.modal,
        state: true,
        section: section,
      },
    });
  };

  closeModal = () => {
    this.setState({
      modal: {
        state: false,
        section: "",
      },
    });
  };

  saveChanges = () => {
    this.setState({
      modal: {
        state: false,
        section: "",
      },
    });
    this.saveData(this.state.modal.section);
  };

  saveData = async (section) => {
    try {
      if (section === "1") {
        this.setState({
          loadingDataTable: true,
        });

        const data = {
          productName: this.state.productName,
          country: this.state.selectCountry.toLowerCase(),
          city: this.state.selectCity.toLowerCase(),
          timeZone: this.state.localTimeZone.toLowerCase(),
          idProduct: this.state.internalProductCode,
        };

        const saveGeneral = await api(
          "save_general_productEdit",
          "application/json",
          data
        );
        console.log("saveGeneral", saveGeneral);
      }

      if (section === "2") {
        this.setState({
          loadingDataTable: true,
        });

        const data = {
          meetingPointName: this.state.meetingPointName,
          meetingPointAddress: this.state.meetingPointAddress,
          meetingPointURL: this.state.meetingPointURL,
          meetingTime: this.state.meetingTime,
          dropoff: this.state.dropoff,
          idProduct: this.state.internalProductCode,
        };

        const saveGeneral = await api(
          "save_logistic_productEdit",
          "application/json",
          data
        );
      }

      if (section === "3") {
        this.setState({
          loadingDataTable: true,
        });

        const data = {
          websiteProductURL: this.state.websiteProductURL,
          includes: this.state.includes,
          attractions: this.state.attractions,
          recommendationURL: this.state.recommendationURL,
          parking: this.state.parking,
          idProduct: this.state.internalProductCode,
        };

        const saveGeneral = await api(
          "save_details_productEdit",
          "application/json",
          data
        );
      }

      if (section === "4") {
        this.setState({
          loadingDataTable: true,
        });

        const data = {
          cancelationPolicity: this.state.cancelationPolicity,
          changesPolicy: this.state.changesPolicy,
          covidPolicy: this.state.covidPolicy,
          foodAndBeveragePolicy: this.state.foodAndBeveragePolicy,
          extraTimePolicy: this.state.extraTimePolicy,
          addPeoplePolicy: this.state.addPeoplePolicy,
          idProduct: this.state.internalProductCode,
        };

        const saveGeneral = await api(
          "save_policies_productEdit",
          "application/json",
          data
        );
      }

      const productId = localStorage.getItem("productId");
      this.loadData(productId);
    } catch (e) {}
  };

  handleSwitchChange = async (switchNumber) => {
    console.log("switchNumber", switchNumber);
    try {
      if (switchNumber === "1") {
        const stateSwitch = !this.state.ignoreTemplateAutomatedMessage;

        const data = {
          stateTemplateMessage: stateSwitch ? "1" : "0",
          idProduct: this.state.internalProductCode,
        };

        const saveGeneral = await api(
          "save_template_message_productEdit",
          "application/json",
          data
        );

        this.setState({
          ignoreTemplateAutomatedMessage: stateSwitch,
        });
      }
      if (switchNumber === "2") {
        const stateSwitch = !this.state.ignoreTemplateChatbot;

        const data = {
          stateTemplateChatbot: stateSwitch ? "1" : "0",
          idProduct: this.state.internalProductCode,
        };

        const saveGeneral = await api(
          "save_template_chatbot_productEdit",
          "application/json",
          data
        );

        this.setState({
          ignoreTemplateChatbot: stateSwitch,
        });
      }
    } catch (e) {}
  };

  backPage = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/products",
    });
  };

  render() {
    // console.log("--->", this.state);
    return (
      // <p>hello</p>
      <React.Fragment>
        <ToastContainer />

        <MDBModal
          isOpen={this.state.modal.state}
          toggle={this.closeModal}
          centered
        >
          <MDBModalHeader toggle={this.closeModal}></MDBModalHeader>
          <MDBModalBody className="text-center">
            <p>Are you sure want to save changes?</p>
            <div className="modal-buttons">
              <MDBBtn className="button-save" onClick={this.saveChanges}>
                Save
              </MDBBtn>
              <MDBBtn className="button-cancel" onClick={this.closeModal}>
                Cancel
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <div className="icon-product">
                <MDBIcon fab icon="product-hunt" size="2x" />
                <div>
                  <span>
                    <a href="#" onClick={(e) => this.backPage(e)}>
                      Products{" "}
                    </a>{" "}
                    / {this.state.productName}
                  </span>
                </div>
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              {/* <MDBBtn color="secondary" onClick={this.open_modal("new")}>
                New Automated Message
              </MDBBtn> */}
              {this.state.loadingDataTable ? (
                // <MDBDataTable
                //   striped
                //   bordered
                //   small
                //   paging={false}
                //   btn
                //   maxHeight="300px"
                //   className="table_warp"
                //   data={this.state.data}
                //   fixed
                // ></MDBDataTable>
                <MDBRow center style={{ height: "80vh" }}>
                  <MDBCol lg="3">
                    <MDBSpinner />
                  </MDBCol>
                </MDBRow>
              ) : (
                <div className="edit-product">
                  <div className="edit-product__left-box">
                    <div className="edit-product__left-container">
                      <ul className="list-config">
                        <li className="list-config__item">
                          <a
                            className={this.state.classGeneral}
                            href="#general"
                            // className={
                            //   this.props.menuLeftConfiguration.accountState
                            // }
                            // onClick={(e) => this.handleClick(e, "1")}
                            onClick={(e) =>
                              this.leftMenuHandleClick(e, "1", "classGeneral")
                            }
                          >
                            <span>General</span>
                          </a>
                        </li>
                        <li className="list-config__item">
                          <a
                            className={this.state.classLogistics}
                            href="#logistics"
                            // className={
                            //   this.props.menuLeftConfiguration.accountState
                            // }
                            // onClick={(e) => this.handleClick(e, "1")}
                            onClick={(e) =>
                              this.leftMenuHandleClick(e, "2", "classLogistics")
                            }
                          >
                            <span>Logistics</span>
                          </a>
                        </li>
                        <li className="list-config__item">
                          <a
                            className={this.state.classDetails}
                            href="#details"
                            // className={
                            //   this.props.menuLeftConfiguration.accountState
                            // }
                            // onClick={(e) => this.handleClick(e, "1")}
                            onClick={(e) =>
                              this.leftMenuHandleClick(e, "3", "classDetails")
                            }
                          >
                            <span>Details</span>
                          </a>
                        </li>
                        <li className="list-config__item">
                          <a
                            className={this.state.classPolicies}
                            href="#policies"
                            // className={
                            //   this.props.menuLeftConfiguration.accountState
                            // }
                            // onClick={(e) => this.handleClick(e, "1")}
                            onClick={(e) =>
                              this.leftMenuHandleClick(e, "4", "classPolicies")
                            }
                          >
                            <span>Policies</span>
                          </a>
                        </li>
                        <li className="list-config__item">
                          <a
                            className={this.state.classConfiguration}
                            href="#configuration"
                            // className={
                            //   this.props.menuLeftConfiguration.accountState
                            // }
                            // onClick={(e) => this.handleClick(e, "1")}
                            onClick={(e) =>
                              this.leftMenuHandleClick(
                                e,
                                "5",
                                "classConfiguration"
                              )
                            }
                          >
                            <span>Configuration</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="edit-product__right-box">
                    <div className="edit-product__right-container">
                      {this.state.listConfig === "1" && (
                        <MDBModalBody>
                          {/* <form
                            className="text-left"
                            noValidate
                            // onSubmit={this.verifyData}
                          > */}
                          <div className="md-form">
                            <MDBInput
                              label="Product Name"
                              type="text"
                              value={this.state.productName || ""}
                              onChange={this.handleChange}
                              name="productName"
                              required
                            ></MDBInput>
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Internal Product Code"
                              type="text"
                              value={this.state.internalProductCode || ""}
                              onChange={this.handleChange}
                              name="internalProductCode"
                              disabled
                            ></MDBInput>
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Product Code"
                              type="text"
                              value={this.state.productCode || ""}
                              onChange={this.handleChange}
                              name="productCode"
                              disabled
                            ></MDBInput>
                          </div>

                          <MDBSelect
                            // search
                            options={this.state.selectCountryOptions}
                            // selected="Choose your option"
                            label="Country"
                            getTextContent={this.selectedCountry}
                            className="scroll_select"
                            name="selectCountry"
                          ></MDBSelect>
                          {/* <div className={this.state.productValidate}>
                          select a product.
                        </div> */}

                          <MDBSelect
                            // search
                            options={this.state.selectCityOptions}
                            // selected="Choose your option"
                            label="City"
                            getTextContent={this.selectedCity}
                            className="scroll_select"
                            name="selectCity"
                          ></MDBSelect>
                          {/* <div className={this.state.dateValidate}>
                          select a date.
                        </div> */}

                          <MDBSelect
                            // selectAll
                            // multiple
                            options={this.state.localTimeZoneOptions}
                            // selected="Choose your option"
                            label="Local Time Zone"
                            getTextContent={this.selectedTimeZone}
                            className="scroll_select"
                            name="localTimeZone"
                          ></MDBSelect>

                          <div className="edit-product__button">
                            <MDBBtn
                              color="deep-orange"
                              // type="submit"
                              onClick={() => this.openModal("1")}
                            >
                              SAVE
                            </MDBBtn>
                          </div>
                          {/* </form> */}
                        </MDBModalBody>
                      )}

                      {this.state.listConfig === "2" && (
                        <MDBModalBody className="text-center">
                          {/* <form
                            className="text-left"
                            noValidate
                            // onSubmit={this.verifyData}
                          > */}
                          <div className="md-form">
                            <MDBInput
                              label="Meeting Point Name / @pointname"
                              type="text"
                              value={this.state.meetingPointName || ""}
                              onChange={this.handleChange}
                              name="meetingPointName"
                              required
                            ></MDBInput>
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Meeting Point Address"
                              type="text"
                              value={this.state.meetingPointAddress || ""}
                              onChange={this.handleChange}
                              name="meetingPointAddress"
                              required
                            ></MDBInput>
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Meeting Point URL"
                              type="text"
                              value={this.state.meetingPointURL || ""}
                              onChange={this.handleChange}
                              name="meetingPointURL"
                              required
                            ></MDBInput>
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Meeting Time"
                              type="text"
                              value={this.state.meetingTime || ""}
                              onChange={this.handleChange}
                              name="meetingTime"
                              required
                            ></MDBInput>
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Dropoff"
                              type="text"
                              value={this.state.dropoff || ""}
                              onChange={this.handleChange}
                              name="dropoff"
                              required
                            ></MDBInput>
                          </div>
                          {/* </form> */}
                          <div className="edit-product__button">
                            <MDBBtn
                              color="deep-orange"
                              type="submit"
                              onClick={() => this.openModal("2")}
                            >
                              SAVE
                            </MDBBtn>
                          </div>
                        </MDBModalBody>
                      )}

                      {this.state.listConfig === "3" && (
                        <MDBModalBody className="text-center">
                          {/* <form
                            className="text-left"
                            noValidate
                            // onSubmit={this.verifyData}
                          > */}
                          <div className="md-form">
                            <MDBInput
                              label="Website Product URL"
                              type="text"
                              value={this.state.websiteProductURL || ""}
                              onChange={this.handleChange}
                              name="websiteProductURL"
                              required
                            ></MDBInput>
                          </div>
                          <div className="edit-product__textarea">
                            <MDBInput
                              type="textarea"
                              label="Includes"
                              rows="5"
                              onChange={this.handleChange}
                              name="includes"
                              value={this.state.includes || ""}
                            />
                          </div>
                          <div className="edit-product__textarea">
                            <MDBInput
                              type="textarea"
                              label="Attractions"
                              rows="5"
                              onChange={this.handleChange}
                              name="attractions"
                              value={this.state.attractions || ""}
                            />
                          </div>
                          <div className="md-form">
                            <MDBInput
                              label="Recommendation URL"
                              type="text"
                              value={this.state.recommendationURL || ""}
                              onChange={this.handleChange}
                              name="recommendationURL"
                              required
                            ></MDBInput>
                          </div>
                          <div className="edit-product__textarea">
                            <MDBInput
                              type="textarea"
                              label="Parking"
                              rows="5"
                              onChange={this.handleChange}
                              name="parking"
                              value={this.state.parking || ""}
                            />
                          </div>
                          {/* </form> */}
                          <div className="edit-product__button">
                            <MDBBtn
                              color="deep-orange"
                              type="submit"
                              onClick={() => this.openModal("3")}
                            >
                              SAVE
                            </MDBBtn>
                          </div>
                        </MDBModalBody>
                      )}

                      {this.state.listConfig === "4" && (
                        <MDBModalBody className="text-center">
                          {/* <form
                            className="text-left"
                            noValidate
                            // onSubmit={this.verifyData}
                          > */}
                          <div className="md-form">
                            <MDBInput
                              label="Cancelation Policity"
                              type="text"
                              value={this.state.cancelationPolicity || ""}
                              onChange={this.handleChange}
                              name="cancelationPolicity"
                              required
                            ></MDBInput>
                          </div>

                          <div className="md-form">
                            <MDBInput
                              label="Changes Policy"
                              type="text"
                              value={this.state.changesPolicy || ""}
                              onChange={this.handleChange}
                              name="changesPolicy"
                              required
                            ></MDBInput>
                          </div>

                          <div className="md-form">
                            <MDBInput
                              label="COVID Policy"
                              type="text"
                              value={this.state.covidPolicy || ""}
                              onChange={this.handleChange}
                              name="covidPolicy"
                              required
                            ></MDBInput>
                          </div>

                          <div className="md-form">
                            <MDBInput
                              label="Food And Beverage Policy"
                              type="text"
                              value={this.state.foodAndBeveragePolicy || ""}
                              onChange={this.handleChange}
                              name="foodAndBeveragePolicy"
                              required
                            ></MDBInput>
                          </div>

                          <div className="md-form">
                            <MDBInput
                              label="Extra Time Policy"
                              type="text"
                              value={this.state.extraTimePolicy || ""}
                              onChange={this.handleChange}
                              name="extraTimePolicy"
                              required
                            ></MDBInput>
                          </div>

                          <div className="md-form">
                            <MDBInput
                              label='"Add People" Policy'
                              type="text"
                              value={this.state.addPeoplePolicy || ""}
                              onChange={this.handleChange}
                              name="addPeoplePolicy"
                              required
                            ></MDBInput>
                          </div>
                          {/* </form> */}
                          <div className="edit-product__button">
                            <MDBBtn
                              color="deep-orange"
                              type="submit"
                              onClick={() => this.openModal("4")}
                            >
                              SAVE
                            </MDBBtn>
                          </div>
                        </MDBModalBody>
                      )}

                      {this.state.listConfig === "5" && (
                        <MDBModalBody className="text-center">
                          <form
                            className="text-left"
                            noValidate
                            // onSubmit={this.verifyData}
                          >
                            <div className="list-edit__text-switch">
                              <span>Ignore Template Automated Message</span>
                              <MDBSwitch
                                checked={
                                  this.state.ignoreTemplateAutomatedMessage
                                }
                                onChange={() => this.handleSwitchChange("1")}
                              />
                            </div>
                            <br />
                            <div className="list-edit__text-switch">
                              <span>Ignore Template Chatbot</span>
                              <MDBSwitch
                                checked={this.state.ignoreTemplateChatbot}
                                onChange={() => this.handleSwitchChange("2")}
                              />
                            </div>
                          </form>
                        </MDBModalBody>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </React.Fragment>
    );
  }
}

export default withRouter(ProductEdit);
