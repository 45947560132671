export default [
  {
    label: "Id Webhook",
    field: "id_webhook",
    sort: "desc",
    minimal: "size-col-10",
  },
  {
    label: "Payload",
    field: "json",
    sort: "asc",
    minimal: "size-col-30",
  },
  {
    label: "Status",
    field: "estado",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "System",
    field: "sistema",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-20 ",
  },
];
