import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBCardHeader,
  MDBSpinner,
  MDBDatePicker,
  MDBSelect,
} from "mdbreact";
import { withRouter, Redirect } from "react-router-dom";
import MDBDataTable from "../../source-pack/DataTable/DataTable";

import FileDownload from "js-file-download";

import configTable from "./01_config_table_reports.js";
import apiAdmin from "../../helpers/apiAdmin";

import moment from "moment";

import ReportsButton from "./02-reports_button";
import axios from "axios";
import { config } from "../../config/config";
import getJWT_admin from "../../helpers/jwt_ADMIN";
import { async } from "q";
const api = config.backendApiUrl;

class ReportsGeneration extends React.Component {
  state = {
    loader: true,
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    usernames: [],
    userArray: [],
    usersInformation: [],
    tableData: [],
  };

  async componentDidMount() {
    try {
      this.setState({
        loader: true,
      });
      const rta = await apiAdmin("reports/users", "application/json");

      const usernames = rta.data.map((user) => {
        return {
          checked: false,
          disabled: false,
          icon: null,
          value: user.username,
          text: user.username,
        };
      });

      const rtaReports = await apiAdmin("reports", "application/json");

      const reportRow = this.getReportRows(rtaReports);

      this.setState({
        loader: false,
        usernames,
        usersInformation: rta.data,
        tableData: {
          columns: configTable,
          rows: reportRow,
        },
      });
    } catch (error) {}
  }

  getReportRows = (rtaReports) => {
    const reportRow = rtaReports.data.map((report) => {
      return {
        reportName: report.reportName,
        createdAt: report.createdAt,
        actions: (
          <ReportsButton
            downloadReport={this.downloadReport}
            deleteReport={this.deleteReport}
            buttonStatus={report.url ? true : false}
            url={report.url}
            reportName={report.reportName}
          />
        ),
      };
    });

    return reportRow;
  };

  downloadReport = async (url) => {
    const jwt_ADMIN = getJWT_admin();

    const rta = await axios.post(
      `${api}reports/download_report`,
      { url },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_ADMIN}`,
        },
        responseType: "blob",
      }
    );

    FileDownload(rta.data, url.replace("src/public/reports/", ""));
  };

  deleteReport = async (url, reportName) => {
    const rta = await apiAdmin("reports/delete_report", "application/json", {
      url,
      reportName,
    });

    const reportRow = this.getReportRows(rta);

    this.setState({
      tableData: {
        columns: configTable,
        rows: reportRow,
      },
    });
  };

  setUser = (user) => {
    if (user) {
      const usersSeparator = user.split(",");
      const userArray = usersSeparator.map((username) => {
        return username.trim();
      });
      this.setState({ userArray });
    } else {
      this.setState({ userArray: [] });
    }
  };

  saveData = async () => {
    const { startDate, endDate, userArray, usersInformation } = this.state;
    if (startDate && endDate && userArray.length > 0) {
      const usersData = userArray.map((username) => {
        return usersInformation.find(
          (information) => information.username === username
        );
      });

      const rta = await apiAdmin(
        "reports/generateReports",
        "application/json",
        { startDate, endDate, usersData }
      );
      const reportRow = this.getReportRows(rta);

      this.setState({
        tableData: {
          columns: configTable,
          rows: reportRow,
        },
      });
    }
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon far icon="calendar-check" /> Reports
            </MDBCardHeader>

            <MDBCardBody>
              <MDBRow className="espace">
                <MDBCol md="3">
                  <div className="md-form">
                    <MDBDatePicker
                      value={this.state.startDate}
                      getValue={(val) =>
                        this.setState({
                          startDate: moment(val).format("YYYY-MM-DD"),
                        })
                      }
                      format="YYYY-MM-DD"
                    />
                  </div>
                </MDBCol>

                <MDBCol md="3">
                  <div className="md-form">
                    <MDBDatePicker
                      value={this.state.endDate}
                      getValue={(val) =>
                        this.setState({
                          endDate: moment(val).format("YYYY-MM-DD"),
                        })
                      }
                      format="YYYY-MM-DD"
                    />
                  </div>
                </MDBCol>

                <MDBCol md="4">
                  <div className="md-form">
                    <MDBSelect
                      search
                      multiple
                      options={this.state.usernames}
                      color="primary"
                      label="usernames"
                      name="usernames"
                      getTextContent={this.setUser}
                    />
                  </div>
                </MDBCol>

                <MDBBtn
                  color="deep-orange"
                  type="button"
                  onClick={() => {
                    this.saveData();
                  }}
                >
                  Generate Report
                </MDBBtn>
              </MDBRow>
            </MDBCardBody>

            {this.state.loader ? (
              <MDBRow
                className="page-loading"
                center
                style={{ height: "80vh" }}
              >
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBCol lg="12">
                <MDBCard className="text-left">
                  <MDBCardHeader className="white-text color_select p-4 text-left">
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon far icon="calendar-check" /> Reports Result
                      </MDBCol>
                    </MDBRow>
                  </MDBCardHeader>

                  <MDBCardBody>
                    <MDBDataTable
                      striped
                      bordered
                      small
                      paging={false}
                      btn
                      maxHeight="300px"
                      className="table_wrap"
                      data={this.state.tableData}
                      fixed
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            )}
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withRouter(ReportsGeneration);
