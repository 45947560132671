import React from "react";

import { MDBBtn, MDBIcon } from "mdbreact";
import ModalStatusSms from "./03-Modal-statusSMS";

class ButtonsStatusSMS extends React.Component {
  state = {
    modal: false,
  };
  closeModal = () => {
    this.setState({
      modal: false,
    });
  };
  openModal = () => {
    this.setState({
      modal: true,
    });
  };
  render() {
    return (
      <React.Fragment>
        <MDBBtn
        title='See message information'
          tag="a"
          className="btn-square color_btn-d "
          onClick={this.openModal}
          color="infoa"
        >
          <MDBIcon far icon="eye" className="size_icon" />
        </MDBBtn>
        <ModalStatusSms
          isOpen={this.state.modal}
          closeModal={this.closeModal}
          data={this.props.data}
          segments={this.props.segments}
        />
      </React.Fragment>
    );
  }
}

export default ButtonsStatusSMS;
