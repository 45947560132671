import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";

import api from "../../../helpers/api";

class Buttons_automated_msg extends React.Component {
  state = {
    id: "",
    modals: {
      form: false,
      header: {
        title: "Edit Automated Message",
        color: "color_select",
      },
      component: "",
      centered: true,
    },
  };

  delete_data = async (a) => {
    // console.log("query id",a);
    const res = await api("delete_msg", "application/json", { id: a });

    // console.log("query 2 delete",res.data);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  force_send = async (a) => {
    // console.log("query id force",a);
    const res = await api("force_msg_automated", "application/json", { id: a });
    // console.log("query force_msg_automated",res.data);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  modal_data = async (data, id) => {
    // console.log("data from click delete",data,id);
    if (data === "delete") {
      // console.log("state",this.state.modals.form)
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.modal_data("new-togle", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.delete_data(id)}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
    if (data === "new-togle") {
      // console.log("state",this.state.modals.form)
      this.setState({
        modals: {
          form: false,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "send") {
      // console.log("state",this.state.modals.form)
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Force Message",
            color: "color_select",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>
                    Are you sure you want to force the sending of the msg?
                  </h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.modal_data("new-togle", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.force_send(id)}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  refresh_data = () => {
    // console.log("refresh data");
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.modals.form}
          toggle={() => this.modal_data("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            className={
              this.state.modals.header.color + " white-text text-center"
            }
            titleClass="w-100"
          >
            <h5 className="text-center">{this.state.modals.header.title}</h5>
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>
        <MDBContainer className="center-button">
          {/* <MDBBtn
            title='Delete message'
            tag="a"
            className="btn-square color_btn-red "
            onClick={() => this.modal_data("delete", this.props.id_)}
            color="danger"
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn> */}

          <MDBDropdown className="options_">
            <MDBDropdownToggle
              title='More options'
              color="infoa"
              className="btn-square color_btn-yellow"
            >
              <MDBIcon icon="plus" className="size_icon" />
            </MDBDropdownToggle>
            <MDBDropdownMenu color="warning" basic>
              <MDBDropdownItem
                onClick={() => this.modal_data("send", this.props.id_)}
              >
                Force Send
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default Buttons_automated_msg;
