import React from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBDataTable,
  MDBModalBody,
  MDBRow,
  MDBSpinner,
} from "mdbreact";

import moment from "moment";
import isgsm7 from "isgsm7";
import api from "../../../helpers/api";
import config_table_msg from "./01-config_sms_usage_table";

class SmsUsed extends React.Component {
  state = {
    loadData: true,
    messageUsage: {
      columns: config_table_msg,
      rows: [],
    },
  };

  async componentDidMount() {
    // this.loadData();
    const rta = await api("dashboard_message_data", "application/json", {
      initialDate: moment().startOf("day").format("YYYY-MM-DD"),
      endDate: moment().add(1, "days").startOf("day").format("YYYY-MM-DD"),
    });

    // console.log("rta :>> ", rta);

    const messageData = rta.data.reduce(
      (obj, element) => {
        obj.sent = element.sentMessage;
        obj.received = element.receivedMessage;
        return obj;
      },
      { sent: 0, received: 0 }
    );
    // console.log("messageData :>> ", messageData);
    this.setState({
      messageUsage: {
        ...this.state.messageUsage,
        rows: [
          {
            typeMessage: "Sent",
            quantity: messageData.sent,
            // percent: "---",
          },
          {
            typeMessage: "Received",
            quantity: messageData.received,
            // percent: percentCanceled.toString() + " %",
          },
          {
            typeMessage: "Total",
            quantity: messageData.sent + messageData.received,
            // percent: percentCanceled.toString() + " %",
          },
        ],
      },
      loadData: false,
    });
  }

  loadData = async () => {
    this.setState({
      loadData: true,
    });
    try {
      // console.log("sms---->", this.props.startDate, this.props.endDate);
      const query = {
        initial_date: this.props.startDate,
        finishing_date: this.props.endDate,
      };
      const a = await api(
        "counter_message_dashboard",
        "application/json",
        query
      );

      // console.log("data-->", a.data);
      let counterInSMS = 0;
      let counterOutSMS = 0;
      let counterOut = 0;
      let counterin = 0;
      a.data.data.map((val) => {
        let validSMS = true;
        if (val.status === "accepted") {
          validSMS = false;
        }
        if (val.status === "inactive") {
          validSMS = false;
        }
        if (val.status === "error in send msg to twilio") {
          validSMS = false;
        }
        if (val.status === "inactive or don´t have messaging service") {
          validSMS = false;
        }
        if (val.status === "error") {
          validSMS = false;
        }

        if (val.type === "in" && validSMS) {
          // const jsonSMS = JSON.parse(val.json);
          // let segments = "";
          // if (jsonSMS.numSegments) {
          //   segments = jsonSMS.numSegments;
          //   counterInSMS += parseInt(segments);
          // } else {
          //   segments = jsonSMS.NumSegments;
          //   counterInSMS += parseInt(segments);
          // }
          let msg = val.body.replace(/^"(.+(?="$))"$/, "$1");
          msg = msg.replace(/\\n/g, " ");
          msg = msg.trim();

          let maxSms = 153;

          if (!isgsm7(msg)) {
            maxSms = 67;
          }

          const segments = Math.ceil(msg.length / maxSms);

          counterInSMS = counterInSMS + segments;

          counterin++;
        }

        if (val.type === "out" && validSMS) {
          // const jsonSMS = JSON.parse(val.json);
          // let segments = "";
          // if (jsonSMS.numSegments) {
          //   segments = jsonSMS.numSegments;
          //   counterOutSMS += parseInt(segments);
          // } else {
          //   segments = jsonSMS.NumSegments;
          //   counterOutSMS += parseInt(segments);
          // }

          let msg = val.body.replace(/^"(.+(?="$))"$/, "$1");
          msg = msg.replace(/\\n/g, " ");
          msg = msg.trim();

          let maxSms = 153;

          if (!isgsm7(msg)) {
            maxSms = 67;
          }

          const segments = Math.ceil(msg.length / maxSms);

          counterOutSMS = counterOutSMS + segments;

          counterOut++;
        }
      });

      // console.log("counter-->", counterInSMS, counterOutSMS);

      const totalSMS = counterInSMS + counterOutSMS;

      // console.log("total-->", totalSMS);

      // console.log("delivered--->", counterOut);
      // console.log("received--->", counterin);

      this.setState({
        data: {
          ...this.state.data,
          rows: [
            {
              typeMessage: "Sent",
              quantity: counterOutSMS,
              // percent: "---",
            },
            {
              typeMessage: "Received",
              quantity: counterInSMS,
              // percent: percentCanceled.toString() + " %",
            },
            {
              typeMessage: "Total",
              quantity: totalSMS,
              // percent: percentCanceled.toString() + " %",
            },
          ],
        },
        loadData: false,
      });

      // console.log("data dashboard", a.data);
    } catch (e) {}
  };

  render() {
    // console.log(this.state.data);
    return (
      <React.Fragment>
        <MDBCard className="text-left">
          <MDBCardHeader className="white-text color_select text-left">
            Message Usage from the past 30 days
          </MDBCardHeader>
          <MDBCardBody>
            {this.state.loadData ? (
              <MDBRow center style={{ height: "8vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow>
                <MDBCol md="12">
                  <MDBDataTable
                    striped
                    searching={false}
                    bordered
                    small
                    paging={false}
                    btn
                    maxHeight="300px"
                    className="table_warp"
                    data={this.state.messageUsage}
                    fixed
                    sortable={false}
                    noBottomColumns
                  ></MDBDataTable>
                </MDBCol>
              </MDBRow>
            )}
          </MDBCardBody>
        </MDBCard>
      </React.Fragment>
    );
  }
}

export default SmsUsed;
