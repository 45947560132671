import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

import Edit_form from "./05-edit_user";
import api from "../../helpers/api";
import AddWebhook from "./06-Add-Webhook";
import UserSettings from "./07_userSettings";
import UserStatus from "./08_user_status";

class Buttons_config_user extends React.Component {
  state = {
    id: "",
    modals: {
      showModal: false,
      header: {
        title: "",
        color: "",
      },
      component: "",
      centered: true,
    },
  };

  delete_data = async (a) => {
    const res = await api("delete_bot_msg", "application/json", { id: a });

    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  activate_user = async (data) => {
    try {
      //API new
      const res = await api("active_user", "application/json", { data });
      this.props.refresh();
      this.modal_data("new-togle", "");
    } catch (e) {}
  };

  modal_data = async (data, id) => {
    // if (data === "delete") {
    //   this.setState({
    //     id: id,
    //     modals: {
    //       form: true,
    //       header: {
    //         title: "Delete Message",
    //         color: "color_select",
    //       },
    //       component: (
    //         <Fragment>
    //           <MDBRow>
    //             <MDBCol md="12">
    //               <h6>Are you sure you want to delete this message?</h6>
    //             </MDBCol>

    //             <MDBCol md="12">
    //               <MDBBtn
    //                 color="danger"
    //                 className="color_select"
    //                 onClick={() => this.modal_data("new-togle", "")}
    //               >
    //                 Cancel
    //               </MDBBtn>
    //               <MDBBtn
    //                 color="danger"
    //                 className="color_btn-red"
    //                 onClick={() => this.delete_data(id)}
    //               >
    //                 Continue
    //               </MDBBtn>
    //             </MDBCol>
    //           </MDBRow>
    //         </Fragment>
    //       ),
    //       centered: false,
    //     },
    //   });
    // }

    if (data === "new-togle") {
      this.setState({
        modals: {
          showModal: false,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "userStatus") {
      this.setState({
        id: id,
        modals: {
          showModal: true,
          header: {
            title: "User Status",
            color: "color_select",
          },
          component: (
            <UserStatus
              data_edit={this.props.userInformation}
              id_={id}
              refresh_={this.refresh_data.bind(this)}
              modalData={this.modal_data}
            />
          ),
          centered: true,
        },
      });
    }

    // if (data === "userSettings") {
    //   this.setState({
    //     id: id,
    //     modals: {
    //       showModal: true,
    //       header: {
    //         title: "User configuration",
    //         color: "color_select",
    //       },
    //       component: (
    //         <UserSettings
    //           data_edit={this.props.userInformation}
    //           id_={id}
    //           refresh_={this.refresh_data.bind(this)}
    //         />
    //       ),
    //       centered: true,
    //     },
    //   });
    // }

    if (data === "addWebhook") {
      this.setState({
        id: id,
        modals: {
          showModal: true,
          header: {
            title: "Add Webhook",
            color: "color_select",
          },
          component: (
            <AddWebhook
              data_edit={this.props.userInformation}
              id_={id}
              refresh_={this.refresh_data.bind(this)}
            />
          ),
          centered: true,
        },
      });
    }

    if (data === "load_user_data") {
      const res = await api("login", "application/json", {
        user_name: this.props.userName,
        password: this.props.password,
      });

      if (res.data.token) {
        localStorage.setItem("jwt", res.data.token);
        localStorage.setItem("userId", this.props.userInformation.id_usuario);
        localStorage.setItem("SMSStatus", res.data.SMSCounter);
        window.location.href = "/a";
      } else {
      }
    }

    // if (data === "active") {
    //   this.setState({
    //     id: id,
    //     modals: {
    //       form: true,
    //       header: {
    //         title:
    //           id.SMS_Service === "active" ? "Deactivate user" : "Active User",
    //         color: "color_select",
    //       },
    //       component: (
    //         <Fragment>
    //           <MDBRow>
    //             <MDBCol md="12">
    //               <h6>
    //                 Are you sure you want to{" "}
    //                 {id.SMS_Service === "active" ? "deactivate" : "activate"}{" "}
    //                 user?
    //               </h6>
    //             </MDBCol>

    //             <MDBCol md="12">
    //               <MDBBtn
    //                 color="danger"
    //                 className="color_select"
    //                 onClick={() => this.modal_data("new-togle", "")}
    //               >
    //                 Cancel
    //               </MDBBtn>
    //               <MDBBtn
    //                 color="danger"
    //                 className="color_btn-red"
    //                 onClick={() => this.activate_user(id)}
    //               >
    //                 Continue
    //               </MDBBtn>
    //             </MDBCol>
    //           </MDBRow>
    //         </Fragment>
    //       ),
    //       centered: false,
    //     },
    //   });
    // }
  };

  userSettingsModal = () => {
    this.setState({
      modals: {
        showModal: true,
        header: {
          title: "User configuration",
          color: "color_select",
        },
        component: (
          <UserSettings
            userInformation={this.props.userInformation}
            refresh={this.refresh_data.bind(this)}
          />
        ),
        centered: true,
      },
    });
  };

  refresh_data = () => {
    this.props.refresh();
  };

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.modals.showModal}
          toggle={() => this.modal_data("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            className={
              this.state.modals.header.color + " white-text text-center"
            }
            titleClass="w-100"
          >
            <p className="text-center">{this.state.modals.header.title}</p>
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBContainer className="center-button">
          <MDBBtn
            title="User configuration"
            tag="a"
            className="btn-square color_btn-c"
            // onClick={() => this.modal_data("userSettings", this.props.id_)}
            onClick={() => this.userSettingsModal()}
            color="infoa"
          >
            <MDBIcon icon="cog" />
          </MDBBtn>

          <MDBBtn
            title="Add a webhook to account"
            tag="a"
            className="btn-square color_btn-c"
            onClick={() => this.modal_data("addWebhook", this.props.id_)}
            color="infoa"
          >
            <MDBIcon far icon="edit" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            tag="a"
            title={
              this.props.userInformation.statusDocker === "active"
                ? "Active account"
                : this.props.userInformation.statusDocker === "onboarding"
                ? "Onboarding account"
                : this.props.userInformation.statusDocker === "pause"
                ? "Pause account"
                : "Deactive account"
            }
            className={
              this.props.userInformation.statusDocker === "active"
                ? "btn-square color_btn-yellow"
                : this.props.userInformation.statusDocker === "onboarding"
                ? "btn-square color_btn-d"
                : this.props.userInformation.statusDocker === "pause"
                ? "btn-square color_btn-c"
                : "btn-square color_btn-red"
            }
            onClick={() => this.modal_data("userStatus")}
            color="infoa"
          >
            <MDBIcon icon="check" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            tag="a"
            title="Go this account"
            className="btn-square color_btn-c"
            onClick={() => this.modal_data("load_user_data", this.props.id_)}
            color="infoa"
          >
            {/* <MDBIcon icon="sync-alt" className="size_icon" /> */}
            <MDBIcon icon="chevron-right" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default Buttons_config_user;
