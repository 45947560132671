import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// import GlobalHistory from "./Spy";

import Main from "./pages/main";
import chatbot from "../src/pages/chatbot";
import chat from "../src/pages/chat";
import Login from "../src/components_fn/form_login";
import Auth_ from "./components/auth_2";
import Products_list from "./pages/products";
import Products_automated_msg from "./pages/products_automated_msg";
import Products_chatbot from "./pages/products_chatbot";
import Products_booking from "./pages/products_booking";
import Products_automated_msg_booking from "./pages/products_automated_msg_booking";
import Bookings from "./pages/bookings";
import StatusWP from "./pages/status_wp";
import Logout_ from "./components_fn/logout_";
import Settings from "./pages/settings";
import InformeSMS from "./pages/informeSMS";
import SMSServicePage from "./pages/sms_service";
import Dashboard from "./pages/dashboard";
import AdminPage from "./pages/admin";
//import DesignPage from "./pages/desing";
import AuthAdmin from "./components/auth_2admin";
import monitoringMicroservice from "./pages/monitoringMicroservice";
import email_alerts from "./pages/email_alerts";
import BroadcastingMessage from "./pages/broadcastingMessage";
import ProductEditPage from "./pages/productEditPage";
import reportInformation from "./pages/reportInformation";

// import ReactGA from "react-ga";
// import config from "./config";

//import UsersAdmin from "./pages/admin_pages/usersAdmin";

//import CommandsAdmin from "./pages/commandsAdmin";
//changes
//import Mocap from "./pages/mocap_main_page";
//import UsersAdminEdit from "./pages/admin_pages/usersAdminEdit";
//import UsersAdminNew from "./pages/admin_pages/usersAdminNew";

export default function App() {
  // useEffect(() => {
  //   console.log("-->useeffect", window.location.pathname);
  //   ReactGA.initialize(config.googleAnalytics);
  //   ReactGA.pageview(window.location.pathname);
  // });
  return (
    <div className="app">
      <div className="white-skin">
        <Router>
          <div>
            <Switch>
              {/* <GlobalHistory /> */}
              <Route
                path="/a"
                render={(props) => <Auth_ {...props} Component={Main} />}
              />

              <Route
                path="/b"
                render={(props) => <Auth_ {...props} Component={chatbot} />}
              />

              {/* <Route
                path="/chat"
                render={(props) => <Auth_ {...props} Component={chat} />}
              /> */}

              <Route
                path="/user"
                render={(props) => <Auth_ {...props} Component={Settings} />}
              />

              <Route
                path="/broadcasting_message"
                render={(props) => (
                  <Auth_ {...props} Component={BroadcastingMessage} />
                )}
              />

              <Route
                path="/bookings"
                render={(props) => <Auth_ {...props} Component={Bookings} />}
              />

              <Route
                exact
                path="/products/add_automated_msg_product/:id"
                render={(props) => (
                  <Auth_ {...props} Component={Products_automated_msg} />
                )}
              />

              <Route
                exact
                path="/products"
                render={(props) => (
                  <Auth_ {...props} Component={Products_list} />
                )}
              />

              <Route
                exact
                path="/products/edit"
                render={(props) => (
                  <Auth_ {...props} Component={ProductEditPage} />
                )}
              />

              <Route
                exact
                path="/products/add_chatbot_msg_product/:id"
                render={(props) => (
                  <Auth_ {...props} Component={Products_chatbot} />
                )}
              />

              <Route
                exact
                path="/products/list_booking_product/:id"
                render={(props) => (
                  <Auth_ {...props} Component={Products_booking} />
                )}
              />

              <Route
                exact
                path="/products/automated_msg_booking/:id"
                render={(props) => (
                  <Auth_
                    {...props}
                    Component={Products_automated_msg_booking}
                  />
                )}
              />

              <Route
                exact
                path="/monitoringMicroservice"
                render={(props) => (
                  <AuthAdmin {...props} Component={monitoringMicroservice} />
                )}
              />

              <Route
                exact
                path="/email_alerts"
                render={(props) => (
                  <AuthAdmin {...props} Component={email_alerts} />
                )}
              />

              <Route
                exact
                path="/status_wp"
                render={(props) => <Auth_ {...props} Component={StatusWP} />}
              />

              <Route
                exact
                path="/status_sms"
                render={(props) => <Auth_ {...props} Component={InformeSMS} />}
              />

              {/* <Route
                exact
                path="/dashboard/:start/:end"
                render={(props) => <Auth_ {...props} Component={Dashboard} />}
              /> */}
              <Route
                exact
                path="/dashboard"
                render={(props) => <Auth_ {...props} Component={Dashboard} />}
              />

              <Route
                exact
                path="/ADMIN"
                render={(props) => (
                  <AuthAdmin {...props} Component={AdminPage} />
                )}
              />

              <Route
                exact
                path="/reports"
                render={(props) => (
                  <AuthAdmin {...props} Component={reportInformation} />
                )}
              />

              {/*  <Route
                exact
                path="/design"
                render={(props) => <DesignPage></DesignPage>}
              />
                
              <Route
                exact
                path="/usersAdmin"
                render={(props) => (
                  <AuthAdmin {...props} Component={UsersAdmin} />
                )}
              />
              <Route
                exact
                path="/userAdmin/edit"
                render={(props) => (
                  <AuthAdmin {...props} Component={UsersAdminEdit} />
                )}
              />

              <Route
                exact
                path="/userAdmin/new"
                render={(props) => (
                  <AuthAdmin {...props} Component={UsersAdminNew} />
                )}
              />

              <Route
                exact
                path="/mocap"
                render={(props) => (
                  <AuthAdmin {...props} Component={Mocap} />
                )}
              />

              <Route
                exact
                path="/commandsAdmin"
                render={(props) => (
                  <AuthAdmin {...props} Component={CommandsAdmin} />
                )}
              />
              */}
              {/* <Route
                    exact
                    path="/SMS_service"
                    render={props => <Auth_ {...props} Component={SMSServicePage} />}
                  /> */}

              <Route exact path="/logout">
                <Logout_ />
              </Route>
              <Route exact path="/">
                <Redirect to="/login">
                  <Login />
                </Redirect>
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </div>
  );
}

//hola
