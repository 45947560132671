import React from "react";

import { MDBBtn, MDBIcon, MDBInput, MDBSelect } from "mdbreact";

import "./styleEditConfiguration.css";
import ModalEditConfiguration from "./07-modalEditConfiguration";
import ModalDeleteEditConfiguration from "./08-modalDeleteEditConfiguration";

class PhoneNumberConfiguration extends React.Component {
  state = {
    validating: false,
    saveData: false,
    deleteData: false,
    options: [],
    testPhoneNumber: "",
    servicePhoneNumber: "",
    countries: "",
    classTestPhoneNumber: "",
    classCountries: "",
    sectionPage: "phoneNumberConfiguration",
    sectionPage1: "deletePhoneNumberConfiguration",
    dataSection: [],
    dataSection1: [],
  };

  componentDidMount() {
    let servicePhoneNumber = "";
    let testPhoneNumber = "";
    if (
      this.props.data.numero_wp !== "" &&
      this.props.data.numero_wp !== undefined &&
      this.props.data.numero_wp !== "null"
    ) {
      servicePhoneNumber = Number(this.props.data.numero_wp);
    }
    if (
      this.props.data.whatsapp_test_number !== "" &&
      this.props.data.whatsapp_test_number !== undefined &&
      this.props.data.whatsapp_test_number !== "null"
    ) {
      testPhoneNumber = Number(this.props.data.whatsapp_test_number);
    }
    this.setState({
      options: [
        {
          text: this.props.data.countries_allowed,
          value: "1",
        },
      ],
      testPhoneNumber,
      servicePhoneNumber,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  countriesSelect = (e) => {
    this.setState({
      countries: e,
    });
  };

  saveChanges = async () => {
    this.setState({
      validating: true,
    });

    const testPhoneNumber = await this.validateData(
      this.state.testPhoneNumber,
      "classTestPhoneNumber"
    );

    const countries = await this.validateData(
      this.state.countries,
      "classCountries"
    );

    this.setState({
      validating: false,
    });

    if (testPhoneNumber && countries) {
      this.setState({
        saveData: true,
        dataSection: {
          phoneNumber: this.state.testPhoneNumber,
          counties: this.state.countries,
        },
      });
    }
  };

  validateData = (e, d) => {
    return new Promise((resolve, reject) => {
      if (e === "" || e === undefined || e === null) {
        this.setState({
          [d]: "invalidate-field",
        });
        resolve(false);
      } else {
        this.setState({
          [d]: "validate-field",
        });
        resolve(true);
      }
    });
  };

  closeModal = () => {
    this.setState({
      saveData: false,
    });
  };

  closeModalDelete = () => {
    this.setState({
      deleteData: false,
    });
  };

  deletePhoneNumber = (e) => {
    e.preventDefault();
    this.setState({
      deleteData: true,
      dataSection1: {
        phoneNumber: this.state.servicePhoneNumber,
      },
    });
  };

  render() {
    return (
      <div className="container-right__data">
        <div className="field-input__icon">
          <MDBInput
            label="Service Phone Number"
            size="sm"
            value={this.state.servicePhoneNumber}
            disabled
          ></MDBInput>
          <a onClick={this.deletePhoneNumber}>
            <MDBIcon far icon="trash-alt" />
          </a>
          <ModalDeleteEditConfiguration
            closeModal={this.closeModalDelete}
            open={this.state.deleteData}
            titleDelete="Are you sure want to delete service phone number?"
            informationDelete="This process cannot be reversed"
            saveChanges={this.props.saveChanges}
            sectionPage={this.state.sectionPage1}
            dataSection={this.state.dataSection1}
          />
        </div>
        <MDBInput
          className={this.state.classTestPhoneNumber}
          label="Customer's Phone Number"
          size="sm"
          value={this.state.testPhoneNumber}
          onChange={this.handleChange}
          name="testPhoneNumber"
          type="number"
        ></MDBInput>
        <div className="select-container">
          <MDBSelect
            className={this.state.classCountries}
            search
            multiple
            options={this.state.options}
            getTextContent={this.countriesSelect}
            label="Countries Allowed"
          />
        </div>
        <div className="button-save__form">
          <MDBBtn className="button-save" onClick={this.saveChanges}>
            {this.state.validating ? "loading" : "Save Changes"}
          </MDBBtn>
          <ModalEditConfiguration
            open={this.state.saveData}
            closeModal={this.closeModal}
            saveChanges={this.props.saveChanges}
            sectionPage={this.state.sectionPage}
            dataSection={this.state.dataSection}
          />
        </div>
      </div>
    );
  }
}

export default PhoneNumberConfiguration;
