import React, { Fragment } from "react";
import { MDBIcon, MDBBtn, MDBContainer } from "mdbreact";

class Buttons_user_settings extends React.Component {
  delete = () => {
    this.props.delete(this.props.id_);
  };
  edit = () => {
    this.props.edit();
  };

  render() {
    return (
      <Fragment>
        <MDBContainer className="center-button">
          <MDBBtn
            tag="a"
            className="btn-square color_btn-red "
            color="danger"
            onClick={this.delete}
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn>
          <MDBBtn
            tag="a"
            className="btn-square color_btn-d "
            color="infoa"
            onClick={this.edit}
          >
            <MDBIcon icon="pencil-alt" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default Buttons_user_settings;
