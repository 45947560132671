import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBAlert,
} from "mdbreact";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import api from "../../helpers/api";
import crypto from "crypto";
class AddWebhook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: {
        show: false,
        msg: "",
      },
      save: false,
      bookingsSystems: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback",
        data: "",
      },
      user_name: "",
      bookingSystemOptions: [],
    };
  }

  async componentDidMount() {
    try {
      const [getBookingSystemWithWebhook, getBookingSystems] =
        await this.getDataFromDatabase(this.props.data_edit.id_usuario);

      const [
        validBookingSystemWithWebhook,
        validBookingSystems,
        validUserName,
      ] = await this.validateDataFromDatabase(
        getBookingSystemWithWebhook,
        getBookingSystems,
        this.props.data_edit.nombre_usuario
      );

      const [buildBookingSystemsAvailable, buildUserName] =
        await this.buildDataFromDatabase(
          validBookingSystemWithWebhook,
          validBookingSystems,
          validUserName
        );

      const saveData = await this.saveDataFromDatabase(
        buildBookingSystemsAvailable,
        buildUserName
      );
    } catch (error) {
      console.error(`Error in ${__filename} - componentDidMount`);
    }
  }

  getDataFromDatabase = (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!Number.isSafeInteger(userId)) {
          throw { message: "User ID must be an integer" };
        }

        const getBookingSystemsWithWebhookCreatedByUser = await api(
          "read_bookings_systems_adminEdit",
          "application/json",
          { userId }
        );

        let bookingSystemsWithWebhookCreated = [];
        getBookingSystemsWithWebhookCreatedByUser.data.data.map((val) => {
          bookingSystemsWithWebhookCreated.push(val.booking_system);
        });

        const getBookingSystems = await api(
          "getAllBookingSystemsAdmin",
          "application/json"
        );

        let bookingSystems = [];
        getBookingSystems.data.data.map((val) => {
          bookingSystems.push(val.system);
        });

        resolve([bookingSystemsWithWebhookCreated, bookingSystems]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `getDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  // <<<<<<< HEAD
  validateDataFromDatabase = async (
    bookingSystemWithWebhook,
    bookingSystems,
    userName
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (bookingSystemWithWebhook.length === 0) {
          throw { message: "No data to validate in bookingSystemWithWebhook" };
        }
        // =======
        //   async componentDidMount() {
        //
        // >>>>>>> sprint_29_2022

        if (bookingSystems.length === 0) {
          throw { message: "No data to validate in bookingSystems" };
        }

        bookingSystemWithWebhook.map((val) => {
          if (typeof val !== "string") {
            throw {
              message: `bookingSystemWithWebhook - Data isn't a string, received: ${val}`,
            };
          }
        });

        bookingSystems.map((val) => {
          if (typeof val !== "string") {
            throw {
              message: `bookingSystems - Data isn't a string, received: ${val}`,
            };
          }
        });

        if (typeof userName !== "string") {
          throw {
            message: `User name - Data isn't a string, received: ${userName}`,
          };
        }

        resolve([bookingSystemWithWebhook, bookingSystems, userName]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `validateDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  buildDataFromDatabase = async (
    bookingSystemWithWebhook,
    bookingSystems,
    userName
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const bookingSystemsSorted = bookingSystems.sort();
        let bookingsAvailable = [];

        bookingSystemsSorted.map((val) => {
          let bookingExist = true;
          bookingSystemWithWebhook.map((val1) => {
            if (val === val1) {
              bookingExist = false;
            }
          });
          if (bookingExist) {
            bookingsAvailable.push({
              checked: false,
              disabled: false,
              icon: null,
              value: val,
              text: val,
            });
          }
        });

        resolve([bookingsAvailable, userName]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `buildDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  saveDataFromDatabase = async (bookingSystemsAvailable, userName) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({
          user_name: userName,
          bookingSystemOptions: bookingSystemsAvailable,
        });
        resolve(true);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `saveDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();

    // const bookingsSistemSplit = this.state.bookingsSystems.data.split(", ");

    const a = await this.validateSelectBookingsSystems(
      this.state.bookingsSystems.data
    );

    if (a) {
      const bookingsSistemSplit = this.state.bookingsSystems.data.split(", ");
      const data = {
        bookingsSystems: bookingsSistemSplit,
        userId: this.props.data_edit.id_usuario,
      };

      this.send_api(data);
    }
  };

  validateSelectBookingsSystems = (data) => {
    return new Promise((resolve, reject) => {
      if (data === "" || data === undefined || data === null) {
        this.setState({
          bookingsSystems: {
            ...this.state.bookingsSystems,
            class_novalid: "novalidate_select",
            class_valid: "valid-feedback",
          },
        });
        resolve(false);
      } else {
        this.setState({
          bookingsSystems: {
            ...this.state.bookingsSystems,
            class_novalid: "invalid-feedback",
            class_valid: "valid-feedback",
          },
        });
        resolve(true);
      }
    });
  };

  send_api = async (data) => {
    this.setState({
      save: true,
      saving: true,
    });
    try {
      const res = await api("add_webhook_adminPage", "application/json", data);

      if (res.data.status === "ok") {
        this.props.refresh_();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("error-query", e);
    }
  };

  setSelectBookingSystem = (e) => {
    if (e !== "" && e !== undefined && e !== null) {
      this.setState({
        bookingsSystems: {
          ...this.state.bookingsSystems,
          data: e,
        },
      });
    }
  };

  render() {
    return (
      <form
        // className="needs-validation"
        onSubmit={this.submitHandler}
        noValidate
        className="text-left"
      >
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}

        <div className="md-form">
          <MDBInput
            value={this.state.user_name}
            type="text"
            name="userName"
            label="User Name"
            disabled
          />
        </div>

        <div className="md-form scrollbar_booking-systems">
          <MDBSelect
            multiple
            name="system"
            options={this.state.bookingSystemOptions}
            label="Booking System"
            getTextContent={(e) => this.setSelectBookingSystem(e)}
            color="primary"
          ></MDBSelect>

          <div className={this.state.bookingsSystems.class_novalid}>
            select booking system.
          </div>
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default AddWebhook;
