export default [
  {
    label: "Title",
    field: "title",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Intent",
    field: "intent",
    sort: "asc",
    minimal: "size-col-15",
  },
  {
    label: "Followup",
    field: "followup",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Message",
    field: "msg",
    sort: "asc",
    minimal: "size-col-50",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-20 ",
  },
];
