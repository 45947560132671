import React from "react";

import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdbreact";

// import AdminCardSection from "../../06-userSettings/dashboard/AdminCardSection";
// import ChartSection from "../../06-userSettings/dashboard/ChartSection";
import { withRouter } from "react-router-dom";
import InternalReviewTable from "../01-Internal_review/00-internal_review_table";
import TransactionsReceived from "../02-Transaction_received/00-transactions_received";
import SmsUsed from "../03-Sms_used/00-sms_used";
import moment from "moment";

class DashboardData extends React.Component {
  // state = {
  //   startDate: "",
  //   endDate: "",
  //   // validationDate: true,
  // };
  async componentDidMount() {
    // let validateDate = true;
    // const startDate = moment(
    //   this.props.match.params.start,
    //   "YYYY-MM-DD",
    //   true
    // ).format("YYYY-MM-DD");
    // const endDate = moment(
    //   this.props.match.params.end,
    //   "YYYY-MM-DD",
    //   true
    // ).format("YYYY-MM-DD");
    // const endDate = moment()
    //   .subtract(1, "days")
    //   .startOf("day")
    //   .format("YYYY-MM-DD");
    // const startDate = moment(endDate).subtract(30, "days").format("YYYY-MM-DD");
    // if (startDate === "Invalid date") {
    //   validateDate = false;
    //   await this.setState({
    //     validationDate: false,
    //   });
    // }
    // if (endDate === "Invalid date") {
    //   validateDate = false;
    //   await this.setState({
    //     validationDate: false,
    //   });
    // }
    // if (validateDate) {
    //   await this.setState({
    //     startDate: moment(startDate).format("YYYY-MM-DD"),
    //     endDate: moment(endDate).add(1, "day").format("YYYY-MM-DD"),
    //     validateDate: true,
    //   });
    // }
    // await this.setState({
    //   startDate,
    //   endDate,
    // });
  }
  render() {
    return (
      <MDBCard className="text-left">
        <MDBCardHeader className="white-text color_select p-4 text-left">
          <MDBIcon far icon="chart-bar" /> Dashboard
        </MDBCardHeader>

        <MDBCardBody>
          {/* {this.state.validationDate &&
          this.state.startDate !== "" &&
          this.state.endDate !== "" ? ( */}
          <MDBRow>
            <MDBCol md="6">
              {/* <MDBRow>
            <MDBCol size="12">
              <AdminCardSection />
            </MDBCol>
          </MDBRow>
          <br /> */}
              <MDBRow>
                <MDBCol size="12">
                  <InternalReviewTable
                  // startDate={this.state.startDate}
                  // endDate={this.state.endDate}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow>
                <MDBCol size="12">
                  <SmsUsed
                  // startDate={this.state.startDate}
                  // endDate={this.state.endDate}
                  />
                </MDBCol>
              </MDBRow>
              {/* <br />
          <MDBRow>
            <MDBCol size="12">
              <TransactionsReceived/>
            </MDBCol>
          </MDBRow> */}
            </MDBCol>
            <MDBCol md="6" className="mb-4">
              {/* <ChartSection /> */}
              <MDBRow>
                <MDBCol size="12">
                  <TransactionsReceived
                  // startDate={this.state.startDate}
                  // endDate={this.state.endDate}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          {/* ) : (
             <MDBRow>
               <MDBCol>
                 <MDBCardHeader className="white-text text-left color_select_error">
                   Incorrect dates
                 </MDBCardHeader>
               </MDBCol>
             </MDBRow>
          )}*/}
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default withRouter(DashboardData);
