import React, { Component } from "react";
import { MDBAlert, MDBBtn, MDBInput } from "mdbreact";

import api from "../../helpers/api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PhoneNumberConfiguration from "../14-usersAdmin/01-usersEdit/01-usersEditConfiguration/04-phoneNumberConfiguration";

class SendTest extends Component {
  state = {
    options: [],
    sendData: false,
    phone: "",
    codeCountry: "us",
    invalidNumber: false,
    errorMsg: "",
  };

  sendTestMessage = async (event) => {
    event.preventDefault();
    this.setState({
      invalidNumber: false,
      sendData: true,
    });
    try {
      let message = this.props.message.replace(/<[^>]*>?/g, "");
      message = message.replace(/['"]+/g, "");
      message = message.replace(/&.*;/g, "");
      message = message.replace(/\\r/g, "");
      message = message.replace(/\\n/g, " ");
      message = message.trim();

      const data_ = {
        message,
        from: this.props.data.numero_wp,
        to: this.state.phone,
        user: this.props.data.id_usuario,
        command: this.props.command,
      };

      const a = await api("save_sms_message", "application/json", data_);

      if (a.data.status === "error") {
        throw {
          msg: a.data.msg,
        };
      }

      this.props.refresh_();
    } catch (e) {
      this.setState({
        invalidNumber: true,
        sendData: false,
        errorMsg: e.msg,
      });
    }
  };

  changeNumber = (phoneNumber) => {
    this.setState({ phone: phoneNumber.phone });
  };

  render() {
    return (
      <form onSubmit={this.sendTestMessage} noValidate className="text-center">
        <div className="body-message__send-test">
          <p
            className="font-italic text-sm-center"
            style={{ fontSize: "small" }}
          >
            IMPORTANT: There's no booking associated to this message so
            variables will not be replaced and there will be no context for the
            chatbot to respond to a question
          </p>
        </div>
        <div className="container-phonenumber_test">
          <PhoneInput
            country={"us"}
            value={this.state.phone}
            onChange={(phone) => this.changeNumber({ phone })}
            onlyCountries={["us"]}
          />
          {this.state.invalidNumber ? (
            <div className="invalid-phone-number">{this.state.errorMsg}</div>
          ) : (
            ""
          )}
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.sendData ? (
              <div className="d-flex flex-row">
                <div className="p-1">Send</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Send</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default SendTest;
