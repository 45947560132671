import React, { Fragment } from "react";
import {
  MDBBtn,
  MDBAlert,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import imageCompression from "browser-image-compression";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import path from "path";
import api from "../../helpers/api";
import isgsm7 from "isgsm7";

//import data from "./datos";

class Edit_chatbot_msg extends React.Component {
  constructor(props) {
    super(props);
    const html = JSON.parse(this.props.data_edit.message);
    const contentBlock = htmlToDraft(html);
    // search in array select time value
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      const t = convertToRaw(editorState.getCurrentContent()).blocks;
      let counterCharactersSMS = 0;
      let counter1 = 0;
      let maxSMS = 153;
      t.map((e) => {
        // console.log("text data",e.text);
        if (!isgsm7(e.text)) {
          maxSMS = 67;
        }
        if (counter1 > 0) {
          counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
        } else {
          counterCharactersSMS = counterCharactersSMS + e.text.length;
        }
        counter1++;
      });
      // console.log("total",counterCharactersSMS);
      const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
      let characters = counterCharactersSMS + "/" + totalSMS;
      // console.log("new options_props", this.props.new_options_);
      this.state = {
        editorState,
        counterSMS: characters,
        idbb_chatbot_msg_template: this.props.id_,
        file: undefined,
        start_compresion: false,
        progress_compresion: 0,
        ext: "",
        html_msg: "",
        valid_html_msg: true,
        incompatible: false,
        max_size_file: "3000000",
        max_size: false,
        alert: {
          show: false,
          msg: "",
        },
        save: false,
        saving: false,
        options: this.props.new_options_,
        title: "",
        number_time: this.props.data_edit.qty,
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.props.data_edit.time,
        },
        open_modal_file: false,
        file_name: "",
        attached: this.props.data_edit.attached,
        path: this.props.data_edit.path,
        modal: false,
      };
    }
  }

  componentDidMount() {
    if (this.props.data_edit.filename === "false") {
      this.setState({
        file_name: "Upload your file",
      });
    } else {
      this.setState({
        file_name: this.props.data_edit.filename,
      });
    }
  }

  selec_type = ["time"];

  toggle = () => {
    // console.log("click toggle");
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    if (this.state.saving === false) {
      // console.log("validated-no sending");
      const msg_html = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const ht = msg_html.trim();
      // console.log("validation_html:", ht);
      if (ht === "<p></p>") {
        this.setState({
          valid_html_msg: false,
        });
      }
      this.setState({
        html_msg: msg_html,
        attached: "true",
      });
      // console.log("state false", ht);
      var b = await this.input_fields(ht);

      if (b) {
        // console.log("all validated-send api");
        const datos = {
          file: this.state.file,
          message: msg_html,
        };
        this.send_api(datos);
      }
    } else {
      // console.log("can't validated-sending");
    }
  };

  input_fields = (data) => {
    const ht = data.trim();
    // console.log("check field", ht.length, ht);
    return new Promise((resolve, reject) => {
      if (ht !== "<p></p>") {
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      }
    });
  };

  select_box = () => {
    // console.log("before promise");
    return new Promise((resolve, reject) => {
      // console.log("run");
      var b = false;
      this.selec_type.map((valor, index) => {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok");
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach");
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
          if (index === 0) {
            // console.log("select_box validated");
            resolve(true);
          }
        }
      });
    });
  };

  send_api = async (data) => {
    // console.log("send api:", data);
    this.setState({
      save: true,
      saving: true,
    });
    const formData = new FormData();
    // console.log(
    //   "idbb_chatbot_msg_template",
    //   this.state.idbb_chatbot_msg_template
    // );
    formData.append(
      "idbb_chatbot_msg_template",
      this.state.idbb_chatbot_msg_template
    );
    formData.append("file", data.file);
    formData.append("message", data.message);
    formData.append("ext", this.state.ext);

    try {
      const res = await api("edit_bot_msg", "multipart/form-data", formData);
      // console.log("response:", res.data);
      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        // this.props.changeStateModal();
        this.props.refresh_();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("query error", e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  progress = (a) => {
    // console.log("progress", a);
    this.setState({
      progress_compresion: a,
    });
    if (a === 100) {
      this.setState({
        progress_compresion: 0,
        start_compresion: false,
      });
      // console.log("finished compress");
    }
  };

  fileInputHandler = async (e) => {
    // console.log(e);

    if (e[0] !== undefined) {
      if (e[0].size <= this.state.max_size_file) {
        const ext = path.extname(e[0].name);
        if (
          ext === ".jpg" ||
          ext === ".jpeg" ||
          ext === ".png" ||
          ext === ".gif" ||
          ext === ".mp4" ||
          ext === ".pdf"
        ) {
          // console.log(e[0]);
          if (e[0].type === "image/png") {
            if (ext !== ".png") {
              this.setState({
                start_compresion: true,
              });
              const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                onProgress: (a) => this.progress(a),
              };
              const compress_file_a = await imageCompression(e[0], options);

              this.setState({
                file: compress_file_a,
                ext: "jpg",
              });
            } else {
              //if file is .png for a postcard
              this.setState({
                file: e[0],
              });
            }
          } else {
            this.setState({
              file: e[0],
            });
          }
          this.setState({
            incompatible: false,
            attached: "false",
          });
        } else {
          // console.log("incompatible file");
          this.setState({
            incompatible: true,
          });
        }
        this.setState({
          max_size: false,
        });
      } else {
        /* file max exceed*/
        // console.log("max size file");
        this.setState({
          max_size: true,
        });
      }
    }
  };

  onEditorStateChange = (editorState) => {
    const t = convertToRaw(editorState.getCurrentContent()).blocks;
    let counterCharactersSMS = 0;
    let counter1 = 0;
    let maxSMS = 153;
    t.map((e) => {
      // console.log("text data",e.text);
      if (!isgsm7(e.text)) {
        maxSMS = 67;
      }
      if (counter1 > 0) {
        counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
      } else {
        counterCharactersSMS = counterCharactersSMS + e.text.length;
      }
      counter1++;
    });
    // console.log("total",counterCharactersSMS);
    // const letras = msg_html.length;
    const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
    let characters = counterCharactersSMS + "/" + totalSMS;
    this.setState({
      editorState,
      counterSMS: characters,
    });
    // console.log(msg_html);
  };

  modal_file = (e) => {
    if (e === "open") {
      this.setState({
        open_modal_file: true,
      });
      // console.log(this.state.path);
    }
    if (e === "close") {
      this.setState({
        open_modal_file: false,
      });
    }
  };

  render() {
    const { editorState } = this.state;

    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.open_modal_file}
          toggle={() => this.modal_file("close")}
          position="right"
        >
          <MDBModalHeader toggle={() => this.modal_file("close")}>
            Uploaded file
          </MDBModalHeader>

          <MDBModalBody></MDBModalBody>
        </MDBModal>

        <form onSubmit={this.submitHandler} noValidate className="text-left">
          {this.state.alert.show ? (
            <MDBAlert color="danger" dismiss>
              {this.state.alert.msg}
            </MDBAlert>
          ) : (
            ""
          )}

          <div className="md-form text-center">
            <h6>Intent:{this.props.data_edit.title}</h6>

            <h6>
              Intent:{this.props.data_edit.intent} / Followup:
              {this.props.data_edit.followup}
            </h6>
          </div>

          <div className="md-form">
            <Editor
              handlePastedText={() => false}
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                inline: {
                  options: ["bold", "italic", "underline", "monospace"],
                },
                list: {
                  options: [],
                },
                textAlign: { options: [] },
                link: { options: [] },
                blockType: {
                  inDropdown: true,
                  options: [],
                  className: "ocultar",
                },
                fontSize: {
                  options: [],
                  className: "ocultar",
                },
                fontFamily: {
                  className: "ocultar",
                },
                embedded: {
                  className: "ocultar",
                },
                image: {
                  className: "ocultar",
                },
                remove: {
                  className: "ocultar",
                },
                colorPicker: {
                  popupClassName: "popup",
                  className: "ocultar",
                  colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
                },
              }}
            />
            <p className="text-right">{this.state.counterSMS}</p>
            {this.state.valid_html_msg ? (
              ""
            ) : (
              <div className="novalidate_html">Insert a body message</div>
            )}
          </div>

          <div className="text-center">
            <MDBBtn color="deep-orange" type="submit" className="btn_save">
              {this.state.save ? (
                <div className="d-flex flex-row">
                  <div className="p-1">Edit</div>
                  <div className="p-1">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-1">Save</div>
              )}
            </MDBBtn>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default Edit_chatbot_msg;
