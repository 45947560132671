import React from "react";
import { MDBContainer, MDBSwitch, ToastContainer, toast } from "mdbreact";
import api from "../../helpers/api";

class ButtonsSMSService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
    };
  }
  componentDidMount() {
    if (this.props.state === "inactivo") {
      this.setState({
        switch: false,
      });
    } else {
      this.setState({
        switch: true,
      });
    }
  }
  handleSwitchChange = async () => {
    let data = "";
    const switchState = !this.state.switch;
    if (switchState) {
      data = {
        state: "activo",
      };
    } else {
      data = {
        state: "inactivo",
      };
    }
    try {
      const res = await api(
        "update_user_sms_service",
        "application/json",
        data
      );
      // console.log("back---dg",res.data.data)
      if (res.data.data === "save") {
        this.setState({
          switch: !this.state.switch,
        });
      }
    } catch (e) {
      toast.error("Oops, an error ocurred", {
        closeButton: true,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBContainer>
          <MDBSwitch
            className="switch-color"
            checked={this.state.switch}
            onChange={this.handleSwitchChange}
          />
        </MDBContainer>
      </React.Fragment>
    );
  }
}

export default ButtonsSMSService;
