import React from "react";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBCardHeader,
  toast,
  ToastContainer,
  MDBSpinner,
  MDBDatePicker,
  MDBSelect,
} from "mdbreact";

import MDBDataTable from "../../source-pack/DataTable/DataTable";
import Config_table from "./01-Config-Table";
import api from "../../helpers/api";
import moment from "moment";
import ButtonsStatusSMS from "./02-Buttons-statusSMS";
import isgsm7 from "isgsm7";

class InformationSMS extends React.Component {
  state = {
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    data: "",
    data_1: "",
    load_data: true,
    options: [],
    initial_date: moment().format("YYYY-MM-DD"),
    finishing_date: moment().add(1, "days").format("YYYY-MM-DD"),
    count_sms: "",
    type: "",
    limitData: "1",
    stateButtonMore: false,
    initialDateQuery: "",
    finishingDateQuery: "",
    initialDataQuery: 0,
    loadDataButton: false,
    limitOptions: "",
  };

  toggle = () => {
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });

    toast.success("Automatic msg saved successfully", {
      closeButton: true,
    });
    // call to api for all data message created
    this.load_data();
  };

  componentDidMount() {
    this.load_data();
    const query = {
      initial_date: moment(this.state.initial_date)
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      finishing_date: moment(this.state.finishing_date).format("YYYY-MM-DD"),
      limitDataQuery: this.state.limitData,
      initialDataQuery: this.state.initialDataQuery,
    };
    this.setState({
      initialDateQuery: query.initial_date,
      finishingDateQuery: query.finishing_date,
      load_data: false,
    });
    this.load_data_table(query);
  }

  load_data = async () => {
    try {
      var a = [];
      let b = [];
      a.push({
        checked: true,
        disabled: false,
        icon: null,
        value: "1",
        text: "all",
      });
      a.push({
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "sent",
      });
      a.push({
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "received",
      });

      b.push({
        checked: true,
        disabled: false,
        icon: null,
        value: "1",
        text: "0-50",
      });
      b.push({
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "0-100",
      });
      b.push({
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "All",
      });

      this.setState({
        options: a,
        limitOptions: b,
      });
    } catch (e) {}
  };

  load_data_table = async (query) => {
    try {
      /////
      const res = await api("read_sms_message", "application/json", query);

      var a_1 = [];
      let countData = 1;
      var cuenta = 0;

      if (this.state.stateButtonMore) {
        a_1 = this.state.data_1.rows;
      }

      if (res.data.data.length > 200) {
        this.setState({
          stateButtonMore: true,
        });
      } else {
        this.setState({
          stateButtonMore: false,
        });
      }

      if (this.state.loadDataButton) {
        cuenta = this.state.count_segments;
      }

      res.data.data.map((val) => {
        if (countData > 200) {
          return;
        }

        // const jsonSMS = JSON.parse(val.json);
        // let segments = "";
        // if (jsonSMS.numSegments) {
        //   segments = jsonSMS.numSegments;
        //   cuenta += parseInt(segments);
        // } else {
        //   segments = jsonSMS.NumSegments;
        //   cuenta += parseInt(segments);
        // }

        let msg = val.body.replace(/^"(.+(?="$))"$/, "$1");
        msg = msg.replace(/\\n/g, " ");
        msg = msg.trim();

        let maxSms = 153;

        if (!isgsm7(msg)) {
          maxSms = 67;
        }

        const segments = Math.ceil(msg.length / maxSms);

        cuenta = cuenta + segments;

        a_1.push({
          type: val.type,
          segments,
          body: val.body,
          state: val.status,
          command: val.command,
          data_update: val.data_update,
          date_created: val.date_created,
          buttons: <ButtonsStatusSMS data={val} segments={segments} />,
        });
        countData++;
      });
      if (this.state.loadDataButton) {
        this.setState({
          loadDataButton: false,
        });
      }

      this.setState({
        data_1: {
          columns: Config_table,
          rows: a_1,
        },
        load_data: true,
        count_sms: a_1.length,
        count_segments: cuenta,
        load_data: true,
      });
    } catch (e) {}
  };

  search = async () => {
    const query_data = {
      initial_date: moment(this.state.initial_date).format("YYYY-MM-DD"),
      finishing_date: moment(this.state.finishing_date)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      type: this.state.type,
      limitDataQuery: this.state.limitData,
      initialDataQuery: 0,
    };
    this.setState({
      initialDateQuery: query_data.initial_date,
      finishingDateQuery: query_data.finishing_date,
      initialDataQuery: 0,
      stateButtonMore: false,
      load_data: false,
      loadDataButton: false,
    });
    this.load_data_table(query_data);
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSelect = (data) => {
    if (data === "sent") {
      this.setState({
        type: "out",
      });
    }
    if (data === "received") {
      this.setState({
        type: "in",
      });
    }
    if (data === "" || data === "all") {
      this.setState({
        type: "all",
      });
    }
  };

  setSelectLimit = (data) => {
    if (data === "0-50") {
      this.setState({
        limitData: "1",
        stateButtonMore: false,
      });
    }
    if (data === "0-100") {
      this.setState({
        limitData: "2",
        stateButtonMore: false,
      });
    }
    if (data === "" || data === "All") {
      this.setState({
        limitData: "3",
      });
    }
  };

  moreData = (e) => {
    e.preventDefault();
    let initialDataQuery = this.state.initialDataQuery + 200;
    this.setState({
      loadDataButton: true,
      initialDataQuery,
    });
    const query = {
      initial_date: this.state.initialDateQuery,
      finishing_date: this.state.finishingDateQuery,
      type: this.state.type,
      limitDataQuery: this.state.limitData,
      initialDataQuery: initialDataQuery,
    };
    this.load_data_table(query);
  };

  render() {
    return (
      <MDBRow>
        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon far icon="envelope" /> All SMS
            </MDBCardHeader>

            <MDBCardBody>
              <MDBRow className="espace">
                <MDBCol md="3">
                  <div className="md-form">
                    <MDBDatePicker
                      getValue={(val) => this.setState({ initial_date: val })}
                      format="DD-MM-YYYY"
                    />
                  </div>
                </MDBCol>

                <MDBCol md="3">
                  <div className="md-form">
                    <MDBDatePicker
                      value={this.state.finishing_date}
                      getValue={(val) => this.setState({ finishing_date: val })}
                      format="DD-MM-YYYY"
                    />
                  </div>
                </MDBCol>

                <MDBCol md="2">
                  <div className="md-form">
                    <MDBSelect
                      options={this.state.options}
                      color="primary"
                      label="Type"
                      name="type"
                      className="text_1"
                      getTextContent={this.setSelect.bind(this)}
                    />
                  </div>
                </MDBCol>

                <MDBCol md="2">
                  <div className="md-form">
                    <MDBSelect
                      options={this.state.limitOptions}
                      color="primary"
                      label="Limit"
                      name="limit"
                      className="text_1"
                      getTextContent={this.setSelectLimit.bind(this)}
                    />
                  </div>
                </MDBCol>

                <MDBBtn color="deep-orange" onClick={this.search}>
                  Search
                </MDBBtn>
              </MDBRow>
            </MDBCardBody>

            {this.state.load_data ? (
              <MDBCol lg="12">
                <MDBCard className="text-left">
                  <MDBCardHeader className="white-text color_select p-4 text-left">
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon far icon="envelope" /> Result All SMS:{" "}
                        {this.state.count_sms} / All segments:{" "}
                        {this.state.count_segments}
                      </MDBCol>
                      <MDBCol className="text-right">
                        {this.state.stateButtonMore ? (
                          <a
                            href=""
                            onClick={(e) => this.moreData(e)}
                            className="view-more mr-3 my-2"
                          >
                            {this.state.loadDataButton ? (
                              <MDBSpinner red />
                            ) : (
                              <span>View more</span>
                            )}
                          </a>
                        ) : (
                          ""
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCardHeader>

                  <MDBCardBody>
                    <MDBDataTable
                      striped
                      bordered
                      small
                      paging={false}
                      btn
                      maxHeight="300px"
                      reponsiveSm
                      className="table_warp text-center"
                      data={this.state.data_1}
                      custom={"table_warp"}
                      fixed
                    ></MDBDataTable>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ) : (
              <MDBRow center style={{ height: "80vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            )}
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default InformationSMS;
