import React from "react";

import { MDBSelect } from "mdbreact";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import isgsm7 from "isgsm7";

import api from "../../helpers/api";

class EditBroadcastMessage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.data.msg)
    // const html = JSON.parse(this.props.data.msg);

    const contentBlock = htmlToDraft(this.props.data.msg);

    console.log("content-->", contentBlock);
    // search in array select time value
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      console.log("??????????????",contentState);
      const editorState = EditorState.createWithContent(contentState);
      const t = convertToRaw(editorState.getCurrentContent()).blocks;
      let counterCharactersSMS = 0;
      let counter1 = 0;
      let maxSMS = 153;
      t.map((e) => {
        // console.log("edit text data",e.text);
        if (!isgsm7(e.text)) {
          maxSMS = 67;
        }
        if (counter1 > 0) {
          counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
        } else {
          counterCharactersSMS = counterCharactersSMS + e.text.length;
        }
        counter1++;
      });
      // console.log("total",counterCharactersSMS);
      const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
      let characters = counterCharactersSMS + "/" + totalSMS;
      // console.log("??????????????", characters);
      // console.log("new options_props", this.props.new_options_);

      this.state = {
        editorState,
        counterSMS: characters,
        saveBroadcastDate: "",
        saveBroadcastPhone: "",

        broadcastCustomer: [],
        broadcastDate: [],
      };
    }
  }

  // state = {
  //   // editorState,
  //   // counterSMS: characters,
  //   saveBroadcastDate: "",
  //   saveBroadcastPhone: "",

  //   broadcastCustomer: [],
  //   broadcastDate: [],
  // };

  componentDidMount() {
    // console.log("props", this.props.data);
    this.loadData();
  }

  loadData = async () => {
    try {
      const dataProduct = {
        product: this.props.data.product,
        date: this.props.data.date_broadcast_message,
      };
      const editDataProduct = await api(
        "edit_read_date_product",
        "application/json",
        dataProduct
      );
      console.log("----->", editDataProduct);

      let allDateProducts = [];

      editDataProduct.data.data.map((val) => {
        allDateProducts.push(val.hora_humana);
      });

      let uniqueItems = Array.from(new Set(allDateProducts));

      let broadcastDate = [];

      uniqueItems.map((val, index) => {
        if (val === this.props.data.date_broadcast_message) {
          broadcastDate.push({
            text: `${val}`,
            value: `${index + 1}`,
            checked: true,
          });
        } else {
          broadcastDate.push({
            text: `${val}`,
            value: `${index + 1}`,
          });
        }
      });

      console.log("broadcastDate--->", broadcastDate);

      let broadcastPhone = [];

      const phoneSelected = this.props.data.phone_numbers.split(",");

      editDataProduct.data.data1.map((val, index) => {
        let equal = false;
        phoneSelected.map((val1) => {
          if (val.telefono_cliente === val1) {
            equal = true;
          }
        });
        if (equal) {
          broadcastPhone.push({
            text: `${val.telefono_cliente}`,
            value: `${index + 1}`,
            checked: true,
          });
        } else {
          broadcastPhone.push({
            text: `${val.telefono_cliente}`,
            value: `${index + 1}`,
          });
        }
      });

      await this.setState({
        saveBroadcastDate: this.props.data.date_broadcast_message,
        saveBroadcastPhone: this.props.data.phone_numbers,

        broadcastCustomer: broadcastPhone,
        broadcastDate: broadcastDate,
      });

      // this.setState({
      //   queryDateBookings: resDateBookings.data.data,
      //   broadcastDate,
      // });
    } catch (e) {
      console.log(e);
    }
  };

  verifyData = () => {};

  onEditorStateChange = (editorState) => {
    const t = convertToRaw(editorState.getCurrentContent()).blocks;
    let counterCharactersSMS = 0;
    let counterSalto = 0;
    let maxSMS = 153;
    t.map((e) => {
      // console.log("text data",e.text);
      if (!isgsm7(e.text)) {
        maxSMS = 67;
      }
      if (counterSalto > 0) {
        counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
      } else {
        counterCharactersSMS = counterCharactersSMS + e.text.length;
      }
      counterSalto++;
    });
    // console.log("total",counterCharactersSMS);
    const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
    let characters = counterCharactersSMS + "/" + totalSMS;
    this.setState({
      editorState,
      counterSMS: characters,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <form className="text-left" noValidate onSubmit={this.verifyData}>
        {/* <div className="md-form">
                <MDBInput
                  label="Title"
                  type="text"
                  value={this.props.data.title}
                  disable
                  name="broadcastTitle"
                  required
                ></MDBInput>
              </div> */}

        {/* <MDBSelect
                search
                options={this.state.broadcastProducts}
                selected="Choose your option"
                label="Product"
                getTextContent={this.selectDataProduct}
                className="scroll_select"
                id="selectProduct"
              ></MDBSelect> */}
        <div className="text-center">
          <p>{this.props.data.title}</p>
          <p>{this.props.data.product}</p>
        </div>
        <MDBSelect
          // search
          options={this.state.broadcastDate}
          selected="Choose your option"
          label="Date"
          // getTextContent={this.selectDateBooking}
          className="scroll_select"
          name="selectDateBooking"
        ></MDBSelect>

        <MDBSelect
          selectAll
          multiple
          options={this.state.broadcastCustomer}
          // selected="Choose your option"
          label="Customer"
          // getTextContent={this.selectphoneNumber}
          className="scroll_select"
        ></MDBSelect>

        <div className="md-form">
          <Editor
            handlePastedText={() => false}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: {
                options: ["bold", "italic", "underline", "monospace"],
              },
              list: {
                options: [],
              },
              textAlign: { options: [] },
              link: { options: [] },
              blockType: {
                inDropdown: true,
                options: [],
                className: "ocultar",
              },
              fontSize: {
                options: [],
                className: "ocultar",
              },
              fontFamily: {
                className: "ocultar",
              },
              embedded: {
                className: "ocultar",
              },
              image: {
                className: "ocultar",
              },
              remove: {
                className: "ocultar",
              },
              colorPicker: {
                popupClassName: "popup",
                className: "ocultar",
                colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
              },
            }}
          />
          <p className="text-right">{this.state.contadorSMS}</p>

          {this.state.valid_html_msg ? (
            ""
          ) : (
            <div className="novalidate_html">Insert a body message</div>
          )}
        </div>

        <div className="text-center">
          <p
            className="font-italic text-sm-left edit-broadcast__p"
            style={{ fontSize: "x-small" }}
          >
            Character count is only for reference purposes and should only be
            used as a guide. If you include emojis or any other special
            characters in the text, the amount of text messages used against
            your quota may increase.
          </p>
        </div>
      </form>
    );
  }
}

export default EditBroadcastMessage;
