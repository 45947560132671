import React from "react";

import { MDBSwitch } from "mdbreact";

import api from "../../helpers/api";

import "./styleproducts.css";

class ActiveMessagesProducts extends React.Component {
  state = {
    switchStatus: true,
  };

  componentDidMount() {
    /* if (this.props.activeMessage === 1) {
      this.setState({
        switchStatus: true,
      });
    }
    if (this.props.activeMessage === 0) {
      this.setState({
        switchStatus: false,
      });
    } */
  }

  componentDidUpdate(prevProps, prevState) {
    /*     console.log({
      prevProps: prevProps,
      prevState: prevState,
    });

    console.log({
      props: this.props,
      state: this.state,
    }); */
  }

  switchChange = async () => {
    let switchStatus = !this.props.activeMessage;

    /* this.setState({
      switchStatus,
    }); */
    const data = {
      active: switchStatus,
      idusr_tours: this.props.idusr_tours,
    };
    try {
      const res = await api(
        "activate_message_products",
        "application/json",
        data
      );
      this.props.refresh();
    } catch (e) {
      /* this.setState({
        switchStatus: !switchStatus,
      }); */
      this.props.refresh();
      console.log("error", e);
    }
  };

  render() {
    // console.log("switch");
    return (
      <React.Fragment>
        <MDBSwitch
          title={
            this.props.activeMessage
            ? 'Deactive product'
            : 'Active product'
          }
          checked={this.props.activeMessage}
          onChange={this.switchChange}
        />
      </React.Fragment>
    );
  }
}

export default ActiveMessagesProducts;
