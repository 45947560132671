import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";

import Edit_form from "./00-forms/02-Edit_automated_msg";
import Edit_postal from "./00-forms/02-Edit_postal";
import Edit_map from "./00-forms/02-Edit_location";
import CopytoProducts from "./00-forms/03-copytoproducts";
import SendTest from "./00-forms/04-sendTest";
import api from "../helpers/api";

class Buttons_automated_msg extends React.Component {
  state = {
    id: "",
    modals: {
      form: false,
      header: {
        title: "Edit Automated Message",
        color: "color_select",
      },
      component: <Edit_form data_edit={this.props.data} />,
      centered: true,
    },
  };

  delete_data = async (a) => {
    const res = await api("delete_automated_msg", "application/json", {
      id: a,
    });

    // console.log("consult delete", res.data);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  modal_data = async (data, id) => {
    // console.log("data from click delete", data, id);
    if (data === "delete") {
      // console.log("state", this.state.modals.form);
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
          component: (
            <React.Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.modal_data("new-togle", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.delete_data(id)}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </React.Fragment>
          ),
          centered: false,
        },
      });
    }

    if (data === "new-togle") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: false,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "edit") {
      // console.log("state", this.state.modals.form);
      // console.log("data-rr", this.props.data);
      // console.log("msg", this.props.data);
      // console.log("id_automated--r", id);
      const time = ["Hour(s)", "Minute(s)", "Day(s)"];
      var new_options = [];
      let timeSet = "";
      if (this.props.data.time === "Days") {
        timeSet = "Day(s)";
      }
      if (this.props.data.time === "Hour") {
        timeSet = "Hour(s)";
      }
      if (this.props.data.time === "Minutes") {
        timeSet = "Minute(s)";
      }
      time.map((value, index) => {
        if (timeSet === value) {
          // console.log("found");
          new_options.push({
            checked: true,
            text: value,
            value: `${index + 1}`,
          });
        } else {
          new_options.push({
            checked: false,
            text: value,
            value: `${index + 1}`,
          });
        }
      });

      if (
        this.props.data.type === "message" &&
        this.props.data.command === "@postcard"
      ) {
        this.setState({
          id: id,
          modals: {
            form: true,
            header: {
              title: "Edit Automated message with postcard",
              color: "color_select",
            },
            component: (
              <Edit_postal
                data_edit={this.props.data}
                id_={id}
                refresh_={this.refresh_data.bind(this)}
                new_options_={new_options}
              />
            ),
            centered: true,
          },
        });
      }

      if (
        this.props.data.type === "message" &&
        this.props.data.command !== "@postcard"
      ) {
        this.setState({
          id: id,
          modals: {
            form: true,
            header: {
              title: "Edit Automated message",
              color: "color_select",
            },
            component: (
              <Edit_form
                data_edit={this.props.data}
                id_={id}
                refresh_={this.refresh_data.bind(this)}
                new_options_={new_options}
              />
            ),
            centered: true,
          },
        });
      }

      if (this.props.data.type === "location") {
        this.setState({
          id: id,
          modals: {
            form: true,
            header: {
              title: "Edit Automated message with location",
              color: "color_select",
            },
            component: (
              <Edit_map
                data_edit={this.props.data}
                id_={id}
                refresh_={this.refresh_data.bind(this)}
                new_options_={new_options}
              />
            ),
            centered: true,
          },
        });
      }
    }

    if (data === "copyto") {
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Copy to products",
            color: "color_select",
          },
          component: (
            <CopytoProducts id_={id} refresh_={this.refresh_data.bind(this)} />
          ),
          centered: true,
        },
      });
    }

    if (data === "send") {
      var data_num = "";
      try {
        const a = await api("userdata_user_info", "application/json");
        // data_num = a.data.data.user_info.numero_wp;
        // console.log("number in the query", data_num,a);
        this.setState({
          id: id,
          modals: {
            form: true,
            header: {
              title: "Send test",
              color: "color_select",
            },
            component: (
              <SendTest
                id_={id}
                data={a.data.data.user_info}
                message={this.props.data.message}
                command={this.props.data.command}
                refresh_={this.refresh_data.bind(this)}
              />
            ),
            centered: true,
          },
        });
      } catch (e) {
        // console.log("upload data error", e);
      }
    }
  };

  refresh_data = () => {
    // console.log("refresh data");
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  render() {
    return (
      <React.Fragment>
        <MDBModal
          isOpen={this.state.modals.form}
          toggle={() => this.modal_data("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            className={
              this.state.modals.header.color + " white-text text-center"
            }
            titleClass="w-100"
          >
            <h5 className="text-center">{this.state.modals.header.title}</h5>
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBContainer className="center-button">
          <MDBBtn
            title='Delete automated message'
            tag="a"
            className="btn-square color_btn-red "
            onClick={() => this.modal_data("delete", this.props.id_)}
            color="danger"
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            title='Edit automated message'
            tag="a"
            className="btn-square color_btn-c"
            onClick={() => this.modal_data("edit", this.props.id_)}
            color="infoa"
          >
            <MDBIcon icon="pencil-alt" className="size_icon" />
          </MDBBtn>

          <MDBDropdown className="options_">
            <MDBDropdownToggle
              title='More options'
              color="infoa"
              className="btn-square color_btn-yellow"
            >
              <MDBIcon icon="plus" className="size_icon" />
            </MDBDropdownToggle>

            <MDBDropdownMenu color="warning" basic>
              {/* <MDBDropdownItem href="#">
                Update all notifications
              </MDBDropdownItem> */}
              <MDBDropdownItem
                onClick={() => this.modal_data("copyto", this.props.id_)}
              >
                Copy to products
              </MDBDropdownItem>
              <MDBDropdownItem
                onClick={() => this.modal_data("send", this.props.id_)}
              >
                Send test
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

export default Buttons_automated_msg;
