export default [
  {
    label: "Booking system",
    field: "booking_system",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Url Webhook",
    field: "url_webhook",
    sort: "asc",
    minimal: "size-col-20",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-10 ",
  },
];
