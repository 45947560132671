import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBAlert,
} from "mdbreact";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import api from "../../helpers/api";
import crypto from "crypto";
class New_automated_msg extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: {
        show: false,
        msg: "",
      },
      save: false,
      saving: false,
      service: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback",
        data: "",
      },
      system: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback",
        data: "",
      },
      modal: false,
      user_name: "",
      password: crypto.randomBytes(5).toString("hex"),
      email: "suppliers@touropp.com",
      contact_name: "",
      allBookingSystems: [],
    };
  }

  async componentDidMount() {
    try {
      const getData = await this.getDataFromDatabase();
      const validData = await this.validDataFromDatabase(getData);
      const buildData = await this.buildDataFromDatabase(validData);
      const saveData = await this.saveDataFromDatabase(buildData);
    } catch (error) {
      console.error(error);
    }
  }

  getDataFromDatabase = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const getBookingSystems = await api(
          "getAllBookingSystemsAdmin",
          "application/json"
        );

        let bookingSystems = [];
        getBookingSystems.data.data.map((val) => {
          bookingSystems.push(val.system);
        });

        resolve(bookingSystems);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `getDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  validDataFromDatabase = async (dataToValidate) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (dataToValidate.length === 0) {
          throw { message: "No data to validate" };
        }

        dataToValidate.map((val) => {
          if (typeof val !== "string") {
            throw { message: `Data isn't a string, received: ${val}` };
          }
        });
        resolve(dataToValidate);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `validDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  buildDataFromDatabase = async (dataToBuild) => {
    return new Promise(async (resolve, reject) => {
      try {
        const bookingSystemsSorted = dataToBuild.sort();

        let buildData = [];
        bookingSystemsSorted.map((val) => {
          buildData.push({
            checked: false,
            disabled: false,
            icon: null,
            value: val,
            text: val,
          });
        });

        resolve(buildData);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `buildDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  saveDataFromDatabase = async (dataToSave) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({
          allBookingSystems: dataToSave,
        });
        resolve(true);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `saveDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  service = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "starter",
        text: "starter",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "pro",
        text: "pro",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "ultimate",
        text: "ultimate",
      },
    ],
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.saving === false) {
      event.target.className += " was-validated";

      var a = this.validate_selectBOX();
      var b = await this.input_fields();

      if (a && b) {
        const datos = {
          nombre_usuario: this.state.user_name,
          pass: this.state.password,
          email: this.state.email,
          system: this.state.system.data,
          service: this.state.service.data,
          contact_name: this.state.contact_name,
        };

        this.send_api(datos);
      }
    } else {
    }
  };

  input_fields = () => {
    if (
      this.state.user_name &&
      this.state.password &&
      this.state.email &&
      this.state.contact_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  validate_selectBOX = () => {
    var counter = 0;
    if (this.state.system.data === "") {
      this.setState({
        system: {
          class_novalid: "novalidate_select",
          class_valid: "valid-feedback",
          data: "",
        },
      });
    } else {
      this.setState({
        system: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.state.system.data,
        },
      });
      counter++;
    }
    if (this.state.service.data === "") {
      this.setState({
        service: {
          class_novalid: "novalidate_select",
          class_valid: "valid-feedback",
          data: "",
        },
      });
    } else {
      this.setState({
        service: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.state.service.data,
        },
      });
      counter++;
    }
    if (counter === 0) {
      return false;
    }
    if (counter === 2) {
      return true;
    }
  };

  send_api = async (data) => {
    this.setState({
      save: true,
      saving: true,
    });
    try {
      const res = await api("add_new_USER", "application/json", data);

      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        this.props.changeStateModal();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("error-query", e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSelect_system = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  setSelect_service = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  render() {
    const scrollContainerStyle = { maxHeight: "100px" };
    return (
      <form
        // className="needs-validation"
        onSubmit={this.submitHandler}
        noValidate
        className="text-left"
      >
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}

        <div className="md-form">
          <MDBInput
            value={this.state.user_name}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="user_name"
            label="User Name"
            required
          >
            <div className="invalid-feedback">Please insert a user name.</div>
          </MDBInput>
        </div>

        <div className="md-form">
          <MDBInput
            value={this.state.contact_name}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="contact_name"
            label="Contact Name"
            required
          >
            <div className="invalid-feedback">
              Please insert a contact name.
            </div>
          </MDBInput>
        </div>

        <div className="md-form">
          <MDBInput
            value={this.state.password}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="password"
            label="Password"
            required
          >
            <div className="invalid-feedback">Please insert a password.</div>
          </MDBInput>
        </div>

        <div className="md-form">
          <MDBInput
            value={this.state.email}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="email"
            label="Email"
            required
          >
            <div className="invalid-feedback">Please insert a email.</div>
          </MDBInput>
        </div>

        <MDBRow>
          <MDBCol md="6">
            {/* select Booking System */}
            <div
              className="md-form scrollbar_booking-systems"
              style={scrollContainerStyle}
            >
              <MDBSelect
                multiple
                name="system"
                // options={this.type.options}
                options={this.state.allBookingSystems}
                label="Booking System"
                getTextContent={this.setSelect_system.bind(this, "system")}
                color="primary"
                // className="scrollbar"
              ></MDBSelect>

              <div className={this.state.system.class_novalid}>
                select booking system.
              </div>
            </div>

            {/*end select booking System */}
          </MDBCol>

          <MDBCol md="6">
            {/* select Topic */}
            <div className="md-form">
              <MDBSelect
                name="service"
                options={this.service.options}
                label="Service"
                getTextContent={this.setSelect_service.bind(this, "service")}
                color="primary"
                // style={{maxMenuHeight:"100"}}
              ></MDBSelect>

              <div className={this.state.service.class_novalid}>
                Select service
              </div>
            </div>

            {/*end select Topic */}
          </MDBCol>
        </MDBRow>

        <div className="text-center">
          <MDBBtn
            color="deep-orange"
            title="XD"
            type="submit"
            className="btn_save"
          >
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default New_automated_msg;
