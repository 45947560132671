export default [
  {
    label: "Title",
    field: "title",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Type",
    field: "type",
    sort: "asc",
    minimal: "size-col-15",
  },
  {
    label: "Command",
    field: "command",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Moment",
    field: "moment",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Time",
    field: "time",
    sort: "asc",
    minimal: "size-col-15",
  },
  {
    label: "Message",
    field: "msg",
    sort: "asc",
    minimal: "size-col-50",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-20 ",
  },
];
