import React from "react";

import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

class ModalStatusSms extends React.Component {
  constructor(props) {
    super(props);
    const html = JSON.parse(this.props.data.body);

    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      // console.log("??????????????",contentState);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  render() {
    return (
      <MDBModal isOpen={this.props.isOpen} toggle={this.props.closeModal}>
        <MDBModalHeader toggle={this.props.closeModal}>
          Message Status
        </MDBModalHeader>

        <MDBModalBody>
          <form
            // className="needs-validation"
            onSubmit={this.submitHandler}
            noValidate
            className="text-left"
          >
            <div className="md-form text-center">
              <h6>Data Update: {this.props.data.data_update}</h6>
              <h6>
                Date Create:
                {this.props.data.date_created}
              </h6>
              <h6>
                Type: {this.props.data.type} / Segments: {this.props.segments}
              </h6>
              <h6>State: {this.props.data.status}</h6>
            </div>

            <div className="md-form">
              <Editor
                handlePastedText={() => false}
                editorState={this.state.editorState}
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  inline: {
                    options: ["bold", "italic", "underline", "monospace"],
                    className: "ocultar",
                  },
                  list: {
                    options: [],
                    className: "ocultar",
                  },
                  textAlign: { options: [], className: "ocultar" },
                  link: { options: [], className: "ocultar" },
                  blockType: {
                    inDropdown: true,
                    options: [],
                    className: "ocultar",
                  },
                  fontSize: {
                    options: [],
                    className: "ocultar",
                  },
                  fontFamily: {
                    className: "ocultar",
                  },
                  embedded: {
                    className: "ocultar",
                  },
                  image: {
                    className: "ocultar",
                  },
                  remove: {
                    className: "ocultar",
                  },
                  colorPicker: {
                    popupClassName: "popup",
                    className: "ocultar",
                    colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
                  },
                  emoji: {
                    className: "ocultar",
                  },
                  history: {
                    className: "ocultar",
                  },
                }}
              />
            </div>
          </form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default ModalStatusSms;
