const config = {
  // chatAppUrl: process.env.REACT_APP_CHAT_URL,
  chatAppUrl: "https://chatapp.touropp.com/",
  chat2AppUrl: "https://chatapp2.touropp.com/",
  backendApiUrl: process.env.REACT_APP_API_URL,
  // backendApiUrl: "http://localhost:16614/",

  appEnvironment: process.env.REACT_APP_API_ENV,
};

module.exports = { config };
