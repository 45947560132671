import React from "react";

import { Redirect } from "react-router-dom";

import { MDBCol } from "mdbreact";

import "../index.css";
import SideNavPageAdmin from "../components/nav_sideAdmin";
import TopNavigationAdmin from "../components/nav_topAdmin";
import Admin_component from "../components/10-Admin/00-Admin";
// import TopBar from "../components/12-BarComponent/Topbar";
// import TopBar from "../components/12-nabvarComponent/Navbar"

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: "",
      sideNavToggled: false,
      breakWidth: 1024,
    };
  }

  componentDidMount() {
    // console.log("data en el componente:", this.props.user_data);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    localStorage.removeItem("jwt");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  toggleSideNav = () => {
    // console.log("click en boton hamburguesa", this.state.sideNavToggled);
    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled,
      });
    }
  };
  render() {
    const dynamicLeftPadding = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0",
    };
    return (
      <React.Fragment>
        <SideNavPageAdmin triggerOpening={this.state.sideNavToggled} />

        <div className="flexible-content white-skin">
          <TopNavigationAdmin
            onSideNavToggleClick={this.toggleSideNav}
            user_name="ADMIN"
          />
          {/* <TopBar/> */}
          <main style={{ ...dynamicLeftPadding, margin: "5rem 0% 6rem" }}>
            {this.state.redirect ? <Redirect to="/logout" /> : ""}

            <MDBCol size="12" className="text-center">
              <Admin_component />
            </MDBCol>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminPage;
