export default [
  {
    label: "Parameters",
    field: "parameters",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Token",
    field: "token",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "System",
    field: "system",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-10 ",
  },
];
