import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

import ReactJson from "react-json-view";

class Buttons_products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modals: {
        form: false,
        header: {
          title: "",
          color: "color_select",
        },
        info: "",
        centered: true,
        alignment: "",
      },
    };
  }

  open_modal = (a, data) => {
    if (a === "ver_info") {
      // console.log("Modal",data,data.id_webhook);
      const b = "Booking information" + " " + data.id_webhook;
      var datos_json = data.json;
      var value_ = JSON.parse(datos_json);
      // console.log("0000000000000",datos_json);
      // console.log("0000001111111",value_);
      this.setState({
        modals: {
          form: true,
          header: {
            title: b,
            color: "color_select",
          },
          centered: true,
          alignment: "text-justify",
          info: (
            <ReactJson
              src={value_}
              collapseStringsAfterLength={30}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              indentWidth={1}
            />
          ),
        },
      });
    }

    if (a === "cerrar") {
      this.setState({
        modals: {
          form: false,
          header: {
            title: "",
            color: "color_select",
          },
          centered: true,
          info: "",
        },
      });
    }

    if (a === "delete") {
      const b = "Delete booking" + " " + data;
      this.setState({
        modals: {
          form: true,
          header: {
            title: b,
            color: "color_select",
          },
          centered: true,
          alignment: "text-center",
          info: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this booking?</h6>
                </MDBCol>

                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.open_modal("cerrar", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.open_modal("continue", "")}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
        },
      });
    }

    if (a === "continue") {
      const data_env = {
        id_booking: data,
      };
      // console.log("data sent",data_env)

      try {
        // console.log("data base response",res)
        this.setState({
          modals: {
            form: false,
            header: {
              title: "",
              color: "color_select",
            },
            centered: true,
            alignment: "",
            info: "",
          },
        });
      } catch (e) {
        // console.log("error-query", e);
      }
    }
  };

  actualizar_booking = async (data, id) => {
    console.log("update booking", data, id);
    const data_env = {
      id_booking: id,
    };
    // console.log("data sent",data_env)
    try {
      // console.log("data base response",res)
      this.setState({
        modals: {
          form: false,
          header: {
            title: "",
            color: "color_select",
          },
          centered: true,
          alignment: "",
          info: "",
        },
      });
    } catch (e) {
      // console.log("error-query", e);
    }
  };

  //   console.log("go to booking",data,id);

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.modals.form}
          toggle={() => this.open_modal("cerrar", "a")} //
          centered
        >
          <MDBModalHeader
            className={
              this.state.modals.header.color + " white-text text-center"
            }
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>

          <MDBModalBody className={this.state.modals.alignment}>
            {this.state.modals.info}
          </MDBModalBody>
        </MDBModal>

        <MDBContainer className="center-button">
          {/* <MDBBtn
            tag="a"
            className="btn-square color_btn-red "
            onClick={() => this.open_modal("delete", this.props.id_)}
            color="danger"
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn> */}

          <MDBBtn
            tag="a"
            className="btn-square color_btn-d"
            onClick={() =>
              this.actualizar_booking("actualizar", this.props.id_)
            }
            color="infoa"
          >
            <MDBIcon icon="redo-alt" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            tag="a"
            className="btn-square color_btn-d "
            onClick={() => this.open_modal("ver_info", this.props.data)}
            color="infoa"
          >
            <MDBIcon far icon="eye" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default Buttons_products;
