import React, { Fragment } from "react";

import { withRouter } from "react-router-dom";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
  MDBSpinner,
} from "mdbreact";

import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { stripHtml } from "string-strip-html";

import MDBDataTable from "../source-pack/DataTable/DataTable";
import Form_new_msg from "../components/form_new_automated_message";
import Butons_bar from "../components/butons_automated_msg";
import Config_table from "../components/config_table_automated_msg";
import api from "../helpers/api";

class Table_automate_msg extends React.Component {
  state = {
    redirect: false,
    modals: {
      form: false,
      header: {
        title: "   Create Automate Message",
        color: "color_select",
      },
      component: "aaa", //<Form_new_msg changeStateModal="aaa"/>,
      centered: true,
    },
    open: false,
    title: "",
    hours: 0,
    minutes: 5,
    action: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    time: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "00:05",
    },
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    load_data_table: true,
    data: "",
  };

  toggle = () => {
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });
    toast.success("Automatic msg saved successfully", {
      closeButton: true,
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
    });
    // call to api for all data message created
    this.load_data();
  };

  open_a = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  cargando = () => {
    this.setState({
      load_data_table: true,
    });
  };

  open_modal = (data) => () => {
    if (data === "new") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "   Create Automated Message",
            color: "color_select",
          },
          component: <Form_new_msg changeStateModal={this.toggle.bind(this)} />,
          centered: true,
        },
      });
    }

    if (data === "new-togle") {
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Create Automated Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "delete") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_btn-red",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>

                <MDBCol md="12">
                  <MDBBtn color="danger" className="color_select">
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" className="color_btn-red">
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = async () => {
    try {
      this.setState({
        load_data_table: false,
      });
      const res = await api("all_automated_msg", "application/json");
      var a = [];
      let varTemp = 1;
      var textTable = "";
      res.data.data.map(async (val) => {
        let textTable = val.message.replace(/<[^>]*>?/g, "");
        textTable = textTable.replace(/['"]+/g, "");
        textTable = textTable.replace(/&.*;/g, "");
        textTable = textTable.replace(/\\n/g, " ");

        var remove_msg = val.message.substring(1);
        remove_msg = remove_msg.slice(0, -3);

        let timeOption = "";
        if (parseInt(val.qty) > 1 && val.time === "Days") {
          timeOption = "Days";
        }
        if (parseInt(val.qty) > 1 && val.time === "Hour") {
          timeOption = "Hours";
        }
        if (parseInt(val.qty) > 1 && val.time === "Minutes") {
          timeOption = "Minutes";
        }
        if (parseInt(val.qty) === 1 && val.time === "Days") {
          timeOption = "Day";
        }
        if (parseInt(val.qty) === 1 && val.time === "Hour") {
          timeOption = "Hour";
        }
        if (parseInt(val.qty) === 1 && val.time === "Minutes") {
          timeOption = "Minute";
        }
        a.push({
          title: val.title,
          moment: val.moment,
          time: val.qty + " " + timeOption,
          type: val.type,
          command: val.command,
          msg: textTable,
          buttons: (
            <Butons_bar
              id_={val.id_automated}
              refresh={this.load_data.bind(this)}
              data={val}
              msg={remove_msg}
            />
          ),
        });
      });

      this.setState({
        data: {
          columns: Config_table,
          rows: a,
        },
        load_data_table: true,
      });
    } catch (e) {}
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    this.selec_type.map((valor) => {
      if (valor !== "time") {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
        }
      } else {
      }
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon far icon="bell" /> Automated Message
            </MDBCardHeader>

            <MDBCardBody>
              <MDBBtn color="secondary" onClick={this.open_modal("new")}>
                New Automated Message
              </MDBBtn>
              {this.state.load_data_table ? (
                <MDBDataTable
                  striped
                  bordered
                  small
                  paging={false}
                  btn
                  maxHeight="300px"
                  className="table_warp"
                  data={this.state.data}
                  fixed
                ></MDBDataTable>
              ) : (
                <MDBRow center style={{ height: "80vh" }}>
                  <MDBCol lg="3">
                    <MDBSpinner />
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </Fragment>
    );
  }
}

export default withRouter(Table_automate_msg);
