export default [
  {
    label: "Bookings",
    field: "transaction",
    // sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Quantity",
    field: "quantity",
    // sort: "asc",
    minimal: "size-col-20",
  },
  {
    label: "Percent",
    field: "percent",
    // sort: "asc",
    minimal: "size-col-10 ",
  },
];
