import axios from "axios";
import { config } from "../config/config";
import getJWT_admin from "./jwt_ADMIN";
const api = config.backendApiUrl;

const ApiAdmin = async (point, contentType, data) => {
  const jwt_ADMIN = getJWT_admin();
  return new Promise(async (resolve, reject) => {
    try {
      const data_api = await axios.post(`${api}${point}`, data, {
        headers: {
          "Content-Type": contentType, //'application/json', multipart/form-data
          Authorization: `Bearer ${jwt_ADMIN}`,
        },
      });
      resolve(data_api);
    } catch (e) {
      console.log("api_admin error", e.Error);
      reject(e);
      // window.location.href = "/logout";
    }
  });
};

export default ApiAdmin;
