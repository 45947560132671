import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBTimePickere,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCardHeader,
  MDBTableBody,
  MDBTableHead,
  MDBBadge,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBListGroup,
  MDBListGroupItem,
} from "mdbreact";

class Side_modal extends React.Component {
  state = {
    modal: true,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.props.open}
          toggle={() => this.toggle()}
          className="modal-notify modal-success"
          fullHeight
          position="right"
        >
          <MDBModalHeader
            tag="p"
            toggle={() => this.toggle(10)}
            titleClass="heading lead"
          >
            Jonny, it came from the right!
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            <MDBIcon
              icon="check"
              className="animated rotateIn mb-3"
              size="4x"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit
              iusto nulla aperiam blanditiis ad consequatur in dolores culpa,
              dignissimos, eius non possimus fugiat. Esse ratione fuga, enim, ab
              officiis totam.
            </p>
            <MDBListGroup>
              <MDBListGroupItem
                hover
                className="d-flex justify-content-between align-items-center"
              >
                Cras justo odio
                <MDBBadge pill color="primary">
                  14
                </MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem
                hover
                className="d-flex justify-content-between align-items-center"
              >
                Dapibus ac facilisis in
                <MDBBadge pill color="primary">
                  2
                </MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem
                hover
                className="d-flex justify-content-between align-items-center"
              >
                Morbi leo risus
                <MDBBadge pill color="primary">
                  1
                </MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem
                hover
                className="d-flex justify-content-between align-items-center"
              >
                Cras justo odio
                <MDBBadge pill color="primary">
                  10
                </MDBBadge>
              </MDBListGroupItem>
              <MDBListGroupItem
                hover
                className="d-flex justify-content-between align-items-center"
              >
                Morbi leo risus
                <MDBBadge pill color="primary">
                  5
                </MDBBadge>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBModalBody>
          <MDBModalFooter end>
            <MDBBtn color="success" onClick={() => this.toggle(10)}>
              Get it now <MDBIcon icon="diamond white-text ml-1" />
            </MDBBtn>
            <MDBBtn color="success" outline onClick={() => this.toggle(10)}>
              No, thanks
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    );
  }
}

export default Side_modal;
