import React from "react";

import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";

import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
} from "mdbreact";

import api from "../../helpers/api";

import "./style_broadcasting.css";
import { async } from "q";
import EditBroadcastMessage from "./04-edit_broadcast_message";

class ButtonsBroadcastMessage extends React.Component {
  state = {
    modal: {
      state: false,
      title: "",
      info: "",
      delete: false,
      send: false,
      resend: false,
      edit: false,
    },

    broadcastCustomer: [],
    broadcastDate: [],

    saveBroadcastPhone: "",
    saveBroadcastDate: "",

    broadcastSave: false,
  };

  openModal = async (e) => {
    if (e === "delete") {
      this.setState({
        modal: {
          ...this.state.modal,
          state: true,
          title: "Delete Broadcast Message",
          info: "Are you sure delete broadcast message",
          delete: true,
        },
      });
    }

    if (e === "send") {
      this.setState({
        modal: {
          ...this.state.modal,
          state: true,
          title: "Send Broadcast Message",
          info: (
            <span>
              Are you sure you want to send this broadcast message now to
              customers on {this.props.data.product} on{" "}
              {this.props.data.date_broadcast_message}
            </span>
          ),
          send: true,
        },
      });
    }

    if (e === "resend") {
      this.setState({
        modal: {
          ...this.state.modal,
          state: true,
          title: "Resend Broadcast Message",
          info: (
            <span>
              Are you sure you want to resend this broadcast message to
              customers on product {this.props.data.product} on{" "}
              {this.props.data.date_broadcast_message}?
            </span>
          ),
          resend: true,
        },
      });
    }

    // if (e === "edit") {
    //   console.log("edit", this.props.data);

    //   console.log("title", this.props.data.title);
    //   console.log("product", this.props.data.product);
    //   this.setState({
    //     modal: {
    //       ...this.state.modal,
    //       state: true,
    //       title: "Edit Broadcast Message",
    //       info: <EditBroadcastMessage data={this.props.data} />,
    //       edit: true,
    //     },
    //   });
    // }
  };

  closeModal = () => {
    this.setState({
      modal: {
        state: false,
        delete: false,
        send: false,
        resend: false,
        edit: false,
      },
    });
  };

  deleteBroadcastMessage = async () => {
    // console.log("delete message", this.props.data);
    const data = {
      idBroadcast: this.props.data.id,
    };
    try {
      const deleteBroadcastMessage = await api(
        "delete_broadcast_message",
        "application/json",
        data
      );
      this.setState({
        modal: {
          ...this.state.modal,
          state: false,
          delete: false,
        },
      });
      this.props.refresh();
    } catch (e) {
      console.log(e);
    }
  };

  sendBroadcastMessage = async () => {
    // console.log("send message", this.props.data);
    let textTable = this.props.data.msg.replace(/<[^>]*>?/g, "");
    textTable = textTable.replace(/['"]+/g, "");
    textTable = textTable.replace(/&.*;/g, "");
    textTable = textTable.replace(new RegExp("\n", "g"), "");
    textTable = textTable.replace(/\\n/g, " ");
    // console.log("--->end message", textTable);
    const dataSendMessage = {
      phone: this.props.data.phone_numbers,
      body: JSON.stringify(textTable),
      id_notification: this.props.data.id_broadcast_message,
      id: this.props.data.id,
    };
    try {
      const sendBroadcastMessage = await api(
        "send_broadcast_message",
        "application/json",
        dataSendMessage
      );
      this.setState({
        modal: {
          ...this.state.modal,
          state: false,
          send: false,
        },
      });
      if(sendBroadcastMessage.data.status==="ok"){
        this.props.refresh("ok");
      }else{
        this.props.refresh("error");
      }
      
    } catch (e) {
      console.error(e);
    }
  };

  resendBroadcastMessage = async () => {
    // const msgText = draftToHtml(
    //   convertToRaw(this.props.data.msg.getCurrentContent())
    // );
    // let textTable = msgText.replace(/<[^>]*>?/g, "");
    // textTable = textTable.replace(/['"]+/g, "");
    // textTable = textTable.replace(/&.*;/g, "");
    // textTable = textTable.replace(/\\n/g, " ");

    // textTable = JSON.stringify(textTable);

    let textTable = this.props.data.msg.replace(/<[^>]*>?/g, "");
    textTable = textTable.replace(/['"]+/g, "");
    textTable = textTable.replace(/&.*;/g, "");
    textTable = textTable.replace(new RegExp("\n", "g"), "");
    textTable = textTable.replace(/\\n/g, " ");

    const dataResendMessage = {
      phone: this.props.data.phone_numbers,
      body: JSON.stringify(textTable),
      id_notification: this.props.data.id_broadcast_message,
      id: this.props.data.id,
    };
    try {
      const resendBroadcastMessage = await api(
        "resend_broadcast_message",
        "application/json",
        dataResendMessage
      );
      this.setState({
        modal: {
          ...this.state.modal,
          state: false,
          resend: false,
        },
      });
      if(resendBroadcastMessage.data.status==="ok"){
        this.props.refresh("ok");
      }else{
        this.props.refresh("error");
      }
      // this.props.refresh();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <MDBModal
          isOpen={this.state.modal.state}
          toggle={this.closeModal} //
          centered
        >
          <MDBModalHeader
            className="color_select white-text text-center"
            titleClass="w-100"
            toggle={this.closeModal}
          >
            {this.state.modal.title}
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modal.edit ? (
              this.state.modal.info
            ) : (
              <div className="body-modal_broadcast-buttons">
                {this.state.modal.info}
              </div>
            )}
          </MDBModalBody>
          <div className="footer-modal__broadcast">
            <MDBModalFooter>
              {this.state.modal.delete && (
                <div className="button-delete_broadcast">
                  <MDBBtn className="" onClick={this.deleteBroadcastMessage}>
                    Delete
                  </MDBBtn>
                </div>
              )}
              {this.state.modal.send && (
                <div className="button-send__broadcast">
                  <MDBBtn color="secondary" onClick={this.sendBroadcastMessage}>
                    Send
                  </MDBBtn>
                </div>
              )}
              {this.state.modal.resend && (
                <div className="button-resend__broadcast">
                  <MDBBtn
                    color="secondary"
                    onClick={this.resendBroadcastMessage}
                  >
                    Resend
                  </MDBBtn>
                </div>
              )}
              {this.state.modal.edit && (
                <div className="button-edit__broadcast">
                  <MDBBtn color="secondary" onClick={this.saveBroadcastMessage}>
                    Save
                  </MDBBtn>
                </div>
              )}
            </MDBModalFooter>
          </div>
        </MDBModal>

        <div className="button-broadcastMessage">
          <MDBBtn
            title="Delete broadcast message"
            tag="a"
            className="button-broadcastMessage_square color_btn-red "
            onClick={() => this.openModal("delete")}
            color="danger"
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            title={
              this.props.data.broadcast_message_sent === 0
                ? "Send this broadcast message"
                : "Reuse this broadcast message"
            }
            tag="a"
            className="button-broadcastMessage_square color_btn-d"
            onClick={() => {
              this.props.data.broadcast_message_sent === 0
                ? this.openModal("send")
                : this.openModal("resend");
            }}
            color="infoa"
          >
            {this.props.data.broadcast_message_sent === 0 ? (
              <MDBIcon icon="paper-plane" className="size_icon" />
            ) : (
              <MDBIcon icon="sync-alt" className="size_icon" />
            )}
          </MDBBtn>

          {/* <MDBBtn
            tag="a"
            className="button-broadcastMessage_square color_btn-d "
            onClick={() => this.openModal("edit")}
            color="infoa"
          >
            <MDBIcon icon="pencil-alt" className="size_icon" />
          </MDBBtn> */}
        </div>
      </React.Fragment>
    );
  }
}

export default ButtonsBroadcastMessage;
