import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
} from "mdbreact";
import { withRouter, Redirect, Route } from "react-router-dom";
import MDBDataTable from "../../../source-pack/DataTable/DataTable";
import Side_modal from "../../../components/modal_side";
import Form_new_msg from "../../../components/03-products/09-chat_bot_productos/01-add_chatbot_msg";
import Butons_bar from "../09-chat_bot_productos/03-buttonts_bots_products";
import Config_table from "../../../components/03-products/09-chat_bot_productos/02-config_table_bot";
import api from "../../../helpers/api";

class Table_chatbot extends React.Component {
  state = {
    redirect: false,
    modals: {
      form: false,
      header: {
        title: "   Create Automate Message",
        color: "color_select",
      },
      component: "aaa", //<Form_new_msg changeStateModal="aaa"/>,
      centered: true,
    },
    open: false,
    title: "",
    hours: 0,
    minutes: 5,
    action: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    time: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "00:05",
    },
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    data: "",
    productName: "",
  };

  toggle = () => {
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });

    toast.success("Automatic msg saved successfully", {
      closeButton: true,
    });
    // call to api for all data message created
    this.load_data();
  };

  open_a = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  open_modal = (data) => () => {
    if (data === "new") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "   Create Chatbot Message Product",
            color: "color_select",
          },
          component: (
            <Form_new_msg
              changeStateModal={this.toggle.bind(this)}
              productID={this.props.match.params.id}
            />
          ),
          centered: true,
        },
      });
    }
    if (data === "new-togle") {
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Create Automate Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "delete") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_btn-red",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn color="danger" className="color_select">
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" className="color_btn-red">
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = async () => {
    if (
      this.props.location.data === undefined ||
      this.props.location.data === null
    ) {
      this.setState({
        productName: localStorage.getItem("productName"),
      });
    } else {
      this.setState({
        productName: this.props.location.data,
      });
      localStorage.setItem("productName", this.props.location.data);
    }
    try {
      const res = await api(
        `all_bot_msg_product/${this.props.match.params.id}`,
        "application/json"
      );
      var a = [];
      res.data.data.map(async (val) => {
        let textTable = val.message.replace(/<[^>]*>?/g, "");
        textTable = textTable.replace(/['"]+/g, "");
        textTable = textTable.replace(/&.*;/g, " ");
        textTable = textTable.replace(/\\n/g, " ");
        a.push({
          title: val.title,
          intent: val.intent,
          followup: val.followup,
          command: val.command,
          msg: textTable,
          buttons: (
            <Butons_bar
              id_={val.idbb_chatbot_msg_products}
              refresh={this.load_data.bind(this)}
              data={val}
              // msg={remove_msg}
              msg={textTable}
            />
          ),
        });
      });

      this.setState({
        data: {
          columns: Config_table,
          rows: a,
        },
      });
    } catch (e) {}
  };

  submitHandler = (event) => {
    event.preventDefault();

    event.target.className += " was-validated";

    this.selec_type.map((valor) => {
      if (valor !== "time") {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
        }
      } else {
      }
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  getTime = (data) => {
    if (data !== `0${this.state.hours}:0${this.state.minutes}`) {
      this.setState({
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback_ok",
          data: data,
        },
      });
    }
  };

  goToback = () => {
    this.props.history.push("/products");
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}
        <Side_modal open={this.state.open} />

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBBtn color="secondary" onClick={this.goToback}>
                Back
              </MDBBtn>
              <MDBIcon far icon="bell" />
              {` Products > ${this.state.productName} > Chatbot Responses`}
            </MDBCardHeader>
            <MDBCardBody>
              <MDBBtn color="secondary" onClick={this.open_modal("new")}>
                New chat bot
              </MDBBtn>
              <MDBDataTable
                striped
                bordered
                small
                paging={false}
                btn
                maxHeight="300px"
                reponsiveSm
                className="table_warp"
                data={this.state.data}
                custom={"table_warp"}
                fixed
              ></MDBDataTable>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withRouter(Table_chatbot);
