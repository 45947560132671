import React, { useRef } from 'react';
import { render } from 'react-dom'

import EmailEditor from 'react-email-editor';

const EmailEditor_ = (props) => {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      // console.log('exportHtml', design);
    });
  };

  const onLoad = () => {
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  return (
    <div>
      <div>
        <button onClick={exportHtml}>Export HTML</button>
      </div>
      
      <EmailEditor
              projectId={1071}
              options={{
                customJS: [
                  window.location.protocol + '//' + window.location.host + '/custom.js',
                ]
              }}
        ref={emailEditorRef}
        onLoad={onLoad}
      />
    </div>
  );
};

export default EmailEditor_;
