import React from "react";
import getJWT_admin from "../helpers/jwt_ADMIN";
import { Redirect } from "react-router-dom";
import { useJwt } from "react-jwt";
import ReactGA from "react-ga";
import config from "../config";

const AuthAdmin = ({ Component }, { a }) => {
  const jwt_ADMIN = getJWT_admin();
  const { decodedToken, isExpired } = useJwt(jwt_ADMIN);

  if (decodedToken !== null) {
    ReactGA.initialize(config.googleAnalytics);
    ReactGA.pageview(window.location.pathname + " " + decodedToken.user);
    localStorage.setItem("adminUser", decodedToken.user);
  }

  //console.log(load_data());
  // console.log("11111111", a);
  //   console.log("jwt 11111111111",jwt,Component,decodedToken);

  if (!jwt_ADMIN) {
    // console.log("jwt..", jwt_ADMIN);
    return <Redirect to="/login" />;
  }
  return <Component user_data={"demo"} />;
};

export default AuthAdmin;
