import React, { Fragment } from "react";

import { withRouter } from "react-router-dom";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
  MDBSpinner,
  MDBInput,
  MDBSelect,
  MDBDataTable,
} from "mdbreact";

// import htmlToDraft from "html-to-draftjs";
// import { EditorState, convertToRaw, ContentState } from "draft-js";
// import { stripHtml } from "string-strip-html";

// import MDBDataTable from "../source-pack/DataTable/DataTable";
// import Form_new_msg from "../components/form_new_automated_message";
// import Butons_bar from "../components/butons_automated_msg";
import Config_table from "./01-config_table_broadcast";
import ModalNewBroadcastMessage from "./02-modal_new_broadcast_message";
import api from "../../helpers/api";
import ButtonsBroadcastMessage from "./03-buttons_broadcast_message";

class BroadcastingMessageMain extends React.Component {
  state = {
    modal: {
      state: false,
    },
    data: {
      columns: Config_table,
      rows: "",
    },
    loadingDataTable: false,
  };

  closeModal = async (e) => {
    // console.log("e--->", e);
    if (e === "reload") {
      await this.loadData();
    }
    this.setState({
      modal: {
        ...this.state.modal,
        state: false,
      },
    });
  };

  // toggle = () => {
  //   this.setState({
  //     modals: {
  //       form: !this.state.modals.form,
  //       header: {
  //         title: "Create Automate Message",
  //         color: "color_select",
  //       },
  //     },
  //   });
  //   toast.success("Automatic msg saved successfully", {
  //     closeButton: true,
  //     position: "top-right",
  //     autoClose: 5000,
  //     closeOnClick: true,
  //   });
  //   // call to api for all data message created
  //   this.load_data();
  // };

  // open_a = () => {
  //   this.setState({
  //     open: !this.state.open,
  //   });
  // };

  // cargando = () => {
  //   this.setState({
  //     load_data_table: true,
  //   });
  // };

  openModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        state: true,
      },
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      this.setState({
        loadingDataTable: true,
      });
      const res = await api("read_broadcast_message", "application/json");
      // console.log("query", res.data.data);
      // console.log("query res.data.data", res.data.data);
      var a = [];
      // let varTemp = 1;
      var textTable = "";
      res.data.data.map(async (val) => {
        // console.log(val.title);
        // if (varTemp == 1) {
        // const html = JSON.parse(val.message);

        // const contentBlock = htmlToDraft(html);
        // // search in array select time value

        // const contentState = ContentState.createFromBlockArray(
        //   contentBlock.contentBlocks
        // );
        // // console.log("??????????????",contentState);
        // const editorState = EditorState.createWithContent(contentState);
        // console.log(editorState);
        var textTable = val.msg.replace(/<[^>]*>?/g, "");
        textTable = textTable.replace(/['"]+/g, "");
        textTable = textTable.replace(/&.*;/g, "");
        textTable = textTable.replace(new RegExp("\n", "g"), "");
        textTable = textTable.replace(/\\n/g, " ");

        // let textTable1 = stripHtml(val.message).result;
        // textTable1 = textTable1.replace(/\s+/g, " ");
        // let textTable1 = val.message.replace(/\s+/g, " ");
        // textTable1 = stripHtml(textTable1).result;
        // console.log("1", textTable, "2", textTable1);

        var remove_msg = val.msg.substring(1);
        remove_msg = remove_msg.slice(0, -3);
        const statusBroadcast =
          val.status[0].toUpperCase() + val.status.slice(1);
        // console.log("remove ch:", remove_msg);
        a.push({
          title: val.title,
          product: val.product,
          status: statusBroadcast,
          // msg: textTable,
          buttons: (
            <ButtonsBroadcastMessage
              id_={val.id}
              refresh={this.refresData}
              data={val}
              msg={remove_msg}
            />
          ),
        });
      });

      this.setState({
        data: {
          ...this.state.data,
          rows: a,
        },
        loadingDataTable: false,
      });
    } catch (e) {
      // console.log("query error", e);
      /* this.setState({
        redirect:true
      })*/
    }
  };

  refresData = (msg) => {
    if (msg === "error") {
      toast.error("Ooop! Something went wrong", {
        position: "top-right",
        autoClose: 2000,
        closeButton: true,
        autoClose: true,
        draggable: true,
        closeOnClick: false,
      });
    }
    this.loadData();
  };

  // submitHandler = (event) => {
  //   event.preventDefault();
  //   // console.log(event.target.name, event.target.value);
  //   event.target.className += " was-validated";
  //   // console.log("form data", this.state);

  //   this.selec_type.map((valor) => {
  //     if (valor !== "time") {
  //       if (
  //         this.state[valor].data === "" ||
  //         this.state[valor].data === undefined
  //       ) {
  //         // console.log("correct");
  //         this.setState({
  //           [valor]: {
  //             class_novalid: "novalidate_select",
  //             class_valid: "valid-feedback",
  //           },
  //         });
  //       } else {
  //         // console.log("incorrect");
  //         this.setState({
  //           [valor]: {
  //             class_novalid: "invalid-feedback",
  //             class_valid: "valid-feedback_ok",
  //             data: this.state[valor].data,
  //           },
  //         });
  //       }
  //     } else {
  //     }
  //   });
  //   // console.log("response", this.state);
  // };

  // changeHandler = (event) => {
  //   this.setState({ [event.target.name]: event.target.value });
  //   // console.log(this.state);
  // };

  // setSelect = (source, data) => {
  //   this.setState({
  //     [source]: {
  //       class_novalid: "invalid-feedback",
  //       class_valid: "valid-feedback_ok",
  //       data: data,
  //     },
  //   });
  // };

  render() {
    // console.log("---?");
    return (
      <Fragment>
        {/* <ToastContainer /> */}
        {this.state.modal.state && (
          <ModalNewBroadcastMessage
            openModal={this.state.modal.state}
            closeModal={this.closeModal}
          />
        )}

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon fab icon="intercom" /> Broadcast Message
            </MDBCardHeader>

            <MDBCardBody>
              <MDBBtn color="secondary" onClick={this.openModal}>
                New Broadcast Message
              </MDBBtn>
              {this.state.loadingDataTable ? (
                <MDBRow center style={{ height: "80vh" }}>
                  <MDBCol lg="3">
                    <MDBSpinner />
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBDataTable
                  striped
                  bordered
                  small
                  paging={false}
                  btn
                  maxHeight="300px"
                  className="table_warp"
                  data={this.state.data}
                  fixed
                ></MDBDataTable>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </Fragment>
    );
  }
}

export default withRouter(BroadcastingMessageMain);
