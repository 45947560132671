import React from "react";

import api from "../../helpers/api";

import { MDBBtn, MDBSelect, MDBAlert, MDBCol } from "mdbreact";

class UserStatus extends React.Component {
  state = {
    userStatusSelected: "",
    userStatus: [],
    userStatusType: ["active", "onboarding", "pause", "disabled"],
    informationMessage: "",
    buttonsStatus: false,
    dataToSave: {},
    userPhoneNumber: "",
    userId: null,
    userName: "",
    showButtons: true,
  };

  componentDidMount() {
    try {
      const userData = this.props.data_edit;
      const userStatus = userData.statusDocker;
      const userStatusValidated = this.validData(userStatus);
      const objectOptions = this.buildObject(
        this.state.userStatusType,
        userStatusValidated
      );

      this.setState({
        userStatus: objectOptions,
        userPhoneNumber: userData.numero_wp,
        userId: userData.id_usuario,
        userName: userData.nombre_usuario,
      });
    } catch (e) {
      console.log("componentDidMount e :>> ", e, e.stack);
    }
  }

  validData = (dataToValid) => {
    let dataValidated = "";
    if (
      dataToValid.userStatusGetting !== null ||
      dataToValid.userStatusGetting !== ""
    ) {
      dataValidated = dataToValid;
    }

    return dataValidated;
  };

  buildObject = (statusType, userStatus) => {
    let buildUserStatus = statusType.map((status, index) => {
      if (status === userStatus) {
        return {
          checked: true,
          disabled: false,
          icon: null,
          value: `${index + 1}`,
          text: status.charAt(0).toUpperCase() + status.slice(1),
        };
      } else {
        return {
          checked: false,
          disabled: false,
          icon: null,
          value: `${index + 1}`,
          text: status.charAt(0).toUpperCase() + status.slice(1),
        };
      }
    });

    return buildUserStatus;
  };

  setSelectUserStatus = (userStatus) => {
    try {
      if (
        userStatus !== "" &&
        userStatus !== undefined &&
        userStatus !== null
      ) {
        this.setState({
          userStatusSelected: userStatus.toLowerCase(),
          buttonsStatus: false,
          informationMessage: "",
          showButtons: true,
        });
      }
    } catch (e) {
      console.error("setSelectUserStatus", e, e.stack);
    }
  };

  submitHandler = async (event) => {
    try {
      event.preventDefault();

      const statusSelected = this.state.userStatusSelected;

      const validateStatus = this.validateStatus(statusSelected);

      this.setState({
        buttonsStatus: validateStatus.buttonStatus,
        informationMessage: validateStatus.informationMessage,
        showButtons: validateStatus.showButtons,
        dataToSave: {
          userStatus: statusSelected,
          smsService: statusSelected === "active" ? "active" : "inactive",
          userPhoneNumber: this.state.userPhoneNumber,
          userId: this.state.userId,
          userName: this.state.userName,
        },
      });
    } catch (e) {
      console.error("sumit handler error", e, e.stack);
    }
  };

  validateStatus = (statusSelected) => {
    if (
      (this.state.userPhoneNumber === null ||
        this.state.userPhoneNumber === "") &&
      statusSelected === "active"
    ) {
      return {
        buttonStatus: false,
        showButtons: false,
        informationMessage: "This user doesn't have a phone number",
      };
    }
    return {
      buttonStatus: true,
      showButtons: true,
      informationMessage: `Are you sure you want to change the user status to "
      ${statusSelected.charAt(0).toUpperCase() + statusSelected.slice(1)}
      "?`,
    };
  };

  sendApi = async () => {
    try {
      const resUserStatus = await api(
        "userSettings/userStatus",
        "application/json",
        this.state.dataToSave
      );

      if (resUserStatus.data.status === "ok") {
        this.props.refresh_();
      }
    } catch (e) {
      console.error("error sendApi", e, e.stack);
    }
  };

  render() {
    return (
      <form
        // className="needs-validation"
        onSubmit={this.submitHandler}
        noValidate
        className="text-left"
      >
        <div className="md-form">
          <MDBSelect
            name="status"
            options={this.state.userStatus}
            label="Status"
            getTextContent={(userStatus) =>
              this.setSelectUserStatus(userStatus)
            }
            color="primary"
          ></MDBSelect>
        </div>

        <div className="md-form">
          <h6 className="delete_white-space">
            {this.state.informationMessage}
          </h6>
        </div>

        <div className="text-center">
          {this.state.showButtons ? (
            this.state.buttonsStatus ? (
              <div>
                <MDBBtn
                  color="danger"
                  className="color_select"
                  onClick={() => this.props.modalData("new-togle", "")}
                >
                  Cancel
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  className="color_btn-red"
                  onClick={() => this.sendApi()}
                >
                  Continue
                </MDBBtn>
              </div>
            ) : (
              <MDBBtn color="deep-orange" type="submit" className="btn_save">
                {this.state.save ? (
                  <div className="d-flex flex-row">
                    <div className="p-1">Save</div>
                    <div className="p-1">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-1">Save</div>
                )}
              </MDBBtn>
            )
          ) : (
            ""
          )}
        </div>
      </form>
    );
  }
}

export default UserStatus;
