export default [
  {
    label: "Report Name",
    field: "reportName",
    width: 600,
  },

  {
    label: "Created At",
    field: "createdAt",
    width: 600,
  },
  {
    label: "Actions",
    field: "actions",
    width: 50,
  },
];
