import React from "react";

import {
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBRow,
} from "mdbreact";

import socketIOClient from "socket.io-client";
import Ms_component from "./02-ms_card";
class MonitoringMicroservicePage extends React.Component {
  state = {
    activeItemClassicTabs3: "1",
    socket: socketIOClient("http://apiadmin.touropp.com", {
      //http://apiadmin.touropp.com
      transports: ["websocket"],
    }),
    data_socket: "",
    socket_connect: false,
  };

  toggleClassicTabs3 = (tab) => () => {
    if (this.state.activeItemClassicTabs3 !== tab) {
      this.setState({
        activeItemClassicTabs3: tab,
      });
    }
  };

  async componentDidMount() {
    this.state.socket.on("connect", (data) => {
      console.log("---CONECT SOCKET");
      this.setState({
        socket_connect: true,
      });
    });

    this.state.socket.on("disconnect", () => {
      this.setState({
        socket_connect: false,
      });
      console.log("-----DISCONECT SOCKET");
    });
    this.state.socket.on("state_MS_ENVIAR", (d) => {
      this.setState({
        data_socket: d,
      });
    });
  }

  socket_funtion(data) {
    this.state.socket.on(data, (d) => {
      console.log("--->", d);
      return d;
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="classic-tabs">
          <MDBNav classicTabs className="mt-1 color_select">
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItemClassicTabs3 === "1"}
                onClick={this.toggleClassicTabs3("1")}
              >
                <MDBIcon icon="cog" size="2x" />
                <br />
                Microservicio 1
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItemClassicTabs3 === "2"}
                onClick={this.toggleClassicTabs3("2")}
              >
                <MDBIcon far icon="chart-bar" size="2x" />
                <br />
                Microservicio 2
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent
            className="card mb-5"
            activeItem={this.state.activeItemClassicTabs3}
          >
            <MDBTabPane tabId="1">
              {this.state.socket_connect ? (
                <MDBCard className="text-left">
                  <MDBCardHeader className="white-text color_select p-4 text-left">
                    <MDBIcon far icon="paper-plane" /> MS_SEND |{" "}
                    <MDBIcon icon="microchip" /> Memory: |{" "}
                    <MDBIcon icon="laptop" /> CPU%:
                  </MDBCardHeader>
                  <MDBCardBody className="mt-0">
                    <MDBCard className="text-left">
                      <MDBCardHeader className="white-text color_select p-4 text-left">
                        <MDBIcon far icon="paper-plane" /> PROCESS 1
                      </MDBCardHeader>
                      <MDBCardBody>
                        <MDBRow className="mt-5">
                          <Ms_component
                            data_socket={this.state.data_socket}
                            name_process={"1.1"}
                            socket_active
                          />
                          <Ms_component
                            data_socket={this.state.data_socket}
                            name_process={"1.2"}
                          />
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCardBody>
                </MDBCard>
              ) : (
                "no connect "
              )}
            </MDBTabPane>

            <MDBTabPane tabId="2"></MDBTabPane>
          </MDBTabContent>
        </div>
      </React.Fragment>
    );
  }
}

export default MonitoringMicroservicePage;
