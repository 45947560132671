import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  MDBInput,
  toast,
  ToastContainer,
  MDBSpinner,
} from "mdbreact";

import api from "../../helpers/api";

class User_config extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modals: {
        form: false,
        header: {
          title: "   Create Automate Message",
          color: "color_select",
        },
        component: "aaa", //<Form_new_msg changeStateModal="aaa"/>,
        centered: true,
      },
      open: false,
      passwordError: false,
      class_: "valid-feedback",
      class_check_userName: "mb-3",
      class_check_email: "mb-3",
      class_check_newPassword: "mb-3",
      class_check_confirmPassword: "mb-3",
      class_check_numberTestWhats: "mb-3",
      class_check_token: "mb-3",
      class_check_url: "mb-3",
      class_check_numberWhats: "mb-3",
      class_check_contactName: "mb-3",
      userName: "",
      email: "",
      newPassword: "",
      confirmPassword: "",
      numberTestWhats: "",
      token: "",
      url: "",
      numberWhats: "",
      contactName: "",
      show_msg_newPassword: "noshow_incorrect",
      user_name_msg: "noshow_incorrect",
      token_msg: "noshow_incorrect",
      url_wp_msg: "noshow_incorrect",
      contactName_msg: "noshow_incorrect",
      load_data: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  array_label = [
    "userName",
    "email",
    "newPassword",
    "confirmPassword",
    "numberTestWhats",
    "token",
    "url",
    "numberWhats",
  ];

  async componentDidMount() {
    this.load_data_api();
  }

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  load_data_api = async () => {
    this.setState({
      load_data: false,
    });
    try {
      const a = await api("userdata_user_info", "application/json");
      var data_ = a.data.data.user_info;
      // console.log("-----------",data_.contact_name);
      this.setState({
        userName: data_.nombre_usuario,
        email: data_.email,
        newPassword: "",
        confirmPassword: "",
        numberTestWhats: data_.whatsapp_test_number,
        token: data_.token_wp,
        url: data_.url_wp,
        numberWhats: data_.numero_wp,
        contactName: data_.contact_name,
        load_data: true,
      });
      this.array_label.map((valor, indice) => {
        const a = "class_check_" + valor;
        this.setState({
          [a]: "mb-3",
        });
      });
      // console.log("data received", this.state.data);
    } catch (e) {
      // console.log("load data error", e);
    }
  };

  send_data = async (dataSend) => {

    try {
      const res = await api("edit_userdata", "application/json", dataSend);
      // console.log("response:", res.data);
      if (res.data.status === "ok") {
        // console.log("ok");
      }
      if (res.data.status === "error") {
        toast.error("Oops, an error ocurred", {
          closeButton: true,
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
        });
        if (res.data.field === "token_wp") {
          this.setState({
            token_msg: "show_incorrect",
          });
        } else {
          this.setState({
            token_msg: "noshow_incorrect",
          });
        }

        if (res.data.field === "url_wp") {
          this.setState({
            url_wp_msg: "show_incorrect",
          });
        } else {
          this.setState({
            url_wp_msg: "noshow_incorrect",
          });
        }
        if (res.data.field === "nombre_usuario") {
          this.setState({
            user_name_msg: "show_incorrect",
          });
        } else {
          this.setState({
            user_name_msg: "noshow_incorrect",
          });
        }
        if (res.data.field === "contact-name") {
          this.setState({
            contactName_msg: "show_incorrect",
          });
        } else {
          this.setState({
            contactName_msg: "noshow_incorrect",
          });
        }
      }
      this.load_data_api();
      toast.success("New " + dataSend.info + " saved", {
        closeButton: true,
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    } catch (e) {
      toast.error("Oops, an error ocurred", {
        closeButton: true,
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
      // console.log("error-querry", e);
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    // console.log(event.target.checkValidity());
    var validations = [
      {
        camp_: "userName",
        state_: false,
      },
      {
        camp_: "email",
        state_: false,
      },
      {
        camp_: "newPassword",
        state_: false,
      },
      {
        camp_: "confirmPassword",
        state_: false,
      },
      {
        camp_: "numberTestWhats",
        state_: false,
      },
      {
        camp_: "token",
        state_: false,
      },
      {
        camp_: "url",
        state_: false,
      },
      {
        camp_: "numberWhats",
        state_: false,
      },
    ];
    this.array_label.map((valor, indice) => {
      // console.log("map--->", this.state[valor], valor);
      if (this.state[valor] === "" || this.state[valor] === null) {
        const name_cls = "class_check_" + valor;
        this.setState({
          [name_cls]: "mb-3 novalidar_campo",
        });

        validations.forEach(function (item) {
          if (item.camp_ === valor) {
            item.state_ = false;
          }
        });
      } else {
        const name_cls = "class_check_" + valor;
        this.setState({
          [name_cls]: "mb-3 validar_campo",
        });
        validations.forEach(function (item) {
          if (item.camp_ === valor) {
            item.state_ = true;
          }
        });
      }
      if (this.state.newPassword === "" && this.state.confirmPassword === "") {
        // console.log("error updating password");
        validations.forEach(function (item) {
          if (
            item.camp_ === "newPassword" ||
            item.camp_ === "confirmPassword"
          ) {
            item.state_ = true;
          }
        });
        this.setState({
          class_check_newPassword: "mb-3 validar_campo",
          class_check_confirmPassword: "mb-3 validar_campo",
        });
      } else {
        // console.log("value to update password");
        if (
          this.state.newPassword !== this.state.confirmPassword &&
          valor === "confirmPassword" &&
          validations[2].state_ &&
          validations[3].state_
        ) {
          validations.forEach(function (item) {
            if (
              item.camp_ === "newPassword" ||
              item.camp_ === "confirmPassword"
            ) {
              item.state_ = false;
            }
          });
          this.setState({
            show_msg_newPassword: "show_incorrect",
            class_check_confirmPassword: "mb-3 novalidar_campo",
            class_check_newPassword: "mb-3 novalidar_campo",
          });
        } else if (
          this.state.newPassword === this.state.confirmPassword &&
          valor === "confirmPassword" &&
          validations[2].state_ &&
          validations[3].state_
        ) {
          this.setState({
            show_msg_newPassword: "noshow_incorrect",
            class_check_confirmPassword: "mb-3 validar_campo",
            class_check_newPassword: "mb-3 validar_campo",
          });
        }
      }
    });
    // console.log("validations", validations);
    var counter_validations = 0;
    validations.map((val) => {
      if (val.state_ === true) {
        // console.log("someone field invalid")
        counter_validations++;
      }
    });
    // console.log("fields to validate:", counter_validations);
    if (counter_validations === 8) {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Save user data",
            color: "color_select",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure ?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={this.open_modal("new-togle")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={this.send_data}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  open_modal = (data) => () => {
    // console.log("data from click", data);

    if (data === "new-togle") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Save user data",
            color: "color_select",
          },
        },
      });
    }
  };

  save_userName = async () => {
    // console.log("save username-->dg", this.state.userName);
    if (this.state.userName === "" || this.state.userName === undefined) {
      // console.log("Nothing to save");
      this.setState({
        class_check_userName: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("username wil save");
      const data_username = {
        camp_: "userName",
        data: this.state.userName,
        info: "username",
      };
      this.send_data(data_username);
      this.setState({
        class_check_userName: "mb-3 validar_campo",
      });
    }
  };

  save_email = async () => {
    // console.log("save email-->dg", this.state.email);
    if (this.state.email === "" || this.state.email === undefined) {
      // console.log("Nothing to save");
      this.setState({
        class_check_email: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("email will save");
      const data_email = {
        camp_: "email",
        data: this.state.email,
        info: "email",
      };
      this.send_data(data_email);
      this.setState({
        class_check_email: "mb-3 validar_campo",
      });
    }
  };

  save_password = async () => {
    // console.log(
    //   "save password-->dg",
    //   this.state.newPassword,
    //   this.state.confirmPassword
    // );
    if (
      this.state.newPassword === "" ||
      this.state.newPassword === undefined ||
      this.state.confirmPassword === "" ||
      this.state.confirmPassword === undefined
    ) {
      // console.log("Nothing to save");
      this.setState({
        class_check_newPassword: "mb-3 novalidar_campo",
        class_check_confirmPassword: "mb-3 novalidar_campo",
      });
    } else {
      if (this.state.newPassword === this.state.confirmPassword) {
        // console.log("password will save");
        const data_password = {
          camp_: "password",
          data: this.state.newPassword,
          info: "password",
        };
        this.send_data(data_password);
        this.setState({
          class_check_newPassword: "mb-3 validar_campo",
          class_check_confirmPassword: "mb-3 validar_campo",
        });
      } else {
        this.setState({
          class_check_newPassword: "mb-3 novalidar_campo",
          class_check_confirmPassword: "mb-3 novalidar_campo",
        });
      }
    }
  };

  save_numberTestWhatsapp = async () => {
    if (
      this.state.numberTestWhats === "" ||
      this.state.numberTestWhats === undefined
    ) {
      // console.log("Nothing to save");
      this.setState({
        class_check_numberTestWhats: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("number test whatsapp will be saved");
      const data_numberTestWhatsapp = {
        camp_: "numberTestWhatsapp",
        data: this.state.numberTestWhats,
        info: "test number",
      };
      this.send_data(data_numberTestWhatsapp);
      this.setState({
        class_check_numberTestWhats: "mb-3 validar_campo",
      });
    }
  };

  save_numberWhatsapp = async () => {
    // console.log("whatsapp number save-->dg", this.state.numberWhats);
    if (this.state.numberWhats === "" || this.state.numberWhats === undefined) {
      // console.log("Nothing to save");
      this.setState({
        class_check_numberWhats: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("whatsapp number will save");
      const data_numberWhatsapp = {
        camp_: "numberWhatsapp",
        data: this.state.numberWhats,
        info: "number whatsapp",
      };
      this.send_data(data_numberWhatsapp);
      this.setState({
        class_check_numberWhats: "mb-3 validar_campo",
      });
    }
  };

  save_token = async () => {
    // console.log("save token-->dg", this.state.token);
    if (this.state.token === "" || this.state.token === undefined) {
      // console.log("Nothing to save");
      this.setState({
        class_check_token: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("token wil save");
      const data_token = {
        camp_: "token",
        data: this.state.token,
        info: "token",
      };
      this.send_data(data_token);
      this.setState({
        class_check_token: "mb-3 validar_campo",
      });
    }
  };

  save_url = async () => {
    // console.log("save url-->dg", this.state.url);
    if (this.state.url === "" || this.state.url === undefined) {
      // console.log("nothing to save");
      this.setState({
        class_check_url: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("url will save");
      const data_url = {
        camp_: "url",
        data: this.state.url,
        info: "url",
      };
      this.send_data(data_url);
      this.setState({
        class_check_url: "mb-3 validar_campo",
      });
    }
  };

  save_contactName = async () => {
    // console.log("save contacName-->dg", this.state.url);
    if (this.state.contactName === "" || this.state.contactName === undefined) {
      // console.log("Nothing to save");
      this.setState({
        class_check_contactName: "mb-3 novalidar_campo",
      });
    } else {
      // console.log("contact name wil save");
      const data_url = {
        camp_: "contactName",
        data: this.state.contactName,
        info: "contact name",
      };
      this.send_data(data_url);
      this.setState({
        class_check_contactName: "mb-3 validar_campo",
      });
    }
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />

        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>
        {this.state.load_data ? (
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select text-left">
              User Configuration
            </MDBCardHeader>
            <MDBModalBody>
              <form onSubmit={this.submitHandler} noValidate>
                <div className="grey-text">
                  <MDBRow>
                    <MDBCol size="8">
                      <MDBInput
                        className={this.state.class_check_userName}
                        name="userName"
                        label="Username"
                        type="text"
                        value={this.state.userName}
                        onChange={this.changeHandler}
                      >
                        <div className="invalid-feedback">
                          Insert an user name!
                        </div>
                        <div className={this.state.user_name_msg}>
                          Username already exist
                        </div>
                      </MDBInput>
                    </MDBCol>

                    <MDBCol size="1">
                      <MDBBtn
                        color="deep-orange"
                        type="button"
                        onClick={this.save_userName}
                      >
                        save
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="8">
                      <MDBInput
                        className={this.state.class_check_email}
                        name="email"
                        value={this.state.email}
                        label="Email"
                        type="email"
                        error="wrong"
                        validate
                        success="right"
                        onChange={this.changeHandler}
                      >
                        <div className="invalid-feedback">
                          Insert a correct email!
                        </div>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol size="1">
                      <MDBBtn
                        color="deep-orange"
                        type="button"
                        onClick={this.save_email}
                      >
                        save
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="8">
                      <MDBInput
                        className={this.state.class_check_newPassword}
                        name="newPassword"
                        value={this.state.newPassword}
                        label="New Password"
                        type="password"
                        validate
                        onChange={this.changeHandler}
                      />
                      <MDBInput
                        className={this.state.class_check_confirmPassword}
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        label="Confirm Password"
                        group
                        type="password"
                        validate
                        onChange={this.changeHandler}
                      >
                        <div className={this.state.show_msg_newPassword}>
                          Insert same password!
                        </div>
                      </MDBInput>
                    </MDBCol>
                    <MDBCol size="1">
                      <MDBBtn
                        color="deep-orange"
                        type="button"
                        onClick={this.save_password}
                      >
                        save
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="8">
                      <MDBInput
                        className={this.state.class_check_numberTestWhats}
                        name="numberTestWhats"
                        value={this.state.numberTestWhats}
                        label="Test phone number"
                        type="number"
                        validate
                        onChange={this.changeHandler}
                      ></MDBInput>
                    </MDBCol>
                    <MDBCol size="1">
                      <MDBBtn
                        color="deep-orange"
                        type="button"
                        onClick={this.save_numberTestWhatsapp}
                      >
                        save
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="8">
                      <MDBInput
                        name="numberWhats"
                        value={this.state.numberWhats}
                        label="Phone number"
                        disabled
                        type="text"
                      ></MDBInput>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="8">
                      <MDBInput
                        className={this.state.class_check_contactName}
                        name="contactName"
                        value={this.state.contactName}
                        label="Contact name"
                        type="text"
                        validate
                        onChange={this.changeHandler}
                      ></MDBInput>
                    </MDBCol>
                    <MDBCol size="1">
                      <MDBBtn
                        color="deep-orange"
                        type="button"
                        onClick={this.save_contactName}
                      >
                        save
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </form>
            </MDBModalBody>

          </MDBCard>
        ) : (
          <MDBRow center style={{ height: "80vh" }}>
            <MDBCol lg="3">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        )}
      </Fragment>
    );
  }
}

export default User_config;
