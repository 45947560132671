import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBSpinner,
  MDBModalBody,
  MDBCardHeader,
  MDBDataTable,
  MDBInput,
  MDBSelect,
} from "mdbreact";
import Config_table from "./03-config_table_products";
import Butons_bar from "./04-buttons_user_setting";
import api from "../../helpers/api";
class credential_token extends React.Component {
  state = {
    options: [],
    data_table: "",
    system: "",
    parameter: "",
    token: "",
    idusr_config: "",
    load_data_state: false,
  };

  componentDidMount = () => {
    // llamar al api
    this.call_api();
  };

  call_api = async () => {
    try {
      this.setState({
        load_data_state: false,
      });

      const [getCredentialToken, getBookingSystems] =
        await this.getDataFromDatabase();

      const [validateCredentialToken, validateBookingSystems] =
        await this.validDataFromDatabase(getCredentialToken, getBookingSystems);

      const [builtCredentialToken, builtBookingSystems] =
        await this.buildDataFromDatabase(
          validateCredentialToken,
          validateBookingSystems
        );

      const saveData = await this.saveDataFromDatabase(
        builtCredentialToken,
        builtBookingSystems
      );
    } catch (error) {}
  };

  getDataFromDatabase = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const getDataCredentialToken = await api(
          "all_credentials_token",
          "application/json"
        );

        let dataCredentialToken = [];
        getDataCredentialToken.data.data.map((val) => {
          dataCredentialToken.push({
            parameters: val.parameter,
            token: val.token,
            system: val.system,
            buttons: (
              <Butons_bar
                id_={val.idusr_config}
                delete={() => this.delete_(val.idusr_config)}
                edit={() => this.edit_(val)}
              />
            ),
          });
        });

        const getBookingSystems = await api(
          "getAllBookingSystemsAdmin",
          "application/json"
        );

        let bookingSystems = [];
        getBookingSystems.data.data.map((val) => {
          bookingSystems.push(val.system);
        });

        resolve([dataCredentialToken, bookingSystems]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `getDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  validDataFromDatabase = async (validCredentialToken, validBookingSystems) => {
    return new Promise(async (resolve, reject) => {
      try {
        validCredentialToken.map((val) => {
          if (typeof val !== "object" || Array.isArray(val)) {
            throw { message: `Data isn't a string, received: ${val}` };
          }
        });

        if (validBookingSystems.length === 0) {
          throw { message: "validBookingSystems is empty" };
        }

        validBookingSystems.map((val) => {
          if (typeof val !== "string") {
            throw { message: `Data isn't a string, received: ${val}` };
          }
        });
        resolve([validCredentialToken, validBookingSystems]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `validDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  buildDataFromDatabase = async (credentialToken, bookingSystems) => {
    return new Promise(async (resolve, reject) => {
      try {
        const bookingSystesSort = bookingSystems.sort();
        const buildDataForTable = {
          columns: Config_table,
          rows: credentialToken,
        };

        let buildBookingSystems = [];

        bookingSystesSort.map((val) => {
          buildBookingSystems.push({
            checked: false,
            disabled: false,
            icon: null,
            value: val.charAt(0).toUpperCase() + val.slice(1),
            text: val.charAt(0).toUpperCase() + val.slice(1),
          });
        });

        resolve([buildDataForTable, buildBookingSystems]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `buildDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  saveDataFromDatabase = async (credentialToken, bookingSystems) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({
          data_table: credentialToken,
          load_data_state: true,
          system: "",
          parameter: "",
          token: "",
          idusr_config: "",
          options: bookingSystems,
        });

        resolve(true);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `saveDataFromDatabase - file ${__filename}`,
        });
      }
    });
  };

  delete_ = async (val) => {
    try {
      const res = await api("delete_credentials_token", "application/json", {
        idusr_config: val,
      });
      if (res.data.status === "ok") {
      }
      this.call_api();
    } catch (e) {}
  };

  edit_ = (val) => {
    var select_new = [];
    this.state.options.map((val_) => {
      if (val_.text === val.system) {
        val_.checked = true;
      }
      select_new.push(val_);
    });
    this.setState({
      system: val.system,
      parameter: val.parameter,
      token: val.token,
      idusr_config: val.idusr_config,
      options: select_new,
    });
  };

  save_data = async () => {
    try {
      this.setState({
        load_data_state: false,
      });
      if (
        this.state.system !== "" &&
        this.state.parameter !== "" &&
        this.state.token !== ""
      ) {
        const data_form = {
          system: this.state.system,
          parameter: this.state.parameter,
          token: this.state.token,
          idusr_config: this.state.idusr_config,
        };

        const res = await api(
          "create_credentials_token",
          "application/json",
          data_form
        );
        if (res.data.status === "ok") {
        }
        this.call_api();
      } else {
        this.setState({
          load_data_state: true,
        });
      }
    } catch (e) {}
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setSelect = (a) => {
    this.setState({
      system: a,
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.load_data_state ? (
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select text-left">
              Credentials/Token
            </MDBCardHeader>
            <MDBModalBody>
              <form>
                <div>
                  <MDBRow>
                    <MDBCol md="3">
                      <div className="md-form scrollbar_booking-systems">
                        <MDBSelect
                          options={this.state.options}
                          color="primary"
                          label="System"
                          name="system"
                          className="text_1"
                          getTextContent={this.setSelect.bind(this)}
                        />
                      </div>
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        className="mb-3"
                        name="parameter"
                        label="Parameter"
                        type="text"
                        validate
                        value={this.state.parameter}
                        onChange={this.changeHandler}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBInput
                    className="mb-3"
                    name="token"
                    label="Token"
                    type="text"
                    validate
                    value={this.state.token}
                    onChange={this.changeHandler}
                  />
                </div>
                <div className="text-right">
                  <MDBBtn
                    color="deep-orange"
                    type="button"
                    onClick={this.save_data}
                  >
                    SAVE
                  </MDBBtn>
                </div>
              </form>
              <MDBDataTable
                striped
                bordered
                small
                paging={false}
                btn
                maxHeight="300px"
                className="table_warp"
                data={this.state.data_table}
                fixed
              ></MDBDataTable>
            </MDBModalBody>
          </MDBCard>
        ) : (
          <MDBRow center style={{ height: "80vh" }}>
            <MDBCol lg="3">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        )}
      </Fragment>
    );
  }
}

export default credential_token;
