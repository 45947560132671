import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBAlert,
} from "mdbreact";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import api from "../../helpers/api";
import crypto from "crypto";
class New_automated_msg extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: {
        show: false,
        msg: "",
      },
      save: false,
      saving: false,
      service: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback",
        data: "",
      },
      system: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback",
        data: "",
      },
      modal: false,
      user_name: "",
      password: crypto.randomBytes(5).toString("hex"),
      email: "suppliers@touropp.com",
      bookingSystemOptions: [],
    };
  }

  service = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "Starter",
        text: "Starter",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "Pro",
        text: "Pro",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "Ultimate",
        text: "Ultimate",
      },
    ],
  };

  type = {
    options: [
      "fareharbor",
      "bokun",
      "checkfront",
      "rocketrez",
      "rezdy",
      "peekpro",
      "tourcms",
      "waiver",
      "ventrata",
    ],
  };

  async componentDidMount() {
    const bookingsSystemsCreated = await this.loadData(
      this.props.data_edit.id_usuario
    );

    let bookingsAvailable = [];

    this.type.options.map((val) => {
      let bookingExist = true;
      bookingsSystemsCreated.map((val1) => {
        if (val === val1) {
          bookingExist = false;
        }
      });
      if (bookingExist) {
        bookingsAvailable.push({
          checked: false,
          disabled: false,
          icon: null,
          value: `${val}`,
          text: `${val}`,
        });
      }
    });

    this.setState({
      user_name: this.props.data_edit.nombre_usuario,
      bookingSystemOptions: bookingsAvailable,
    });
  }

  loadData = async (userId) => {
    try {
      const data = {
        userId,
      };
      const res = await api(
        "read_bookings_systems_adminEdit",
        "application/json",
        data
      );

      let bookingsSystemsCreated = [];
      res.data.data.map((val) => {
        bookingsSystemsCreated.push(val.booking_system);
      });
      return bookingsSystemsCreated;
    } catch (e) {
      return "error";
    }
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.saving === false) {
      event.target.className += " was-validated";

      var a = this.validate_selectBOX();
      var b = await this.input_fields();

      if (a && b) {
        const datos = {
          nombre_usuario: this.state.user_name,
          pass: this.state.password,
          email: this.state.email,
          system: this.state.system.data,
          service: this.state.service.data,
        };

        this.send_api(datos);
      }
    } else {
    }
  };

  input_fields = () => {
    if (this.state.user_name && this.state.password && this.state.email) {
      return true;
    } else {
      return false;
    }
  };

  validate_selectBOX = () => {
    var counter = 0;
    if (this.state.system.data === "") {
      this.setState({
        system: {
          class_novalid: "novalidate_select",
          class_valid: "valid-feedback",
          data: "",
        },
      });
    } else {
      this.setState({
        system: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.state.system.data,
        },
      });
      counter++;
    }
    if (this.state.service.data === "") {
      this.setState({
        service: {
          class_novalid: "novalidate_select",
          class_valid: "valid-feedback",
          data: "",
        },
      });
    } else {
      this.setState({
        service: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.state.service.data,
        },
      });
      counter++;
    }
    if (counter === 0) {
      return false;
    }
    if (counter === 2) {
      return true;
    }
  };

  send_api = async (data) => {
    this.setState({
      save: true,
      saving: true,
    });
    try {
      const res = await api("add_new_USER", "application/json", data);

      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        this.props.changeStateModal();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("error-query", e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setSelect_system = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  setSelect_service = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  render() {
    return (
      <form
        // className="needs-validation"
        onSubmit={this.submitHandler}
        noValidate
        className="text-left"
      >
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}

        <div className="md-form">
          <MDBInput
            value={this.state.user_name}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="user_name"
            label="User Name"
            disabled
          >
            <div className="invalid-feedback">Please insert a user name.</div>
          </MDBInput>
        </div>

        <MDBRow>
          <MDBCol md="6">
            {/* select Booking System */}
            <div className="md-form">
              <MDBSelect
                multiple
                name="system"
                options={this.state.bookingSystemOptions}
                label="Booking System"
                getTextContent={this.setSelect_system.bind(this, "system")}
                color="primary"
              ></MDBSelect>

              <div className={this.state.system.class_novalid}>
                select booking system.
              </div>
            </div>

            {/*end select booking System */}
          </MDBCol>

          <MDBCol md="6">
            {/* select Topic */}
            <div className="md-form">
              <MDBSelect
                name="service"
                options={this.service.options}
                label="Service"
                getTextContent={this.setSelect_service.bind(this, "service")}
                color="primary"
              ></MDBSelect>

              <div className={this.state.service.class_novalid}>
                Select service
              </div>
            </div>

            {/*end select Topic */}
          </MDBCol>
        </MDBRow>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default New_automated_msg;
