import React, { Component } from "react";
import { MDBSelect, MDBAlert, MDBBtn } from "mdbreact";
import api from "../../helpers/api";
class copytoproducts_bot extends Component {
  state = {
    options: [],
    save: false,
    saving: false,
    template_id: this.props.id_,
    toProduct: [],
    alert: {
      show: false,
      msg: "",
    },
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = async () => {
    // console.log("refresh")
    var data_ = [];
    try {
      const res = await api("all_Products_Select", "application/json");
      // console.log("query",res);
      // console.log("query-1",res.data.data);
      res.data.data.products.map((val) => {
        data_.push({
          text: val.productName,
          value: val.idusr_tours,
        });
      });

      this.setState({
        options: data_,
      });
    } catch (e) {
      // console.log("query error",e);
    }
  };

  setSelect = (data) => {
    // console.log("selected:",data)
    this.setState({
      toProduct: data,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    // console.log("send data:",this.props.id_);

    if (this.state.saving === false) {
      this.setState({
        save: true,
        saving: true,
      });
      // console.log("loading")
      try {
        const data_ = {
          template_id: this.props.id_,
          toProduct: this.state.toProduct,
        };
        const a = await api("botcopyto", "application/json", data_);
        // console.log("received",a);
        this.props.refresh_();
      } catch (e) {
        // console.log("send error",e);
      }
    } else {
      // console.log("loading")
    }
  };

  render() {
    return (
      <form
        onSubmit={this.submitHandler}
        noValidate
        className="text-left"
      >
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}
        <div className="md-form">
          <MDBSelect
            multiple
            search
            options={this.state.options}
            selected="Choose products"
            selectAll
            getValue={this.setSelect.bind(this)}
          />
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default copytoproducts_bot;
