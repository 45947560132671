export default [
  {
    label: "User ID",
    field: "id_usuario",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Username",
    field: "nombre_usuario",
    sort: "asc",
    minimal: "size-col-20",
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Type",
    field: "permisos",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "State",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-20 ",
  },
];
