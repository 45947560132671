import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { withRouter } from "react-router-dom";

import apiAdmin from "../helpers/apiAdmin";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";

class TopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      logout: false,
      user_name: "",
    };
    this.handleToggleClickA = this.handleToggleClickA.bind(this);
  }

  async componentDidMount() {
    try {
      // const a = await apiAdmin("user_sms_service", "application/json");
      // // console.log("user content",a);
      // this.setState({
      //   user_name: a.data.data.user.nombre_usuario,
      // });
    } catch (e) {}
  }

  handleToggleClickA() {
    this.props.onSideNavToggleClick();
  }

  render() {
    const navStyle = {
      paddingLeft: this.props.toggle ? "16px" : "240px",
      transition: "padding-left .3s",
    };

    return (
      <Router>
        <MDBNavbar
          className="flexible-MDBNavbar"
          light
          expand="md"
          scrolling
          fixed="top"
          style={{ zIndex: 3 }}
        >
          <div
            onClick={this.handleToggleClickA}
            key="sideNavToggleA"
            style={{
              lineHeight: "32px",
              marginleft: "1em",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="bars" color="white" size="lg" />
          </div>

          <MDBNavbarBrand href="/" style={navStyle}>
            <strong>{this.props.routeName}</strong>
          </MDBNavbarBrand>
          <MDBNavbarNav expand="sm" right style={{ flexDirection: "row" }}>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon="user" />{" "}
                <span className="d-none d-md-inline">Admin</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right style={{ minWidth: "200px" }}>
                <MDBDropdownItem
                  onClick={() => this.props.history.push("/logout")}
                >
                  <MDBIcon icon="user" /> Logout
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarNav>
        </MDBNavbar>
      </Router>
    );
  }
}

export default withRouter(TopNavigation);
