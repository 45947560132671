import React from "react";

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

import "./styleEditConfiguration.css";

class ModalEditConfiguration extends React.Component {
  render() {
    return (
      <MDBModal
        isOpen={this.props.open}
        toggle={this.props.closeModal}
        centered
      >
        <MDBModalHeader toggle={this.props.closeModal}></MDBModalHeader>
        <MDBModalBody className="text-center">
          <p>Are you sure want to save changes?</p>
          <div className="modal-buttons">
            <MDBBtn
              className="button-save"
              onClick={() =>
                this.props.saveChanges(
                  this.props.sectionPage,
                  this.props.dataSection
                )
              }
            >
              Save Changes
            </MDBBtn>
            <MDBBtn className="button-cancel" onClick={this.props.closeModal}>
              Cancel
            </MDBBtn>
          </div>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default ModalEditConfiguration;
