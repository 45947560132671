import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
  MDBSpinner,
  MDBBadge,
  MDBSwitch,
} from "mdbreact";
import { withRouter, Redirect, Route } from "react-router-dom";
import MDBDataTable from "../../source-pack/DataTable/DataTable";
import Side_modal from "../modal_side";
import Form_new_msg from "../form_new_automated_message";
import Butons_bar from "./03-buttons_products";

import Config_table from "../03-products/01-config_table_products";
import api from "../../helpers/api";
import ActiveMessagesProducts from "./10-Active_messages_products";

class Products_list extends React.Component {
  state = {
    redirect: false,
    modals: {
      form: false,
      header: {
        title: "   Create Automate Message",
        color: "color_select",
      },
      component: "aaa", //<Form_new_msg changeStateModal="aaa"/>,
      centered: true,
    },
    open: false,
    title: "",
    hours: 0,
    minutes: 5,
    action: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    time: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "00:05",
    },
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    data: "",
    load_data: false,
  };

  toggle = () => {
    // console.log("click toggle");
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });

    toast.success("Automatic msg saved successfully", {
      closeButton: true,
    });
    // call to api for all data message created
    this.load_data();
  };

  open_a = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  open_modal = (data) => () => {
    // console.log("data from click", data);
    if (data === "new") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "   Create Automate Message",
            color: "color_select",
          },
          component: <Form_new_msg changeStateModal={this.toggle.bind(this)} />,
          centered: true,
        },
      });
    }
    if (data === "new-togle") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Create Automate Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "delete") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_btn-red",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn color="danger" className="color_select">
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" className="color_btn-red">
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  componentDidMount() {
    this.load_data();
  }

  switchChange = (e) => {
    console.log("idusr_tours", e);
  };

  load_data = async () => {
    // console.log("refresh");
    try {
      const res = await api("all_products", "application/json");
      // console.log("query", res);
      // console.log("query1", res.data.data);
      var a = [];
      res.data.data.map(async (val) => {
        let statusSwitchProduct = true;
        if (val.active_message === 1) {
          statusSwitchProduct = true;
        }
        if (val.active_message === 0) {
          statusSwitchProduct = false;
        }
        a.push({
          productCode: `${val.productCode} / ${val.idusr_tours}`,
          productName: val.productName,
          active: (
            <ActiveMessagesProducts
              activeMessage={statusSwitchProduct}
              idusr_tours={val.idusr_tours}
              refresh={this.load_data.bind(this)}
            />
          ),
          // ignore: (
          //   <Fragment>
          //     {val.ignore_template_msg ? (
          //       <MDBBadge pill color="primary">
          //         true
          //       </MDBBadge>
          //     ) : (
          //       <MDBBadge pill color="danger">
          //         false
          //       </MDBBadge>
          //     )}{" "}
          //     /{" "}
          //     {val.ignore_template_bot ? (
          //       <MDBBadge pill color="primary">
          //         true
          //       </MDBBadge>
          //     ) : (
          //       <MDBBadge pill color="danger">
          //         false
          //       </MDBBadge>
          //     )}
          //   </Fragment>
          // ),

          system: val.sistema,
          buttons: (
            <Butons_bar
              id_={val.id_automated}
              refresh={this.load_data.bind(this)}
              data={val}
              update={this.update_product.bind(this)}
            />
          ),
        });
      });

      this.setState({
        data: {
          columns: Config_table,
          rows: a,
        },
        load_data: true,
      });
    } catch (e) {
      // console.log("query error", e);
    }
  };

  update_product = async (id, msg, bot) => {
    const data = {
      idusr_tours: id,
      ignore_template_msg: msg,
      ignore_template_bot: bot,
    };
    const res = await api("update_product", "application/json", data);
    this.load_data();
    // console.log("update result:", data);
  };

  submitHandler = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    event.target.className += " was-validated";
    // console.log("form data", this.state);

    this.selec_type.map((valor) => {
      if (valor !== "time") {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok");
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach");
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
        }
      } else {
      }
    });
    // console.log("query", this.state);
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  getTime = (data) => {
    // console.log("read data");
    if (data !== `0${this.state.hours}:0${this.state.minutes}`) {
      // console.log("dd", data, `${this.state.hours}:${this.state.minutes}`);
      this.setState({
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback_ok",
          data: data,
        },
      });
    }
    // console.log("gettime:", data);
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}
        <Side_modal open={this.state.open} />

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        {this.state.load_data ? (
          <MDBCol lg="12">
            <MDBCard className="text-left">
              <MDBCardHeader className="white-text color_select p-4 text-left">
                <MDBIcon fab icon="product-hunt" /> All Products
              </MDBCardHeader>
              <MDBCardBody>
                <MDBDataTable
                  striped
                  bordered
                  small
                  paging={false}
                  btn
                  maxHeight="300px"
                  className="table_warp"
                  data={this.state.data}
                  fixed
                ></MDBDataTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ) : (
          <MDBRow center style={{ height: "80vh" }}>
            <MDBCol lg="3">
              <MDBSpinner />
            </MDBCol>
          </MDBRow>
        )}
      </MDBRow>
    );
  }
}

export default withRouter(Products_list);
