import React, { Component } from "react";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBSpinner,
} from "mdbreact";
import { Pie } from "react-chartjs-2";
import api from "../../../helpers/api";
import moment from "moment";

class ChartSection1 extends Component {
  state = {
    dataPie: {
      labels: ["Sended", "Received", "Available"],
      datasets: [
        {
          data: [22, 23, 4955],
          backgroundColor: ["#F7464A", "#46BFBD"],
          hoverBackgroundColor: ["#FF5A5E", "#5AD3D1"],
        },
      ],
    },
    loadData: true,
  };

  async componentDidMount() {
    this.setState({
      loadData: true,
    });
    try {
      const query = {
        initial_date: moment().format("YYYY/MM") + "/01",
        finishing_date: moment().format("YYYY/MM") + "/31",
      };
      const a = await api("sms_counter", "application/json", query);
      let counterIn = 0;
      let counterOut = 0;
      let totalSMS = 0;
      a.data.data.map((val) => {
        if (
          val.type === "in" &&
          val.status !== "pending" &&
          val.status !== "inactive"
        ) {
          const jsonSMS = JSON.parse(val.json);
          if (jsonSMS.numSegments) {
            counterIn = counterIn + Number(jsonSMS.numSegments) + 0;
          } else {
            counterIn = counterIn + Number(jsonSMS.NumSegments) + 0;
          }
        }
        totalSMS = Number(val.max_sms);
        if (
          val.type === "out" &&
          val.status !== "pending" &&
          val.status !== "inactive"
        ) {
          const jsonSMS = JSON.parse(val.json);
          if (jsonSMS.numSegments) {
            counterOut = counterOut + Number(jsonSMS.numSegments) + 0;
          } else {
            counterOut = counterOut + Number(jsonSMS.NumSegments) + 0;
          }
        }
      });
      const total = totalSMS - counterIn - counterOut;
      let data_sms = [counterOut, counterIn, total];
      this.setState({
        dataPie: {
          labels: [
            `Sent ${counterOut}`,
            `Received ${counterIn}`,
            `Available ${total}`,
          ],
          datasets: [
            {
              data: data_sms,
              backgroundColor: ["#F7464A", "#46BFBD"],
              hoverBackgroundColor: ["#FF5A5E", "#5AD3D1"],
            },
          ],
        },
        loadData: false,
      });
    } catch (e) {
      // console.log("error", e);
    }
  }

  render() {
    return (
      <React.Fragment>
        <MDBCard className="mb-4">
          <MDBCardHeader className="white-text color_select text-left">
            SMS
          </MDBCardHeader>
          <MDBCardBody>
            {this.state.loadData ? (
              <MDBRow center style={{ height: "10vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            ) : (
              <Pie
                data={this.state.dataPie}
                height={300}
                options={{ responsive: true }}
              />
            )}
          </MDBCardBody>
        </MDBCard>
      </React.Fragment>
    );
  }
}

export default ChartSection1;
