import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCardHeader,
  MDBModalBody,
  MDBSpinner,
} from "mdbreact";
import api from "../../../helpers/api";
import moment from "moment";

class AdminCardSection2 extends React.Component {
  state = {
    loadData: false,
    sended: "",
    received: "",
    percentSended: "",
    percentReceived: "",
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({
      loadData: false,
    });
    try {
      const query = {
        initial_date: moment().format("YYYY/MM") + "/01",
        finishing_date: moment().format("YYYY/MM") + "/31",
      };
      const a = await api("sms_counter", "application/json", query);
      // console.log("data",a);
      let counterIn = 0;
      let counterOut = 0;
      let totalSMS = 0;
      a.data.data.map((val) => {
        // console.log("check",val)
        if (
          val.type === "in" &&
          val.status !== "pending" &&
          val.status !== "inactive"
        ) {
          const jsonSMS = JSON.parse(val.json);
          // console.log("data",jsonSMS);
          if (jsonSMS.numSegments) {
            counterIn = counterIn + Number(jsonSMS.numSegments);
          } else {
            counterIn = counterIn + Number(jsonSMS.NumSegments);
          }
        }
        totalSMS = Number(val.max_sms);
        if (
          val.type === "out" &&
          val.status !== "pending" &&
          val.status !== "inactive"
        ) {
          const jsonSMS = JSON.parse(val.json);
          if (jsonSMS.numSegments) {
            counterOut = counterOut + Number(jsonSMS.numSegments);
          } else {
            counterOut = counterOut + Number(jsonSMS.NumSegments);
          }
        }
      });
      // console.log("counter", counterIn, counterOut,totalSMS);
      let percent_sms_in = (counterIn / totalSMS) * 100;
      let percent_sms_out = (counterOut / totalSMS) * 100;
      // console.log("percent",percent_sms_in,percent_sms_out);
      if (!percent_sms_in) {
        percent_sms_in = 0;
      }
      if (!percent_sms_out) {
        percent_sms_out = 0;
      }
      this.setState({
        sended: counterOut,
        received: counterIn,
        percentReceived: percent_sms_in.toFixed(2),
        percentSended: percent_sms_out.toFixed(2),
        loadData: true,
      });
    } catch (e) {
      // console.log("error", e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <MDBCard className="text-left">
          <MDBCardHeader className="white-text color_select text-left">
            Messages sent and received in the last month
          </MDBCardHeader>
          <MDBModalBody>
            {this.state.loadData ? (
              <MDBRow className="mb-4">
                <MDBCol xl="6" md="6" className="mb-3">
                  <MDBCard color="primary-color" className="classic-admin-card">
                    <MDBCardBody>
                      <div className="float-right">
                        <MDBIcon far icon="envelope" />
                      </div>
                      <p className="white-text">SENT</p>
                      <h4>
                        <strong>{this.state.sended}</strong>
                      </h4>
                    </MDBCardBody>
                    <div className="progress">
                      <div
                        aria-valuemax="100"
                        aria-valuemin="0"
                        aria-valuenow="25"
                        className="progress-bar bg grey darken-3"
                        role="progressbar"
                        style={{ width: `${this.state.percentSended}%` }}
                      ></div>
                    </div>
                    <MDBCardBody>
                      <p>Percent ({this.state.percentSended}%)</p>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol xl="6" md="6" className="mb-3">
                  <MDBCard color="warning-color" className="classic-admin-card">
                    <MDBCardBody>
                      <div className="float-right">
                        <MDBIcon far icon="envelope-open" />
                      </div>
                      <p className="white-text">RECEIVED</p>
                      <h4>
                        <strong>{this.state.received}</strong>
                      </h4>
                    </MDBCardBody>
                    <div className="progress">
                      <div
                        aria-valuemax="100"
                        aria-valuemin="0"
                        aria-valuenow="25"
                        className="progress-bar bg grey darken-3"
                        role="progressbar"
                        style={{ width: `${this.state.percentReceived}%` }}
                      ></div>
                    </div>
                    <MDBCardBody>
                      <p>Percent ({this.state.percentReceived}%)</p>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow center style={{ height: "10vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            )}
          </MDBModalBody>
        </MDBCard>
      </React.Fragment>
    );
  }
}

export default AdminCardSection2;
