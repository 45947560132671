import React from "react";

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBDatePicker,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";

import moment from "moment";

class ModalFilter extends React.Component {
  state = {
    initialDate: moment().subtract(60, "days").format("YYYY-MM-DD"), //"01/01/2021",
    finishingDate: moment().format("YYYY-MM-DD"),
    disableButton: false,
  };

  render() {
    return (
      <MDBModal isOpen={this.props.modalFilter} toggle={this.props.closeModal}>
        <MDBModalHeader toggle={this.props.closeModal}>
          Filter by Interaction Date
        </MDBModalHeader>
        <MDBModalBody>
          <MDBCard>
            <MDBCardHeader className="white-text color_select p-2 text-left">
              Date of Interaction
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow className="espace">
                <MDBCol md="6">
                  <MDBDatePicker
                    value={this.state.initialDate}
                    getValue={(val) => {
                      let initialDateSelect = moment(val);
                      let finishingDateSelect = moment(
                        this.state.finishingDate
                      );
                      const dateDifference = finishingDateSelect.diff(
                        initialDateSelect,
                        "days"
                      );
                      if (dateDifference > 60) {
                        this.setState({
                          initialDate: moment(val).format("YYYY-MM-DD"),
                          disableButton: true,
                        });
                      } else {
                        this.setState({
                          initialDate: moment(val).format("YYYY-MM-DD"),
                          disableButton: false,
                        });
                      }
                    }}
                    format="YYYY-MM-DD"
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBDatePicker
                    value={this.state.finishingDate}
                    getValue={async (val) => {
                      let initialDateSelect = moment(this.state.initialDate);
                      let finishingDateSelect = moment(val);
                      const dateDifference = finishingDateSelect.diff(
                        initialDateSelect,
                        "days"
                      );
                      if (dateDifference > 60) {
                        this.setState({
                          finishingDate: moment(val).format("YYYY-MM-DD"),
                          disableButton: true,
                        });
                      } else {
                        this.setState({
                          finishingDate: moment(val).format("YYYY-MM-DD"),
                          disableButton: false,
                        });
                      }
                    }}
                    format="YYYY-MM-DD"
                  />
                </MDBCol>
              </MDBRow>
              {this.state.disableButton && (
                <p
                  className="font-italic text-sm-center"
                  style={{ fontSize: "small" }}
                >
                  Alert: You will not be able to a range of dates longer than 60
                  days
                </p>
              )}
            </MDBCardBody>
          </MDBCard>
          <br />

          <br />
          <div className={this.props.messageFilter}>Invalid filter</div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={this.props.closeModal}>
            Close
          </MDBBtn>
          <MDBBtn
            color="infoa"
            className="color_btn-g"
            disabled={this.state.disableButton}
            onClick={() => {
              this.props.dataFilter(this.state);
            }}
          >
            Filter
            {/* Filter */}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

export default ModalFilter;
