import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBContainer,
  MDBAlert,
  MDBIframe,
} from "mdbreact";

import imageCompression from "browser-image-compression";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import path from "path";
import api from "../../helpers/api";

class New_automated_msg extends React.Component {
  constructor(props) {
    super(props);
    // console.log("properties:",this.props.data_edit.location.split(","));
    const html = JSON.parse(this.props.data_edit.message);
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        locacion: "",
        horizontal: {
          state: false,
          file: undefined,
        },
        vertical: {
          state: false,
          file: undefined,
        },
        id_automated: this.props.id_,
        map: "http://maps.google.com/maps?q=-0.230788,-78.5234213&z=15&output=embed",
        latitude: this.props.data_edit.location.split(",")[0],
        longitude: this.props.data_edit.location.split(",")[1],
        showmap: false,
        attached: true,
        file: undefined,
        start_compresion: false,
        progress_compresion: 0,
        ext: "",
        html_msg: "",
        valid_html_msg: true,
        incompatible: false,
        max_size_file: "3000000",
        max_size: false,
        alert: {
          show: false,
          msg: "",
        },
        save: false,
        saving: false,
        command_data: "",
        command_data_location: "",
        command_data_original: "",
        options: this.props.new_options_,
        title: "",
        number_time: this.props.data_edit.qty,
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: this.props.data_edit.time,
        },
        modal: false,
        postcard: false,
      };
    }
  }

  selec_type = ["time"];

  options = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "After booking",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "Before activity",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "During activity",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "4",
        text: "After activity",
      },
    ],
  };

  type = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "message",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "location",
      },
    ],
  };

  topic = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "@firstmsg",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "@recomendations",
      },
    ],
  };

  async componentDidMount() {
    try {
      const a = await api("userdata_commands", "application/json");

      var data_command = [];
      var location_command_data = [];
      var count = 0;
      var data_ = a.data.data.commands.map((value, index) => {
        // console.log("data value",value.tema);
        data_command.push({
          checked: false,
          disabled: false,
          icon: null,
          value: `${index + 1}`,
          text: value.tema,
        });
        //command_data_location
        if (
          value.tema !== "@postcard" &&
          value.tema !== "@firstmsg" &&
          value.tema !== "@review" &&
          value.tema !== "@includes"
        ) {
          count++;
          location_command_data.push({
            checked: false,
            disabled: false,
            icon: null,
            value: `${count}`,
            text: value.tema,
          });
        }
      });
      this.setState({
        command_data: {
          options: data_command,
        },
        command_data_location: {
          options: location_command_data,
        },
        command_data_original: {
          options: data_command,
        },
      });
      // console.log("command data",data_command);
      // console.log("command_location data",this.state.command_data_location);
      // console.log(this.state.command_data)
    } catch (e) {
      // console.log("data load error",e);
    }
  }

  toggle = () => {
    // console.log("click toggle")
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.saving === false) {
      // console.log("puede validar no esta enviando");
      const msg_html = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const ht = msg_html.trim();
      // console.log("validation_html:",ht);
      if (ht === "<p></p>") {
        this.setState({
          valid_html_msg: false,
        });
      }
      this.setState({
        html_msg: msg_html,
      });
      event.target.className += " was-validated";
      // console.log("initial validation");
      var a = await this.select_box();
      // console.log("validation-a:",a);
      var b = await this.input_fields();
      // console.log("validation-b:",b);
      var c = await this.validate_location();
      // console.log("validation-c:",c);
      // console.log("validations:",a,b,c)

      if (a && b && c) {
        // console.log("all validated-send api");
        const datos = {
          time: this.state.time.data,
          number_time: this.state.number_time,
          location: `${this.state.latitude},${this.state.longitude}`,
          message: msg_html,
        };
        this.send_api(datos);
      }
    } else {
      // console.log("no puede validar esta enviando")
    }
  };

  input_fields = () => {
    const ht = this.state.html_msg.trim();
    return new Promise((resolve, reject) => {
      if (this.state.number_time !== "" && ht !== "<p></p>") {
        // console.log("validated");
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      }
    });
  };

  validate_location = () => {
    // console.log("location data:",this.state.horizontal,this.state.vertical,)
    return new Promise((resolve, reject) => {
      if (this.state.latitude !== "" && this.state.longitude !== "") {
        // console.log("validated location");
        resolve(true);
      }
    });
  };

  select_box = () => {
    // console.log("before promise")
    return new Promise((resolve, reject) => {
      // console.log("run")
      var b = false;
      this.selec_type.map((valor, index) => {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok")
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach")
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
          if (index === 0) {
            // console.log("select_box validated")
            resolve(true);
          }
        }
      });
    });
  };

  send_api = async (data) => {
    // console.log("send api:",data);
    this.setState({
      save: true,
      saving: true,
    });
    const formData = new FormData();
    formData.append("id_automated", this.state.id_automated);
    formData.append("time", data.time);
    formData.append("number_time", data.number_time);
    formData.append("message", data.message);
    formData.append("location", data.location);

    // console.log("here edit location",data);

    try {
      const res = await api(
        "edit_automated_msg_location",
        "multipart/form-data",
        formData
      );
      // console.log("response:",res.data);
      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        // this.props.changeStateModal();
        this.props.refresh_();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("query error",e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
    // console.log("select",source,data)
  };

  setSelect_type = (source, data) => {
    // console.log("data type:",source,data);
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
    if (data === "message") {
      this.setState({
        command_data: this.state.command_data_original,
        attached: true,
        showmap: false,
      });
      // console.log("load data:",this.state.command_data_original);
    }
    if (data === "location") {
      this.setState({
        command_data: this.state.command_data_location,
        attached: false,
        showmap: true,
      });
      // console.log("load data location:",this.state.attached);
    }
  };

  setSelect_command = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });

    if (this.state.type.data === "message" && data === "@postcard") {
      this.setState({
        attached: false,
        postcard: true,
      });
      // console.log("load data message + postcard:",this.state.command_data_location);
    }

    if (
      (this.state.type.data === "message" && data === "@firstmsg") ||
      (this.state.type.data === "message" && data === "@recomendations") ||
      (this.state.type.data === "message" && data === "@attractive") ||
      (this.state.type.data === "message" && data === "@reminder") ||
      (this.state.type.data === "message" && data === "@includes") ||
      (this.state.type.data === "message" && data === "@review") ||
      (this.state.type.data === "message" && data === "@notheme")
    ) {
      this.setState({
        attached: true,
        postcard: false,
      });
      // console.log("load data location:",this.state.command_data_original);
    }
  };

  progress = (a) => {
    // console.log("progress",a)
    this.setState({
      progress_compresion: a,
    });
    if (a === 100) {
      this.setState({
        progress_compresion: 0,
        start_compresion: false,
      });
      // console.log("finished compress")
    }
  };

  fileInputHandler = async (e, origin) => {
    // console.log("file", e,origin);

    if (e[0] !== undefined) {
      if (e[0].size <= this.state.max_size_file) {
        const ext = path.extname(e[0].name);
        if (
          ext === ".jpg" ||
          ext === ".jpeg" ||
          ext === ".png" ||
          ext === ".gif" ||
          ext === ".mp4" ||
          ext === ".pdf"
        ) {
          // console.log(e[0]);
          if (e[0].type === "image/png") {
            this.setState({
              start_compresion: true,
            });
            const options = {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
              onProgress: (a) => this.progress(a),
            };
            const compress_file_a = await imageCompression(e[0], options);

            this.setState({
              file: compress_file_a,
              ext: "jpg",
            });
          } else {
            this.setState({
              file: e[0],
            });
          }
          this.setState({
            incompatible: false,
          });
        } else {
          // console.log("incompatible file");
          this.setState({
            incompatible: true,
          });
        }
        this.setState({
          max_size: false,
        });
      } else {
        /* file max exceed*/
        // console.log("max size file");
        this.setState({
          max_size: true,
        });
      }
    }
  };

  fileInputHandler_postcard = (e, origin) => {
    // console.log(e,origin);
    if (e[0] !== undefined) {
      var img = document.createElement("img");
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      var a = reader.readAsDataURL(e[0]);
      img.onload = () =>
        this.update_state_postal(img.width, img.height, e, origin);
    } else {
      // console.log("can't empty");
    }
  };

  update_state_postal = (width, height, e, origin) => {
    // console.log("dimentions",width,height,e);
    var origin_ =
      origin === "horizontal"
        ? {
            h: 1920,
            v: 1080,
          }
        : {
            h: 1080,
            v: 1920,
          };

    if (width === origin_.h && height === origin_.v) {
      // console.log("valide size")
      this.setState({
        [origin]: {
          state: true,
          file: e[0],
        },
      });
    } else {
      // console.log("invalide  size");
      this.setState({
        [origin]: {
          state: false,
          file: e[0],
        },
      });
    }

    // console.log("state:",this.state.horizontal, this.state.vertical)
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const msg_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const ht = msg_html.trim();
    //  console.log("change editor html",ht);
    if (ht !== "<p></p>") {
      this.setState({
        valid_html_msg: true,
      });
    }
  };

  update_map = () => {
    // console.log("mapa state")
    this.setState({
      map: `http://maps.google.com/maps?q=${this.state.latitude},${this.state.longitude}&z=15&output=embed`,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <form onSubmit={this.submitHandler} noValidate className="text-left">
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}
        <div className="md-form text-center">
          <h6>
            Title:{this.props.data_edit.title} / Command:
            {this.props.data_edit.command}
          </h6>
          <h6>
            Type: {this.props.data_edit.type} / Moment:
            {this.props.data_edit.moment}
          </h6>
        </div>

        <MDBRow className="espace">
          <MDBCol md="6">
            <div className="md-form">
              <MDBSelect
                options={this.state.options}
                color="primary"
                label="Time"
                name="time"
                className="text_1"
                getTextContent={this.setSelect.bind(this, "time")}
              />

              <div className={this.state.time.class_novalid}>
                Please select unity.
              </div>
            </div>
          </MDBCol>
          <MDBCol md="6">
            <div className="md-form">
              <MDBInput
                label="Qty"
                type="number"
                name="number_time"
                value={this.state.number_time}
                onChange={this.changeHandler}
                required
              >
                <div className="invalid-feedback">Insert time</div>
              </MDBInput>
            </div>
          </MDBCol>
        </MDBRow>

        <Fragment>
          <MDBRow>
            <MDBCol md="4">
              <div className="md-form">
                <MDBInput
                  value={this.state.latitude}
                  onChange={this.changeHandler}
                  type="text"
                  name="latitude"
                  label="Latitude"
                  required
                >
                  <div className="invalid-feedback">Latitude</div>
                </MDBInput>
              </div>
            </MDBCol>
            <MDBCol md="4">
              <div className="md-form">
                <MDBInput
                  value={this.state.longitude}
                  onChange={this.changeHandler}
                  type="text"
                  name="longitude"
                  label="Longitude"
                  required
                >
                  <div className="invalid-feedback">Longitude</div>
                </MDBInput>
              </div>
            </MDBCol>
            <MDBCol md="4">
              <MDBBtn
                color="deep-orange"
                type="button"
                onClick={this.update_map}
              >
                Update
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          <MDBContainer className="text-center">
            <MDBIframe src={this.state.map}></MDBIframe>
          </MDBContainer>
        </Fragment>

        <div className="md-form">
          <Editor
            handlePastedText={() => false}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: {
                options: ["bold", "italic", "underline", "monospace"],
              },
              list: {
                options: [],
              },
              textAlign: { options: [] },
              link: { options: [] },
              blockType: {
                inDropdown: true,
                options: [],
                className: "ocultar",
              },
              fontSize: {
                options: [],
                className: "ocultar",
              },
              fontFamily: {
                className: "ocultar",
              },
              embedded: {
                className: "ocultar",
              },
              image: {
                className: "ocultar",
              },
              remove: {
                className: "ocultar",
              },
              colorPicker: {
                popupClassName: "popup",
                className: "ocultar",
                colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
              },
            }}
          />
          {this.state.valid_html_msg ? (
            ""
          ) : (
            <div className="novalidate_html">Insert a body message</div>
          )}
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default New_automated_msg;
