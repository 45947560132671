import React from "react";

import countriesJson from "./countries_zones.json";
import api from "../../helpers/api";

import { MDBBtn, MDBSelect } from "mdbreact";

class UserSettings extends React.Component {
  state = {
    // alert: {
    //   show: false,
    // },
    timeZone: [],
    countriesAllowed: [],
    countriesAllowedWithoutOk: [],
    // loaddata: false,
    countriesAllowedSelected: [],
    countriesAllowedWithoutOkSelected: [],
    timeZoneSelected: null,
    countriesDefault: ["us", "ca"],
  };

  // async componentDidMount() {
  //   await this.loadData(this.props.userInformation);
  // }

  async componentDidMount() {
    try {
      // this.setState({
      //   loadData: false,
      // });
      const userInformation = this.props.userInformation;

      const countries = Object.keys(countriesJson.countries);

      const timeZones = await this.getTimeZone();

      const countriesAllowed = this.validUserCountries(
        userInformation.countries_allowed
      );

      const countriesAllowedWithoutOk = this.validUserCountries(
        userInformation.countries_allowed_without_ok
      );

      const userTimeZone = this.validUserTimeZone(
        userInformation.time_zone_location
      );

      const countriesArray = this.buildCountries(
        countries,
        countriesAllowed,
        this.state.countriesDefault
      );

      const countriesWithoutOkArray = this.buildCountriesOk(
        countries,
        countriesAllowedWithoutOk
      );

      const timeZoneArray = this.buildTimeZone(timeZones, userTimeZone);

      this.setState({
        timeZone: timeZoneArray,
        countriesAllowed: countriesArray,
        countriesAllowedWithoutOk: countriesWithoutOkArray,
        // loadData: true,
      });
    } catch (e) {
      console.error("error - componentDidMount", e, e.stack);
    }
  }

  // loadData = async (userData) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const getAllData = await this.getData(userData);
  //       const validAllData = await this.validData(getAllData);
  //       const buildAllData = await this.buildData(validAllData);
  //       const saveAllData = await this.saveData(buildAllData);
  //       resolve(true);
  //     } catch (e) {
  //       reject(false);
  //     }
  //   });
  // };

  // getData = (userData) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const countries = await this.getCountries();
  //       if (countries.statusError) {
  //         throw countries.errorMessage;
  //       }

  //       const timeZones = await this.getTimeZone();
  //       if (timeZones.statusError) {
  //         throw timeZones.errorMessage;
  //       }

  //       const userCountries = await this.getUserCountries(userData);
  //       if (userCountries.statusError) {
  //         throw userCountries.errorMessage;
  //       }

  //       const userTimeZone = await this.getUserTimeZone(userData);
  //       if (userTimeZone.statusError) {
  //         throw userTimeZone.errorMessage;
  //       }

  //       resolve({
  //         countries: countries.countries,
  //         timeZone: timeZones.timeZones,
  //         userCountriesAllowed: userCountries.countriesAllowed,
  //         userCountriesOk: userCountries.countriesAllowedWithoutOk,
  //         userTimeZone: userTimeZone.userTimeZone,
  //         statusError: false,
  //       });
  //     } catch (e) {
  //       console.error("error - getData function", e);
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // getCountries = () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const countries = Object.keys(countriesJson.countries);

  //       resolve({ countries, statusError: false });
  //     } catch (e) {
  //       console.error("error - getCountries function", e);
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // getTimeZone = () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const resTimeZones = await api(
  //         "userSettings/getTimeZone",
  //         "application/json"
  //       );

  //       let timeZones = [];
  //       resTimeZones.data.data.map((val) => {
  //         timeZones.push(val.timezone);
  //       });

  //       resolve({ timeZones, statusError: false });
  //     } catch (e) {
  //       console.error("error - getTimeZone function", e);
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  getTimeZone = async () => {
    const resTimeZones = await api(
      "userSettings/getTimeZone",
      "application/json"
    );

    const timeZones = resTimeZones.data.data.map((val) => {
      return val.timezone;
    });
    return timeZones;
  };

  // getUserCountries = (userData) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const countriesAllowed = userData.countries_allowed;
  //       const countriesAllowedWithoutOk = userData.countries_allowed_without_ok;
  //       resolve({
  //         countriesAllowed,
  //         countriesAllowedWithoutOk,
  //         statusError: false,
  //       });
  //     } catch (e) {
  //       console.error("error - getUserCountries function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // getUserTimeZone = (userData) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const userTimeZone = userData.time_zone_location;
  //       resolve({ userTimeZone, statusError: false });
  //     } catch (e) {
  //       console.error("error - getUserTimeZone function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // validData = (dataToValid) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const validatedUserCountriesAllowed =
  //         await this.validUserCountriesAllowed(
  //           dataToValid.userCountriesAllowed
  //         );
  //       if (validatedUserCountriesAllowed.statusError) {
  //         throw validatedUserCountriesAllowed.errorMessage;
  //       }

  //       const validatedUserCountriesok = await this.validUserCountriesOk(
  //         dataToValid.userCountriesOk
  //       );
  //       if (validatedUserCountriesok.statusError) {
  //         throw validatedUserCountriesok.errorMessage;
  //       }

  //       const validatedUserTimeZone = await this.validUserTimeZone(
  //         dataToValid.userTimeZone
  //       );
  //       if (validatedUserTimeZone.statusError) {
  //         throw validatedUserTimeZone.errorMessage;
  //       }

  //       resolve({
  //         countries: dataToValid.countries,
  //         timeZone: dataToValid.timeZone,
  //         userCountriesAllowed:
  //           validatedUserCountriesAllowed.userCountriesAllowedValid,
  //         userCountriesOk: validatedUserCountriesok.userCountriesOkValid,
  //         userTimeZone: validatedUserTimeZone.userTimeZoneValid,
  //         statusError: false,
  //       });
  //     } catch (e) {
  //       console.error("Error - validData function", e);
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // validUserCountriesAllowed = (userCountriesAllowed) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let userCountriesAllowedValid = [];
  //       if (userCountriesAllowed !== null) {
  //         userCountriesAllowedValid = userCountriesAllowed.split(",");
  //       }
  //       resolve({ userCountriesAllowedValid, statusError: false });
  //     } catch (e) {
  //       console.error("Error - validUserCountriesAllowed function ");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  validUserCountries = (userCountries) => {
    let userCountriesValid = [];
    if (userCountries !== null) {
      userCountriesValid = userCountries.split(",");
    }
    return userCountriesValid;
  };

  // validUserCountriesOk = (userCountriesOk) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let userCountriesOkValid = [];
  //       if (userCountriesOk !== null) {
  //         userCountriesOkValid = userCountriesOk.split(",");
  //       }
  //       resolve({ userCountriesOkValid, statusError: false });
  //     } catch (e) {
  //       console.error("Error - validData function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // validUserTimeZone = (userTimeZone) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let userTimeZoneValid = [];
  //       if (userTimeZone !== null) {
  //         userTimeZoneValid.push(userTimeZone);
  //       }
  //       resolve({ userTimeZoneValid, errorMessage: false });
  //     } catch (e) {
  //       console.error("Error - validUserTimeZone function");
  //       reject({ errorMessage: true, errorMessage: e });
  //     }
  //   });
  // };

  validUserTimeZone = (userTimeZone) => {
    let userTimeZoneValid = [];
    if (userTimeZone !== null) {
      userTimeZoneValid.push(userTimeZone);
    }
    return userTimeZoneValid;
  };

  // buildData = (dataToBuild) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const builtCountries = await this.buildCountries(
  //         dataToBuild.countries,
  //         dataToBuild.userCountriesAllowed
  //       );
  //       if (builtCountries.statusError) {
  //         throw builtCountries.errorMessage;
  //       }

  //       const builtCountriesOk = await this.buildCountriesOk(
  //         dataToBuild.countries,
  //         dataToBuild.userCountriesOk
  //       );
  //       if (builtCountriesOk.statusError) {
  //         throw builtCountriesOk.errorMessage;
  //       }

  //       const builtTimeZone = await this.buildTimeZone(
  //         dataToBuild.timeZone,
  //         dataToBuild.userTimeZone
  //       );
  //       if (builtTimeZone.statusError) {
  //         throw builtTimeZone.errorMessage;
  //       }
  //       resolve({
  //         countries: builtCountries.buildCountriesData,
  //         countriesOk: builtCountriesOk.buildCountriesDataOk,
  //         timeZone: builtTimeZone.buildTimeZone,
  //         statusError: false,
  //       });
  //     } catch (e) {
  //       console.error("Error - buildData function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  // buildCountries = (allCountries, userCountries) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let buildCountriesData = [];
  //       allCountries.map((val, index) => {
  //         let dataSelect = false;
  //         let dataDisabled = false;

  //         userCountries.map((val1) => {
  //           if (val1.toUpperCase() === val) {
  //             dataSelect = true;
  //           }
  //         });

  //         this.state.countriesDefault.map((countryDefault) => {
  //           if (countryDefault.toUpperCase() === val) {
  //             dataDisabled = true;
  //           }
  //         });
  //         if (dataSelect && !dataDisabled) {
  //           buildCountriesData.push({
  //             checked: true,
  //             disabled: false,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         } else if (dataSelect && dataDisabled) {
  //           buildCountriesData.push({
  //             checked: true,
  //             disabled: true,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         } else {
  //           buildCountriesData.push({
  //             checked: false,
  //             disabled: false,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         }
  //       });

  //       resolve({ buildCountriesData, statusError: false });
  //     } catch (e) {
  //       console.error("Error - buildCountries function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  buildCountries = (allCountries, userCountries, countriesDefault) => {
    const buildCountriesData = allCountries.map((country, index) => {
      const checkedCountry = userCountries.some(
        (userCountry) => userCountry.toUpperCase() === country
      );

      const disabledCountry = countriesDefault.some(
        (countryDefault) => countryDefault.toUpperCase() === country
      );

      return {
        checked: checkedCountry,
        disabled: disabledCountry,
        icon: null,
        value: `${index + 1}`,
        text: country,
      };
    });
    return buildCountriesData;
  };

  // buildCountriesOk = (allCountries, userCountriesOk) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let buildCountriesDataOk = [];
  //       allCountries.map((val, index) => {
  //         let dataSelect = false;
  //         userCountriesOk.map((val1) => {
  //           if (val1.toUpperCase() === val) {
  //             dataSelect = true;
  //           }
  //         });
  //         if (dataSelect) {
  //           buildCountriesDataOk.push({
  //             checked: true,
  //             disabled: false,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         } else {
  //           buildCountriesDataOk.push({
  //             checked: false,
  //             disabled: false,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         }
  //       });
  //       resolve({ buildCountriesDataOk, statusError: false });
  //     } catch (e) {
  //       console.error("Error - buildCountriesOk function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  buildCountriesOk = (allCountries, userCountriesOk) => {
    const buildCountriesDataOk = allCountries.map((country, index) => {
      const checkedCountry = userCountriesOk.some(
        (userCountry) => userCountry.toUpperCase() === country
      );
      return {
        checked: checkedCountry,
        disabled: false,
        icon: null,
        value: `${index + 1}`,
        text: country,
      };
    });
    return buildCountriesDataOk;
  };

  // buildTimeZone = (allTimeZone, userTimeZone) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let buildTimeZone = [];
  //       allTimeZone.map((val, index) => {
  //         let dataSelect = false;
  //         userTimeZone.map((val1) => {
  //           if (val === val1) {
  //             dataSelect = true;
  //           }
  //         });
  //         if (dataSelect) {
  //           buildTimeZone.push({
  //             checked: true,
  //             disabled: false,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         } else {
  //           buildTimeZone.push({
  //             checked: false,
  //             disabled: false,
  //             icon: null,
  //             value: `${index + 1}`,
  //             text: val,
  //           });
  //         }
  //       });
  //       resolve({ buildTimeZone, statusError: false });
  //     } catch (e) {
  //       console.error("Error - buildTimeZone function", e);
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  buildTimeZone = (allTimeZone, userTimeZone) => {
    const buildTimeZone = allTimeZone.map((timeZone, index) => {
      const checkedTimeZOne = userTimeZone.some(
        (userTimeZone) => timeZone === userTimeZone
      );
      return {
        checked: checkedTimeZOne,
        disabled: false,
        icon: null,
        value: `${index + 1}`,
        text: timeZone,
      };
    });
    return buildTimeZone;
  };

  // saveData = (dataToSave) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       this.setState({
  //         timeZone: dataToSave.timeZone,
  //         countriesAllowed: dataToSave.countries,
  //         countriesAllowedWithoutOk: dataToSave.countriesOk,
  //         loadData: true,
  //       });
  //       resolve({ statusError: false });
  //     } catch (e) {
  //       console.error("Error - saveData function");
  //       reject({ statusError: true, errorMessage: e });
  //     }
  //   });
  // };

  setSelectTimeZone = (timeZone) => {
    if (timeZone !== "" && timeZone !== undefined && timeZone !== null) {
      this.setState({
        timeZoneSelected: timeZone,
      });
    }
  };

  setSelectCountries = (countries) => {
    this.setState({
      countriesAllowedSelected: countries,
    });
  };

  setSelectCountriesOk = (countries) => {
    this.setState({
      countriesAllowedWithoutOkSelected: countries,
    });
  };

  submitHandler = async (event) => {
    try {
      event.preventDefault();
      const dataToSend = this.buildDataToSend();
      const saveData = await this.sendApi(dataToSend);
      if (saveData === "ok") {
        this.props.refresh();
      }
    } catch (e) {
      console.log("error - submitHandler :>> ", e, e.stack);
    }
  };

  buildDataToSend = () => {
    let countriesAllowedSelectedToSend = "";
    if (
      this.state.countriesAllowedSelected.length > 0 &&
      this.state.countriesAllowedSelected !== null
    ) {
      let countriesAllowedSelected =
        this.state.countriesAllowedSelected.split(", ");
      countriesAllowedSelected = countriesAllowedSelected.join(",");
      countriesAllowedSelectedToSend = countriesAllowedSelected.toLowerCase();
    }

    let countriesAllowedWithoutOkSelectedToSend = null;
    if (
      this.state.countriesAllowedWithoutOkSelected.length > 0 &&
      this.state.countriesAllowedWithoutOkSelected !== null
    ) {
      let countriesAllowedWithoutOkSelected =
        this.state.countriesAllowedWithoutOkSelected.split(", ");
      countriesAllowedWithoutOkSelected =
        countriesAllowedWithoutOkSelected.join(",");
      countriesAllowedWithoutOkSelectedToSend =
        countriesAllowedWithoutOkSelected.toLowerCase();
    }

    const timeZoneSelected = this.state.timeZoneSelected;

    return {
      countriesAllowed: countriesAllowedSelectedToSend,
      countriesAllowedWithoutOk: countriesAllowedWithoutOkSelectedToSend,
      timeZone: timeZoneSelected,
      statusError: false,
    };
  };

  sendApi = async (dataToSend) => {
    const res = await api(
      "userSettings/editCountriesTimeZone",
      "application/json",
      {
        countriesAllowed: dataToSend.countriesAllowed,
        countriesAllowedWithoutOk: dataToSend.countriesAllowedWithoutOk,
        timeZone: dataToSend.timeZone,
        userId: this.props.userInformation.id_usuario,
      }
    );
    return res.data.status;
  };

  render() {
    return (
      <form
        // className="needs-validation"
        onSubmit={this.submitHandler}
        noValidate
        className="text-left"
      >
        {/* {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )} */}

        <div className="md-form scrollbar_booking-systems">
          <MDBSelect
            search
            name="timeZone"
            options={this.state.timeZone}
            label="Time Zone"
            getTextContent={(timeZone) => this.setSelectTimeZone(timeZone)}
            color="primary"
          ></MDBSelect>

          {/* <div className={this.state.bookingsSystems.class_novalid}>
            select booking system.
          </div> */}
        </div>

        <div className="md-form scrollbar_booking-systems">
          <MDBSelect
            search
            multiple
            name="countriesAllowed"
            options={this.state.countriesAllowed}
            label="Countries Allowed"
            getTextContent={(countries) => this.setSelectCountries(countries)}
            color="primary"
          ></MDBSelect>

          {/* <div className={this.state.bookingsSystems.class_novalid}>
            select booking system.
          </div> */}
        </div>

        <div className="md-form scrollbar_booking-systems">
          <MDBSelect
            search
            multiple
            name="countriesAllowedWithoutOk"
            options={this.state.countriesAllowedWithoutOk}
            label="Countries Allowed Without Ok"
            getTextContent={(countries) => this.setSelectCountriesOk(countries)}
            color="primary"
          ></MDBSelect>

          {/* <div className={this.state.bookingsSystems.class_novalid}>
            select booking system.
          </div> */}
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default UserSettings;
