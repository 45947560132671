import React from "react";

import { MDBCol, toast, ToastContainer } from "mdbreact";

import "../index.css";
import SideNavPage from "../components/nav_side";
import TopNavigation from "../components/nav_top";
import Status_wp_control from "../components/05-status_wp/00-control_wp";

class StatusWp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: "",
      sideNavToggled: false,
      breakWidth: 1024,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    const SMSUser = localStorage.getItem("SMSStatus");
   /* if (SMSUser === "true") {
      toast.error(
        "You've reached the limit of messages allowed by your plan. Please contact your Account Manager if you want to upgrade",
        {
          position: "bottom-right",
          closeButton: false,
          autoClose: false,
          draggable: false,
          closeOnClick: false,
        }
      );
    }*/
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  toggleSideNav = () => {
    // console.log("click on hamburger button",this.state.sideNavToggled)
    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled,
      });
    }
  };
  render() {
    const dynamicLeftPadding = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0",
    };
    return (
      <React.Fragment>
        <SideNavPage triggerOpening={this.state.sideNavToggled} />

        <div className="flexible-content white-skin">
          <TopNavigation onSideNavToggleClick={this.toggleSideNav} />
          <main style={{ ...dynamicLeftPadding, margin: "5rem 0% 6rem" }}>
            <MDBCol middle="true" size="12" className="text-center">
              <Status_wp_control></Status_wp_control>
            </MDBCol>
          </main>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default StatusWp;
