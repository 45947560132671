import { config as configEnvironment } from "./config/config";

let googleAnalyticsCode = "UA-87306954-3";

// console.log("--->env", process.env);
if (configEnvironment.appEnvironment === "develop") {
  googleAnalyticsCode = "UA-214142998-1";
}

export const config = {
  googleAnalytics: googleAnalyticsCode, // utilizar su google analytics id
};

export default {
  ...config,
};
