import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBAlert,
} from "mdbreact";
import imageCompression from "browser-image-compression";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import path from "path";
import getJWT from "../../../helpers/jwt";
import api from "../../../helpers/api";
import isgsm7 from "isgsm7";

class Add_msg_bot extends React.Component {
  constructor(props) {
    super(props);

    const html = "";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        locacion: "",
        horizontal: {
          state: false,
          file: undefined,
        },
        vertical: {
          state: false,
          file: undefined,
        },
        map: "http://maps.google.com/maps?q=-0.230788,-78.5234213&z=15&output=embed",
        latitude: "",
        longitude: "",
        showmap: false,
        attached: true,
        file: undefined,
        start_compresion: false,
        progress_compresion: 0,
        ext: "",
        html_msg: "",
        valid_html_msg: true,
        incompatible: false,
        max_size_file: "3000000",
        max_size: false,
        alert: {
          show: false,
          msg: "",
        },
        save: false,
        saving: false,
        title: "",
        number_time: "",
        intent: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        followup: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        modal: false,
        postcard: false,
        data_intent: "",
        counterSMS: "0/0",
      };
    }
  }

  selec_type = ["intent", "followup"];

  async componentDidMount() {
    // console.log("product number----", this.props.productID);
    try {
      const a = await api("userdata_commands_bot", "application/json");
      // console.log("api result", a);
      var intent = [];
      var intent_a = [];
      const data_a = a.data.data.commands_bot.map((value) => {
        intent_a.push(value.intent);
      });
      // console.log("filter", intent_a);
      const uniqueIntent = intent_a.filter((val, id, array) => {
        return array.indexOf(val) === id;
      });
      // console.log("filter-2", uniqueIntent);

      var data_ = uniqueIntent.map((value, index) => {
        if (value !== "@postal" && value !== "@postal_thanks") {
          intent.push({
            checked: false,
            disabled: false,
            icon: null,
            value: `${index + 1}`,
            text: value,
          });
        }
      });

      // console.log("here", intent);

      this.setState({
        intent: {
          options: intent,
        },
        data_intent: a.data.data.commands_bot,
      });
    } catch (e) {
      // console.log("load data error", e);
    }
  }

  toggle = () => {
    // console.log("click toggle");
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.saving === false) {
      // console.log("validated");
      const msg_html = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const ht = msg_html.trim();
      // console.log("validation_html:", ht);
      if (ht === "<p></p>") {
        this.setState({
          valid_html_msg: false,
        });
      }
      this.setState({
        html_msg: msg_html,
      });
      event.target.className += " was-validated";

      var a = await this.select_box();
      var b = await this.input_fields();

      if (a && b) {
        // console.log("all validated-send api");
        const datos = {
          title: this.state.title,
          intent: this.state.intent.data,
          followup: this.state.followup.data,
          file: this.state.file,
          message: msg_html,
          product: this.props.productID,
        };
        this.send_api(datos);
      }
    } else {
      // console.log("can't validated-sending");
    }
  };

  input_fields = () => {
    const ht = this.state.html_msg.trim();
    return new Promise((resolve, reject) => {
      if (this.state.title !== "" && ht !== "<p></p>") {
        // console.log("validated");
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      }
    });
  };

  select_box = () => {
    // console.log("before promise");
    return new Promise((resolve, reject) => {
      // console.log("run");
      var b = false;
      this.selec_type.map((valor, index) => {
        // console.log("index:", index);
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("invalidated");
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
              options: this.state[valor].options,
            },
          });
        } else {
          // console.log("validated");
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
              options: this.state[valor].options,
            },
          });
          if (index === 1) {
            // console.log("select_box validated");
            resolve(true);
          }
        }
      });
    });
  };

  send_api = async (data) => {
    // console.log("send api:", data);
    this.setState({
      save: true,
      saving: true,
    });
    const formData = new FormData();

    formData.append("file", data.file);
    formData.append("title", data.title);
    formData.append("intent", data.intent);
    formData.append("followup", data.followup);
    formData.append("message", data.message);
    formData.append("product", this.props.productID);

    // console.log("here", data);

    try {
      const res = await api(
        "add_bot_msg_product",
        "multipart/form-data",
        formData
      );

      // console.log("response:", res.data);
      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        this.props.changeStateModal();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("query error", e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect_intent = (source, data) => {
    // console.log("data:", source, data);
    this.setState({
      intent: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
        options: this.state.intent.options,
      },
    });
    // console.log("select",source,data);
    const followup_ = [];

    const result = this.state.data_intent.map((value) => {
      if (value.intent === data) {
        followup_.push(value.followup);
      }
    });
    const followup_filter = [];
    var data_ = followup_.map((value, index) => {
      followup_filter.push({
        checked: false,
        disabled: false,
        icon: null,
        value: `${index + 1}`,
        text: value,
      });
    });

    this.setState({
      followup: {
        options: followup_filter,
      },
    });
    // console.log("here", followup_);
  };

  setSelect_Followup = (source, data) => {
    this.setState({
      followup: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
        options: this.state.followup.options,
      },
    });
  };

  progress = (a) => {
    // console.log("progress", a);
    this.setState({
      progress_compresion: a,
    });
    if (a === 100) {
      this.setState({
        progress_compresion: 0,
        start_compresion: false,
      });
      // console.log("finished compress");
    }
  };

  fileInputHandler = async (e, origin) => {
    // console.log("file", e, origin);

    if (e[0] !== undefined) {
      if (e[0].size <= this.state.max_size_file) {
        const ext = path.extname(e[0].name);
        if (
          ext === ".jpg" ||
          ext === ".jpeg" ||
          ext === ".png" ||
          ext === ".gif" ||
          ext === ".mp4" ||
          ext === ".pdf"
        ) {
          // console.log(e[0]);
          if (e[0].type === "image/png") {
            this.setState({
              start_compresion: true,
            });
            const options = {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
              onProgress: (a) => this.progress(a),
            };
            const compress_file_a = await imageCompression(e[0], options);

            this.setState({
              file: compress_file_a,
              ext: "jpg",
            });
          } else {
            this.setState({
              file: e[0],
            });
          }
          this.setState({
            incompatible: false,
          });
        } else {
          // console.log("invalide file");
          this.setState({
            incompatible: true,
          });
        }
        this.setState({
          max_size: false,
        });
      } else {
        /* file max exceed*/
        // console.log("max size file");
        this.setState({
          max_size: true,
        });
      }
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const msg_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const ht = msg_html.trim();
    const t = convertToRaw(editorState.getCurrentContent()).blocks;
    let counterCharactersSMS = 0;
    let counter1 = 0;
    let maxSMS = 153;
    t.map((e) => {
      // console.log("datos de text",e.text);
      if (!isgsm7(e.text)) {
        maxSMS = 67;
      }
      if (counter1 > 0) {
        counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
      } else {
        counterCharactersSMS = counterCharactersSMS + e.text.length;
      }
      counter1++;
    });
    // console.log("total",counterCharactersSMS);
    const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
    let characters = counterCharactersSMS + "/" + totalSMS;
    this.setState({
      counterSMS: characters,
    });
    if (ht !== "<p></p>") {
      this.setState({
        valid_html_msg: true,
      });
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <form onSubmit={this.submitHandler} noValidate className="text-left">
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}

        <div className="md-form">
          <MDBInput
            value={this.state.title}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="title"
            label="Title"
            required
          >
            <div className="invalid-feedback">
              Please insert a title for automated message.
            </div>
          </MDBInput>
        </div>

        <MDBRow>
          <MDBCol md="6">
            <div className="md-form">
              <MDBSelect
                name="intent"
                options={this.state.intent.options}
                label="intent"
                getTextContent={this.setSelect_intent.bind(this, "intent")}
                color="primary"
              ></MDBSelect>
              <div className={this.state.intent.class_novalid}>
                select intent
              </div>
            </div>
          </MDBCol>

          <MDBCol md="6">
            <div className="md-form">
              <MDBSelect
                name="followup"
                options={this.state.followup.options} //{this.topic.options}
                label="followup"
                getTextContent={this.setSelect_Followup.bind(this, "followup")}
                color="primary"
              ></MDBSelect>
              <div className={this.state.followup.class_novalid}>
                Select Command
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        <div className="md-form">
          <Editor
            handlePastedText={() => false}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: {
                options: ["bold", "italic", "underline", "monospace"],
              },
              list: {
                options: [],
              },
              textAlign: { options: [] },
              link: { options: [] },
              blockType: {
                inDropdown: true,
                options: [],
                className: "ocultar",
              },
              fontSize: {
                options: [],
                className: "ocultar",
              },
              fontFamily: {
                className: "ocultar",
              },
              embedded: {
                className: "ocultar",
              },
              image: {
                className: "ocultar",
              },
              remove: {
                className: "ocultar",
              },
              colorPicker: {
                popupClassName: "popup",
                className: "ocultar",
                colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
              },
            }}
          />
          <p className="text-right">{this.state.counterSMS}</p>
          {this.state.valid_html_msg ? (
            ""
          ) : (
            <div className="novalidate_html">Insert a body message</div>
          )}
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default Add_msg_bot;
