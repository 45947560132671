import React from "react";

import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import isgsm7 from "isgsm7";
import draftToHtml from "draftjs-to-html";
import api from "../../helpers/api";

import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
} from "mdbreact";
import { TextareaAutosize } from "@material-ui/core";

class ModalNewBroadcastMessage extends React.Component {
  constructor(props) {
    super(props);
    // console.log("cons");
    const html = ""; //'<p>Hey this <strong>editor</strong> rocks 😀</p>';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        broadcastTitle: "",
        broadcastProducts: [],
        saveBroadcastProduct: "",
        broadcastDate: [],
        saveBroadcastDate: "",
        broadcastCustomer: [],
        saveBroadcastPhone: "",
        saveBroadcastMessage: "",
        messageText: "",

        broadcastSave: false,

        dateValidate: "invalid-feedback",
        phoneValidate: "invalid-feedback",
        productValidate: "invalid-feedback",

        queryDataProducts: [],
        queryDateBookings: [],
        phoneNumbersBookingsFilter: [],
        contadorSMS: 0,
        valid_html_msg: true,
      };
    }
  }
  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = async () => {
    const res = await api(
      "read_products_broadcast_message",
      "application/json"
    );
    // console.log("data query products-->", res.data.data);
    let productsData = [];
    res.data.data.map((val, index) => {
      productsData.push({
        text: `${val.productName}`,
        value: `${val.idusr_tours}`,
      });
    });
    this.setState({
      broadcastProducts: productsData,
      queryDataProducts: res.data.data,
    });
  };

  loadTouristBooking = async (idTour) => {
    const data = { idTour: idTour };
    const resDateBookings = await api(
      "read_tourist_booking",
      "application/json",
      data
    );

    let allDateProducts = [];
    resDateBookings.data.data.map((val) => {
      allDateProducts.push(val.hora_humana);
    });

    let uniqueItems = Array.from(new Set(allDateProducts));

    let broadcastDate = [];

    uniqueItems.map((val, index) => {
      broadcastDate.push({
        text: `${val}`,
        value: `${index + 1}`,
      });
    });

    this.setState({
      queryDateBookings: resDateBookings.data.data,
      broadcastDate,
    });
    // console.log("querytourist--->", resDateBookings.data.data);
    // console.log("--->date", allDateProducts);
    // console.log("uniqueItems", uniqueItems);
  };

  handleChange = (e) => {
    // console.log("input", e.target.name, e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // selectDataProduct = (e) => {
  //   const dataQueryProducts = this.state.queryDataProducts;

  //   let selectEvent = false;
  //   dataQueryProducts.map((val) => {
  //     if (val.productName === e) {
  //       selectEvent = true;
  //     }
  //   });

  //   if (selectEvent) {
  //     let idTour = "";
  //     dataQueryProducts.map((val) => {
  //       if (val.productName == e) {
  //         idTour = val.idusr_tours;
  //       }
  //     });
  //     this.setState({
  //       saveBroadcastProduct: e,
  //     });
  //     this.loadTouristBooking(idTour);
  //     // console.log("selectProduct--->", e);
  //   }
  // };

  selectDataProduct = (productId) => {
    if (productId.length) {
      const dataQueryProducts = this.state.queryDataProducts;
      const saveBroadcastProduct = dataQueryProducts.find(
        (data) => data.idusr_tours == parseInt(productId)
      );
      this.setState({
        saveBroadcastProduct: saveBroadcastProduct.productName,
      });
      this.loadTouristBooking(parseInt(productId));
    }
  };

  selectDateBooking = (e) => {
    const queryDateBookings = this.state.queryDateBookings;
    let phoneNumbersBookingsFilter = [];
    let broadcastCustomer = [];

    let selectEventDate = false;
    queryDateBookings.map((val) => {
      if (val.hora_humana === e) {
        selectEventDate = true;
      }
    });

    if (selectEventDate) {
      queryDateBookings.map((val, index) => {
        if (val.hora_humana === e) {
          broadcastCustomer.push({
            text: `${val.telefono_cliente}`,
            value: `${index + 1}`,
          });
          phoneNumbersBookingsFilter.push(val.telefono_cliente);
        }
      });

      this.setState({
        broadcastCustomer,
        phoneNumbersBookingsFilter,
        saveBroadcastDate: e,
      });
    }
  };

  selectphoneNumber = (e) => {
    // console.log("---> select phone", e);
    if (e !== "") {
      // console.log("phone number--->", e);
      const dataReplace = e.replace(/, /g, ",");
      // console.log("data--->", dataReplace);
      const dataSplit = dataReplace.split(",");
      // console.log("split", dataSplit);
      const phone = this.state.phoneNumbersBookingsFilter;
      let selectEventPhone = false;
      // console.log("phone", phone);
      let counter = 0;
      phone.map((val) => {
        let validatePhone = true;
        dataSplit.map((val1) => {
          if (val === val1 && validatePhone) {
            counter++;
            validatePhone = false;
          }
        });
      });
      // console.log("counter", counter, dataSplit.length);

      if (counter === dataSplit.length) {
        selectEventPhone = true;
      }

      if (selectEventPhone) {
        // console.log("if", dataReplace);
        this.setState({
          saveBroadcastPhone: dataReplace,
        });
      }
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const msg_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const ht = msg_html.trim();
    const t = convertToRaw(editorState.getCurrentContent()).blocks;
    // console.log("t--->", t);
    let counterCharactersSMS = 0;
    let counterSalto = 0;
    let maxSMS = 153;
    let textEditorArray = [];
    t.map((e) => {
      textEditorArray.push(e.text);
      if (!isgsm7(e.text)) {
        maxSMS = 67;
      }
      if (counterSalto > 0) {
        counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
      } else {
        counterCharactersSMS = counterCharactersSMS + e.text.length;
      }
      counterSalto++;
    });
    const textEditor = textEditorArray.join("\n");
    // console.log("textEditor--->", textEditor);
    // const letras = msg_html.length;
    const cantSMS = Math.ceil(counterCharactersSMS / maxSMS);
    let characters = counterCharactersSMS + "/" + cantSMS;
    this.setState({
      contadorSMS: characters,
      messageText: textEditor,
    });
    if (ht !== "<p></p>") {
      this.setState({
        valid_html_msg: true,
      });
    }
  };

  verifyData = async (e) => {
    e.preventDefault();
    this.setState({
      broadcastSave: true,
    });

    // const msg_html1 = convertToRaw(this.state.editorState.getCurrentContent());
    // const msg_html = this.state.editorState.getCurrentContent();
    // console.log(msg_html, msg_html1.blocks[0].text.trim());
    // const ht = msg_html.trim();
    // console.log("msg-->", msg_html, ht);
    const msgText = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    let textTable = msgText.replace(/<[^>]*>?/g, "");
    textTable = textTable.replace(/['"]+/g, "");
    textTable = textTable.replace(/&.*;/g, "");
    textTable = textTable.replace(/\\n/g, " ");
    // console.log("msg---->", textTable);
    e.target.className += " was-validated";
    let title_message = await this.title_message_validate();
    let product = await this.product_validate();
    let date = await this.date_validate();
    let phoneNumbers = await this.phoneNumbers_validate();
    let newMessage = await this.message_validate(textTable);

    if (title_message && product && date && phoneNumbers && newMessage) {
      const data = {
        titleBroadcastMessage: this.state.broadcastTitle,
        productBroadcastMessage: this.state.saveBroadcastProduct,
        dateBroadcastMessage: this.state.saveBroadcastDate,
        phoneNumbersBroadcastMessage: this.state.saveBroadcastPhone,
        // messageBroadcastMessage: this.state.saveBroadcastMessage,
        messageBroadcastMessage: JSON.stringify(textTable),
      };
      await this.sendApi(data);
      this.setState({
        broadcastSave: false,
      });
      this.props.closeModal("reload");
    }
    this.setState({
      broadcastSave: false,
    });
  };

  sendApi = async (data) => {
    try {
      const saveBroadcastMessage = await api(
        "save_broadcast_message",
        "application/json",
        data
      );
    } catch (e) {}
  };

  title_message_validate = () => {
    const msg_html = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    // console.log("-->", this.state.messageText);
    const messageText = this.state.messageText;
    return new Promise((resolve, reject) => {
      // if (this.state.broadcastTitle !== "" && msg_html.trim() !== "<p></p>") {
      //   this.setState({
      //     valid_html_msg: true,
      //     saveBroadcastMessage: msg_html,
      //   });
      //   resolve(true);
      // } else {
      //   if (msg_html.trim() === "<p></p>") {
      //     this.setState({
      //       valid_html_msg: false,
      //     });
      //   } else {
      //     this.setState({
      //       valid_html_msg: true,
      //     });
      //   }

      //   resolve(false);
      // }
      if (this.state.broadcastTitle !== "" && messageText !== "") {
        this.setState({
          valid_html_msg: true,
          saveBroadcastMessage: msg_html,
        });
        resolve(true);
      } else {
        if (messageText !== "") {
          this.setState({
            valid_html_msg: false,
          });
        } else {
          this.setState({
            valid_html_msg: true,
          });
        }

        resolve(false);
      }
    });
  };

  product_validate = () => {
    return new Promise((resolve, reject) => {
      if (this.state.saveBroadcastProduct !== "") {
        this.setState({
          productValidate: "invalid-feedback",
        });
        resolve(true);
      } else {
        this.setState({
          productValidate: "novalidate_select",
        });
        resolve(false);
      }
    });
  };

  date_validate = () => {
    return new Promise((resolve, reject) => {
      if (this.state.saveBroadcastDate !== "") {
        this.setState({
          dateValidate: "invalid-feedback",
        });
        resolve(true);
      } else {
        this.setState({
          dateValidate: "novalidate_select",
        });
        resolve(false);
      }
    });
  };

  phoneNumbers_validate = () => {
    return new Promise((resolve, reject) => {
      // console.log("--validate phone", this.state.saveBroadcastPhone);
      if (this.state.saveBroadcastPhone !== "") {
        this.setState({
          phoneValidate: "invalid-feedback",
        });
        resolve(true);
      } else {
        this.setState({
          phoneValidate: "novalidate_select",
        });
        resolve(false);
      }
    });
  };

  message_validate = (text) => {
    return new Promise((resolve, reject) => {
      let textData = JSON.stringify(text);
      textData = textData.replace(/<[^>]*>?/g, "");
      textData = textData.replace(/['"]+/g, "");
      textData = textData.replace(/&.*;/g, "");
      textData = textData.replace(/\\n/g, "");
      // console.log(`---> message${textData}--->`);
      if (textData !== "") {
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      } else {
        this.setState({
          valid_html_msg: false,
        });
        resolve(false);
      }
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <MDBModal
        isOpen={this.props.openModal}
        toggle={this.props.closeModal}
        centered={true}
      >
        <MDBModalHeader
          className="color_select white-text"
          toggle={this.props.closeModal}
          titleClass="w-100"
        >
          Create Broadcast Message
        </MDBModalHeader>

        <MDBModalBody className="text-center">
          <form className="text-left" noValidate onSubmit={this.verifyData}>
            <div className="md-form">
              <MDBInput
                label="Title"
                type="text"
                value={this.state.broadcastTitle || ""}
                onChange={this.handleChange}
                name="broadcastTitle"
                required
              ></MDBInput>
            </div>

            <MDBSelect
              search
              options={this.state.broadcastProducts}
              selected="Choose your option"
              label="Product"
              // getTextContent={this.selectDataProduct}
              getValue={this.selectDataProduct}
              className="scroll_select"
              id="selectProduct"
            ></MDBSelect>
            <div className={this.state.productValidate}>select a product.</div>

            <MDBSelect
              // search
              options={this.state.broadcastDate}
              selected="Choose your option"
              label="Date"
              getTextContent={this.selectDateBooking}
              className="scroll_select"
              name="selectDateBooking"
            ></MDBSelect>
            <div className={this.state.dateValidate}>select a date.</div>

            <MDBSelect
              selectAll
              multiple
              options={this.state.broadcastCustomer}
              // selected="Choose your option"
              label="Customer"
              getTextContent={this.selectphoneNumber}
              className="scroll_select"
            ></MDBSelect>
            <div className={this.state.phoneValidate}>
              select a phone numbers.
            </div>

            <div className="md-form">
              <Editor
                handlePastedText={() => false}
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  inline: {
                    options: ["bold", "italic", "underline", "monospace"],
                  },
                  list: {
                    options: [],
                  },
                  textAlign: { options: [] },
                  link: { options: [] },
                  blockType: {
                    inDropdown: true,
                    options: [],
                    className: "ocultar",
                  },
                  fontSize: {
                    options: [],
                    className: "ocultar",
                  },
                  fontFamily: {
                    className: "ocultar",
                  },
                  embedded: {
                    className: "ocultar",
                  },
                  image: {
                    className: "ocultar",
                  },
                  remove: {
                    className: "ocultar",
                  },
                  colorPicker: {
                    popupClassName: "popup",
                    className: "ocultar",
                    colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
                  },
                }}
              />
              <p className="text-right">{this.state.contadorSMS}</p>

              {this.state.valid_html_msg ? (
                ""
              ) : (
                <div className="novalidate_html">Insert a body message</div>
              )}
            </div>
            <div className="text-center">
              <p
                className="font-italic text-sm-left"
                style={{ fontSize: "x-small" }}
              >
                Character count is only for reference purposes and should only
                be used as a guide. If you include emojis or any other special
                characters in the text, the amount of text messages used against
                your quota may increase.
              </p>

              <MDBBtn color="deep-orange" type="submit" className="btn_save">
                {this.state.broadcastSave ? (
                  <div className="d-flex flex-row">
                    <div className="p-1">Save</div>
                    <div className="p-1">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-1">Save</div>
                )}
              </MDBBtn>
            </div>
          </form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default ModalNewBroadcastMessage;
