export default [
    {
      label: "User ID",
      field: "userId",
      sort: "asc",
      minimal: "size-col-10",
    },
    {
      label: "Username",
      field: "userName",
      sort: "asc",
      minimal: "size-col-20",
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      minimal: "size-col-10",
    },
    {
      label: "Type",
      field: "type",
      sort: "asc",
      minimal: "size-col-10",
    },
    {
      label: "State",
      field: "buttons",
      sort: "asc",
      minimal: "size-col-20 ",
    },
  ];