export default [
  {
    label: "Id Booking / Webhook",
    field: "id",
    sort: "desc",
    minimal: "size-col-15",
  },
  {
    label: "Date booking",
    field: "date_booking",
    sort: "asc",
    minimal: "size-col-15",
  },
  {
    label: "Start Activity",
    field: "start_activity",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "End Activity",
    field: "end_activity",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Duration",
    field: "duration",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Client",
    field: "client",
    sort: "asc",
    minimal: "size-col-30",
  },
  {
    label: "status",
    field: "status",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-20 ",
  },
];
