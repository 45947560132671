import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBCardHeader,
} from "mdbreact";
import { withRouter, Redirect } from "react-router-dom";
import MDBDataTable from "../../source-pack/DataTable/DataTable";
import api from "../../helpers/api";
// import QRImage from "react-qr-image";
import Config_table from "./01-config_table_wpstatus";
import socketIOClient from "socket.io-client";
//const socket = socketIOClient("http://localhost:7613");

class Products_list extends React.Component {
  state = {
    redirect: false,
    qr: false,
    qr_data: "",
    data: "",
    state_api: "NO_SERVICE",
    socket: "", // query
    socket_server: "",
    connected: false,
    ready: false,
    last_msg_from: "",
    last_msg_body: "",
    last_msg_from_sent: "",
    last_msg_body_sent: "",
    session_ok: false,
    user_id: "",
    status_docker: "",
    state_api_buttons: "",
  };

  componentDidMount() {
    this.load_data();
  }

  carga_socket = () => {
    // console.log("socket loading")
    const socket = this.state.socket;
    // console.log("---socket",this.state.socket);

    socket.on("disconnect", () => {
      // console.log("---socket disconnect",socket.id); // undefined
      //consultar si el docker continua online
      this.setState({
        qr: false,
        connected: false,
        state_api: "NO_SERVICE", //"LOADING" //"NO_SERVICE"
      });
    });

    socket.on("getState", (data) => {
      // console.log("connection api wp",data);
      this.setState({
        state_api: data.state,
      });
      if (data.state === "CONNECTED") {
        this.setState({
          qr: false,
          connected: true,
          ready: true,
          session_ok: false,
          state_api_buttons: false,
        });
      }
      if (data.state === "INITIATING") {
        this.setState({
          qr: false,
          connected: true,
          ready: false,
          session_ok: true,
        });
      }
      if (data.state === "LOADING_SESSION") {
        this.setState({
          qr: false,
          connected: true,
          ready: false,
          session_ok: false,
        });
      }
      if (data.state === "QR_EMIT") {
        this.setState({
          qr: true,
          connected: true,
          ready: false,
          session_ok: true,
        });
      }
      if (data.state === "LOADING_SESSION_QR") {
        this.setState({
          qr: false,
          connected: true,
          ready: false,
          session_ok: true,
        });
      }

      this.show_state();
    });

    socket.on("qr_code", (data) => {
      // console.log("connection api qr",data);
      this.setState({
        qr_data: data.qr,
      });
    });
    socket.on("get_last_qr_res", (data) => {
      // console.log("lastqr",data);
      this.setState({
        qr_data: data.qr,
        qr: true,
      });
    });

    socket.on("last_msg", (data) => {
      // console.log("last_msg",data);
      this.setState({
        last_msg_from: `From:${data.msg.from}`,
        last_msg_body: `Message:${data.msg.body}`,
      });
    });

    socket.on("last_msg_sent", (data) => {
      // console.log("last_msg_sent",data);
      this.setState({
        last_msg_from_sent: `From:${data.msg.numero}`,
        last_msg_body_sent: `Message:${data.msg.body}`,
      });
    });
  };

  load_data = async () => {
    try {
      const res = await api("getqr", "application/json");
      // console.log("consult QR",res);

      var a = [];
      //console.log("--->",res.data.data);
      if (res.data.data !== undefined) {
        res.data.data.map(async (val) => {
          a.push({
            hora_actividad: `${val.hora_actividad}`,
            estado: val.estado,
            estado_api: val.estado_api,
          });
        });
      }

      const url = res.data.data_user.url_wp.split("6004")[0];
      const url_ = url + "7603";
      // console.log("URL--->",url_)

      this.setState({
        data: {
          columns: Config_table,
          rows: a,
        },
        load_data: true,
        socket: socketIOClient(url_),
        user_id: res.data.data_user.id_usuario,
        status_docker: res.data.data_user.statusDocker,
      });
      // console.log("-->user:",res.data.data_user.id_usuario);
      // console.log("-->Docker_status:",res.data.data_user.statusDocker);
      if (res.data.data_user.statusDocker === "true") {
        this.setState({
          state_api: "DOCKER_ONLINE",
        });
      }
      if (res.data.data_user.statusDocker === "") {
        this.setState({
          state_api: "NO_SERVICE",
          // state_api_buttons:false
        });
      }

      if (res.data.data_user.statusDocker === "false") {
        this.setState({
          state_api: "START_DOCKER",
        });
      }
      const session_JSON = JSON.parse(res.data.data_user.wp_session);
      // console.log("SESSION--->",session_JSON.session);
      if (
        session_JSON.session === "n/a" &&
        this.state.state_api !== "NO_SERVICE"
      ) {
        this.setState({
          state_api_buttons: true,
        });
      } else {
        this.setState({
          state_api_buttons: false,
        });
      }
      this.carga_socket();
      this.socket_servidor();
    } catch (e) {
      // console.log("query error",e);
    }
  };

  socket_servidor = () => {
    this.setState({
      socket_server: socketIOClient("http://3.15.204.5:6900"),
    });
    this.state.socket_server.on("connect", () => {
      // console.log("--->connect SOCKET server"); // undefined
    });
    // console.log("Name_SOCKET:",`GET_CONFIG_APP${this.state.user_id}`);

    this.state.socket_server.on(
      `GET_CONFIG_APP${this.state.user_id}`,
      (data) => {
        // console.log("--->SOCKET SERVER:",data); // undefined
        window.location.href = "/status_wp";
        //this.carga_socket();
      }
    );
  };

  getqr = async () => {
    try {
      const socket = this.state.socket;
      // console.log("--->",this.state.state_api,this.state.qr_data)
      if (this.state.qr_data === "") {
        // console.log("check empty qr")

        socket.emit("get_last_qr", {
          a: "a",
        });
      } else {
        // console.log("full")
        if (
          this.state.state_api === "INITIATING" ||
          this.state.state_api === "UNPAIRED" ||
          this.state.state_api === "QR_EMIT"
        ) {
          this.setState({
            qr: true,
          });
        }
      }
    } catch (e) {
      // console.log("query error",e);
    }
  };

  docker_init = async () => {
    try {
      const res = await api("start_docker", "application/json");
      // console.log("DOCKER response",res);
      this.setState({
        state_api: "START_DOCKER",
      });
    } catch (e) {
      // console.log("docker error",e);
    }
  };

  docker_reset = async () => {
    try {
      const res = await api("delete_docker", "application/json");
      // console.log("stop docker")
      this.setState({
        state_api: "NO_SERVICE",
      });
    } catch (e) {
      // console.log("docker error",e);
    }
  };

  timmer = () => {
    // console.log("call");
  };

  show_state = () => {
    // console.log("--->>>RUN",this.state.state_api)
    switch (this.state.state_api) {
      case "CONNECTED":
        // console.log("show interface");
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="6">
                WHATSAPP SERVICE ONLINE
                <h5>Last Message Received:</h5>
                {this.state.last_msg_from}
                <br></br>
                {this.state.last_msg_body}
              </MDBCol>
              <MDBCol lg="6">
                WHATSAPP SERVICE ONLINE
                <h5>Last Message Sent:</h5>
                {this.state.last_msg_from_sent}
                <br></br>
                {this.state.last_msg_body_sent}
              </MDBCol>
              <MDBCol lg="12">
                <MDBBtn
                  color="danger"
                  className="color_select"
                  onClick={this.reset_session}
                >
                  RESET SESSION
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  className="color_select"
                  onClick={this.docker_reset}
                >
                  STOP DOCKER
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );
      case "LOADING_SESSION":
        // console.log("show interface");
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">LOADING SESSION</MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "NO_SERVICE":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">SERVICE NOT AVAILABLE</MDBCol>
              <MDBCol lg="12">
                <MDBBtn
                  color="danger"
                  className="color_select"
                  onClick={this.docker_init}
                >
                  START DOCKER
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "INITIATING":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">
                Starting service. This process may take a few minutes, please
                wait..
              </MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "QR_EMIT":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="6">
                Please scan QR code:
                {/* <QRImage text={this.state.qr_data} /> */}
              </MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "LOADING_SESSION_QR":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">LOADING_SESSION_QR</MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "START_DOCKER":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">LOADING_DOCKER, Please wait...</MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "DOCKER_ONLINE":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">DOCKER_ONLINE, Please wait...</MDBCol>
            </MDBRow>
          </Fragment>
        );

      case "LOADING":
        return (
          <Fragment>
            <MDBRow>
              <MDBCol lg="12">LOADING,Please wait...</MDBCol>
            </MDBRow>
          </Fragment>
        );
    }
  };

  reset_session = () => {
    const socket = this.state.socket;
    // console.log("reset_session")
    socket.emit("restart_qr", {
      state: "restart",
    });
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}

        <MDBCol lg="6">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon fab icon="product-hunt" /> QR Code
            </MDBCardHeader>

            <MDBCardBody>
              {this.state.state_api_buttons ? (
                <Fragment>
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={this.reset_session}
                  >
                    RESET SESSION
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={this.docker_reset}
                  >
                    STOP DOCKER
                  </MDBBtn>
                </Fragment>
              ) : (
                ""
              )}
              {this.show_state()}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol lg="6">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon fab icon="product-hunt" /> Status
            </MDBCardHeader>

            <MDBCardBody>
              <MDBDataTable
                striped
                bordered
                small
                paging={false}
                btn
                data={this.state.data}
                maxHeight="300px"
                reponsiveSm
                className="table_warp"
                custom={"table_warp"}
                fixed
              ></MDBDataTable>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withRouter(Products_list);
