import React, { Fragment } from "react";

import { withRouter } from "react-router-dom";

import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
} from "mdbreact";

import Edit_form from "../02-chatbot/04-edit_chatbot_msg";
import api from "../../helpers/api";

class Buttons_products extends React.Component {
  state = {
    id: "",
    modals: {
      form: false,
      header: {
        title: "Edit Automated Message",
        color: "color_select",
      },
      component: <Edit_form data_edit={this.props.data} />,
      centered: true,
    },
    msg_state: this.props.data.ignore_template_msg ? true : false,
    bot_state: this.props.data.ignore_template_bot ? true : false,
    save: false,
  };

  delete_data = async (a) => {
    // console.log("delete id", a);
    const res = await api("deleteProduct", "application/json", { id: a });
    // console.log("query2-delete", res.data);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  modal_data = async (data, id) => {
    // console.log("data from click delete", data, id);
    if (data === "delete") {
      // console.log("state", this.state.modals.form);
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this product?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.modal_data("new-togle", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.delete_data(id)}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
    if (data === "new-togle") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: false,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
        },
      });
    }
    if (data === "edit") {
      // console.log("state", this.state.modals.form);
      // console.log("data-rr", this.props.data);
      // console.log("msg", this.props.data);
      // console.log("id_automated--r", id);
      const time = ["Hour", "Minutes", "Days"];
      var new_options = [];
      time.map((value, index) => {
        if (this.props.data.time === value) {
          // console.log("found");
          new_options.push({
            checked: true,
            text: value,
            value: `${index + 1}`,
          });
        } else {
          new_options.push({
            checked: false,
            text: value,
            value: `${index + 1}`,
          });
        }
      });

      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Edit Chatbot Message",
            color: "color_select",
          },
          component: (
            <Edit_form
              data_edit={this.props.data}
              id_={id}
              refresh_={this.refresh_data.bind(this)}
              new_options_={new_options}
            />
          ),
          centered: true,
        },
      });
    }

    if (data === "config") {
      // console.log("product settings", this.state.modals.form);
      // console.log("state:", this.props.data.ignore_template_msg);

      this.props.history.push({
        pathname: "/products/edit",
        data: this.props.data,
      });

      // this.setState({
      //   id: id,
      //   modals: {
      //     form: true,
      //     header: {
      //       title: "Product Configuration",
      //       color: "color_select",
      //     },
      //     component: (
      //       <form onSubmit={this.update_product}>
      //         <Fragment>
      //           <MDBRow>
      //             <MDBCol md="12" className="text-left">
      //               <MDBInput
      //                 label="Ignore automated message template"
      //                 defaultChecked={this.props.data.ignore_template_msg}
      //                 type="checkbox"
      //                 id="checkbox1"
      //                 onClick={() => this.handleSwitchChange("msg_state")}
      //               />
      //               <MDBInput
      //                 label="Ignore chatbot template"
      //                 defaultChecked={this.props.data.ignore_template_bot}
      //                 type="checkbox"
      //                 id="checkbox2"
      //                 onClick={() => this.handleSwitchChange("bot_state")}
      //               />
      //             </MDBCol>
      //           </MDBRow>
      //           <div className="text-center">
      //             <MDBBtn
      //               color="deep-orange"
      //               type="submit"
      //               className="btn_save"
      //             >
      //               {this.state.save ? (
      //                 <div className="d-flex flex-row">
      //                   <div className="p-1">Save</div>
      //                   <div className="p-1">
      //                     <div className="spinner-border" role="status">
      //                       <span className="sr-only">Loading...</span>
      //                     </div>
      //                   </div>
      //                 </div>
      //               ) : (
      //                 <div className="p-1">Save</div>
      //               )}
      //             </MDBBtn>
      //           </div>
      //         </Fragment>
      //       </form>
      //     ),
      //     centered: true,
      //   },
      // });
    }
  };

  handleSwitchChange = (data) => {
    //console.log("change swtich")
    this.setState({
      [data]: !this.state[data],
    });
    // console.log("change swtich", this.state[data]);
  };

  refresh_data = () => {
    // console.log("refresh data");
    // console.log(this.props);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  update_product = async (event) => {
    event.preventDefault();

    // console.log(
    //   "actulizacion del producto",
    //   this.state.msg_state,
    //   this.state.bot_state
    // );
    //

    this.props.update(
      this.props.data.idusr_tours,
      this.state.msg_state,
      this.state.bot_state
    );
    // this.props.refresh();
    this.modal_data("new-togle", "");
  };

  goToAutomatedMessageProduct = () => {
    this.props.history.push({
      pathname: `/products/add_automated_msg_product/${this.props.data.idusr_tours}`,
      data: this.props.data.productName
    });
  };

  goToChatbotMessageProduct = () => {
    this.props.history.push({
      pathname: `/products/add_chatbot_msg_product/${this.props.data.idusr_tours}`,
      data: this.props.data.productName
    });
  };

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.modals.form}
          toggle={() => this.modal_data("new-togle", "")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            className={
              this.state.modals.header.color + " white-text text-center"
            }
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>
        <MDBContainer className="center-button">
          {/* <MDBBtn
            title='Delete product'
            tag="a"
            className="btn-square color_btn-red "
            color="danger"
            onClick={() =>
              this.modal_data("delete", this.props.data.idusr_tours)
            }
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn> */}

          <MDBBtn
            title='Product configurations'
            tag="a"
            className="btn-square color_btn-d"
            onClick={() =>
              this.modal_data("config", this.props.data.idusr_tours)
            }
            color="infoa"
          >
            <MDBIcon icon="wrench" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            title='Product automated messages'
            tag="a"
            className="btn-square color_btn-d "
            onClick={this.goToAutomatedMessageProduct}
            color="infoa"
          >
            <MDBIcon icon="bell" className="size_icon" />
          </MDBBtn>
          <MDBBtn
            title='Product chatbot responses'
            tag="a"
            className="btn-square color_btn-d "
            onClick={this.goToChatbotMessageProduct}
            // href={`/products/add_chatbot_msg_product/${this.props.data.idusr_tours}`}
            color="infoa"
          >
            <MDBIcon icon="robot" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            title='Product bookings'
            tag="a"
            className="btn-square color_btn-d "
            href={`/products/list_booking_product/${this.props.data.idusr_tours}`}
            color="infoa"
          >
            <MDBIcon icon="list" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default withRouter(Buttons_products);
