export default [
  {
    label: "Type",
    field: "type",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Body",
    field: "body",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Segments",
    field: "segments",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "State",
    field: "state",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Command",
    field: "command",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Data update",
    field: "data_update",
    sort: "asc",
    minimal: "size-col-20 ",
  },
  {
    label: "Date created",
    field: "date_created",
    sort: "asc",
    minimal: "size-col-20 ",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-10 ",
  },
];
