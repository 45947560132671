import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
} from "mdbreact";

import User_config from "./01-user_config";
import Credential_token from "./02-credentials_token";

import AdminCardSection from "./dashboard/AdminCardSection";
import ChartSection from "./dashboard/ChartSection";
import User_webhook from "./05-user_webhook";

class User_settings extends React.Component {
  state = {
    activeItemClassicTabs3: "1",
  };

  toggleClassicTabs3 = (tab) => () => {
    if (this.state.activeItemClassicTabs3 !== tab) {
      this.setState({
        activeItemClassicTabs3: tab,
      });
    }
  };
  
  render() {
    return (
      <React.Fragment>
        <div className="classic-tabs">
          <MDBNav classicTabs className="mt-1 color_select">
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItemClassicTabs3 === "1"}
                onClick={this.toggleClassicTabs3("1")}
              >
                <MDBIcon icon="cog" size="2x" />
                <br />
                User Settings
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={this.state.activeItemClassicTabs3 === "2"}
                onClick={this.toggleClassicTabs3("2")}
              >
                <MDBIcon far icon="chart-bar" size="2x" />
                <br />
                Dashboard
              </MDBNavLink>
            </MDBNavItem>

          </MDBNav>
          <MDBTabContent
            className="card mb-5"
            activeItem={this.state.activeItemClassicTabs3}
          >
            <MDBTabPane tabId="1">
              <MDBRow>
                <MDBCol md="6">
                  <User_config />
                </MDBCol>
                <MDBCol lg="6">
                  <Credential_token />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12">
                  <User_webhook />
                </MDBCol>
              </MDBRow>
            </MDBTabPane>

            <MDBTabPane tabId="2">
              <MDBRow>
                <MDBCol md="6">
                  <MDBRow>
                    <MDBCol size="12">
                      <AdminCardSection />
                    </MDBCol>
                  </MDBRow>

                </MDBCol>
                <MDBCol md="6" className="mb-4">
                  <ChartSection />
                </MDBCol>
              </MDBRow>

            </MDBTabPane>
          </MDBTabContent>
        </div>
      </React.Fragment>
    );
  }
}

export default User_settings;
