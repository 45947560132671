import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBAlert,
} from "mdbreact";

import imageCompression from "browser-image-compression";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import path from "path";
import getJWT from "../../helpers/jwt";
import api from "../../helpers/api";
import isgsm7 from "isgsm7";

class New_automated_msg extends React.Component {
  constructor(props) {
    super(props);

    const html = ""; //'<p>Hey this <strong>editor</strong> rocks 😀</p>1';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        locacion: "",
        horizontal: {
          state: false,
          file: undefined,
        },
        vertical: {
          state: false,
          file: undefined,
        },
        map: "http://maps.google.com/maps?q=-0.230788,-78.5234213&z=15&output=embed",
        latitude: "",
        longitude: "",
        showmap: false,
        attached: true,
        file: undefined,
        start_compresion: false,
        progress_compresion: 0,
        ext: "",
        html_msg: "",
        valid_html_msg: true,
        incompatible: false,
        max_size_file: "3000000",
        max_size: false,
        alert: {
          show: false,
          msg: "",
        },
        save: false,
        saving: false,
        command_data: "",
        command_data_location: "",
        command_data_original: "",
        system_data: "",
        origin_data: "",
        options: [
          {
            text: "Hour(s)",
            value: "1",
          },
          {
            text: "Minute(s)",
            value: "2",
          },
          {
            text: "Day(s)",
            value: "3",
          },
        ],
        title: "",
        number_time: "",
        moment: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        type: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        command: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        system: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        origin: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback",
          data: "",
        },
        modal: false,
        postcard: false,
        system_origin_data: [],
        show_origin_options: false,
        counterSMS: "0/0",
      };
    }
  }

  selec_type = ["moment", "time", "type", "command", "system", "origin"];

  options = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "After booking",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "Before activity",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "3",
        text: "During activity",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "4",
        text: "After activity",
      },
    ],
  };

  type = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "message",
      },
    ],
  };

  topic = {
    options: [
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "1",
        text: "@firstmsg",
      },
      {
        checked: false,
        disabled: false,
        icon: null,
        value: "2",
        text: "@recommendations",
      },
    ],
  };

  async componentDidMount() {
    try {
      const a = await api("userdata_commands_origin", "application/json");

      var data_command = [];
      var location_command_data = [];
      var system_data = [];
      var count = 0;
      var data_ = a.data.data.commands.map((value, index) => {
        // console.log("data value",value.tema);
        if (value.tema !== "@postcard") {
          data_command.push({
            checked: false,
            disabled: false,
            icon: null,
            value: `${index + 1}`,
            text: value.tema,
          });
        }
        //command_data_location
        if (
          value.tema !== "@postcard" &&
          value.tema !== "@firstmsg" &&
          value.tema !== "@review" &&
          value.tema !== "@includes" &&
          value.tema !== "@recommendations"
        ) {
          count++;
          location_command_data.push({
            checked: false,
            disabled: false,
            icon: null,
            value: `${count}`,
            text: value.tema,
          });
        }
      });

      // const system_f=[];

      const unique = a.data.data.origin
        .map((e) => e["sistema"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((obj) => a.data.data.origin[obj])
        .map((e) => a.data.data.origin[e]);

      // console.log("unique return",unique)
      var data_2 = unique.map((value, index) => {
        system_data.push({
          checked: false,
          disabled: false,
          icon: null,
          value: `${index + 1}`,
          text: value.sistema,
        });
      });

      this.setState({
        command_data_location: {
          options: location_command_data,
        },
        command_data_original: {
          options: data_command,
        },
        system_data: {
          options: system_data,
        },
        system_origin_data: a.data.data.origin,
      });
      // console.log("data command",data_command);
      // console.log("data command_location",this.state.command_data_location);
      // console.log(this.state.command_data)
    } catch (e) {
      // console.log("load data error", e);
    }
  }

  toggle = () => {
    // console.log("click toggle")
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.saving === false) {
      // console.log("validated");
      const msg_html = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const ht = msg_html.trim();
      // console.log("validation_html:",ht);
      if (ht === "<p></p>") {
        this.setState({
          valid_html_msg: false,
        });
      }
      this.setState({
        html_msg: msg_html,
      });
      event.target.className += " was-validated";

      var a = await this.select_box();
      var b = await this.input_fields();
      var c = await this.validate_postal();
      var d = await this.validate_location();

      if (a && b && c && d) {
        // console.log("all validated-send api");
        let timeOptionSelect = "";
        if (this.state.time.data === "Minute(s)") {
          timeOptionSelect = "Minutes";
        }
        if (this.state.time.data === "Hour(s)") {
          timeOptionSelect = "Hour";
        }
        if (this.state.time.data === "Day(s)") {
          timeOptionSelect = "Days";
        }
        const datos = {
          title: this.state.title,
          moment: this.state.moment.data,
          time: timeOptionSelect,
          number_time: this.state.number_time,
          type: this.state.type.data,
          command: this.state.command.data,
          file: this.state.file,
          message: msg_html,
          horizontal: this.state.horizontal.file,
          vertical: this.state.vertical.file,
          location: `${this.state.latitude},${this.state.longitude}`,
          system: this.state.system.data,
          origin: this.state.origin.data,
        };
        this.send_api(datos);
      }
    } else {
      // console.log("invalidated-sending");
    }
  };

  input_fields = () => {
    const ht = this.state.html_msg.trim();
    return new Promise((resolve, reject) => {
      if (
        this.state.title !== "" &&
        this.state.number_time !== "" &&
        ht !== "<p></p>"
      ) {
        // console.log("validated");
        this.setState({
          valid_html_msg: true,
        });
        resolve(true);
      }
    });
  };

  validate_location = () => {
    // console.log("location data:",this.state.horizontal,this.state.vertical,)
    return new Promise((resolve, reject) => {
      if (
        this.state.type.data === "location" &&
        this.state.latitude !== "" &&
        this.state.longitude !== ""
      ) {
        // console.log("location validated");
        resolve(true);
      }
      if (this.state.type.data !== "location") {
        // console.log("validated location is not required");
        resolve(true);
      }
    });
  };

  validate_postal = () => {
    return new Promise((resolve, reject) => {
      if (
        this.state.command.data === "@postcard" &&
        this.state.horizontal.state === true &&
        this.state.vertical.state === true
      ) {
        // console.log("validated postcard");
        resolve(true);
      }

      if (this.state.command.data !== "@postcard") {
        // console.log("vaidated postcard is not required");
        resolve(true);
      }
    });
  };

  select_box = () => {
    // console.log("before promise")
    return new Promise((resolve, reject) => {
      // console.log("run")
      var b = false;
      let validateData = {
        moment: false,
        time: false,
        type: false,
        command: false,
        system: false,
        origin: false,
      };
      this.selec_type.map((valor, index) => {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok")
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach")
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
          validateData[valor] = true;
        }
      });
      if (this.state.command.data === "@recommendations") {
        if (
          validateData.type &&
          validateData.time &&
          validateData.system &&
          validateData.origin &&
          validateData.moment &&
          validateData.command
        ) {
          resolve(true);
        }
      } else {
        if (
          validateData.type &&
          validateData.time &&
          validateData.moment &&
          validateData.command
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });
  };

  send_api = async (data) => {
    const jwt = getJWT();
    // console.log("jwt-api",jwt)
    // console.log("send api:",data);
    this.setState({
      save: true,
      saving: true,
    });
    const formData = new FormData();

    formData.append("file", data.file);
    formData.append("title", data.title);
    formData.append("moment", data.moment);
    formData.append("time", data.time);
    formData.append("number_time", data.number_time);
    formData.append("type", data.type);
    formData.append("command", data.command);
    formData.append("message", data.message);
    formData.append("ext", this.state.ext);
    formData.append("horizontal", data.horizontal);
    formData.append("vertical", data.vertical);
    formData.append("location", data.location);
    formData.append("product", this.props.productID);
    formData.append("system", data.system);
    formData.append("origin", data.origin);

    // console.log("here",data);

    try {
      const res = await api(
        "add_automated_msg_products",
        "multipart/form-data",
        formData
      );
      // console.log("response:",res.data);
      this.setState({
        save: false,
        saving: false,
      });
      if (res.data.status === "ok") {
        this.props.changeStateModal();
      } else {
        this.setState({
          alert: {
            show: true,
            msg: res.data.msg,
          },
        });
      }
    } catch (e) {
      // console.log("query error", e);
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
    // console.log("selecionar",source,data)
  };

  setSelect_type = (source, data) => {
    // console.log("data type:",source,data);
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
    if (data === "message") {
      this.setState({
        command_data: this.state.command_data_original,
        attached: true,
        showmap: false,
      });
      // console.log("load data:",this.state.command_data_original);
    }
    if (data === "location") {
      this.setState({
        command_data: this.state.command_data_location,
        attached: false,
        showmap: true,
      });
      // console.log("load data location:",this.state.attached);
    }
  };

  setSelect_command = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });

    if (this.state.type.data === "message" && data === "@postcard") {
      this.setState({
        attached: false,
        postcard: true,
      });
      // console.log("load data message + postcard:",this.state.command_data_location);
    }

    if (
      (this.state.type.data === "message" && data === "@firstmsg") ||
      (this.state.type.data === "message" && data === "@recomendations") ||
      (this.state.type.data === "message" && data === "@attractive") ||
      (this.state.type.data === "message" && data === "@reminder") ||
      (this.state.type.data === "message" && data === "@includes") ||
      (this.state.type.data === "message" && data === "@review") ||
      (this.state.type.data === "message" && data === "@notheme")
    ) {
      this.setState({
        attached: true,
        postcard: false,
      });
      // console.log("load data location:",this.state.command_data_original);
    }
    if (data === "@recommendations") {
      // -------------New change for origin------------------
      let options_origin = [];
      let orderOptions = [];

      this.state.system_origin_data.map((val, index) => {
        // -------------New change for origin------------------

        // if (val.sistema === data) {
        //   options_origin.push({
        //     checked: false,
        //     disabled: false,
        //     icon: null,
        //     value: `${index + 1}`,
        //     text: val.ota, //text: val.origen,
        //   });
        // }

        // -------------New change for origin------------------

        orderOptions.push(val.ota);
      });

      orderOptions = orderOptions.filter((item) => item !== "default");

      orderOptions = orderOptions.sort();

      orderOptions.splice(0, 0, "default");

      orderOptions.map((val, index) => {
        options_origin.push({
          checked: false,
          disabled: false,
          icon: null,
          value: `${index + 1}`,
          text: val, //text: val.origen,
        });
      });

      this.setState({
        system: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback_ok",
          data: data,
        },
        origin_data: options_origin,
      });

      // -------------New change for origin------------------

      this.setState({
        show_origin_options: true,
      });
    } else {
      // console.log("don't show origen options")
      this.setState({
        show_origin_options: false,
      });
    }

    if (data !== "@firstmsg") {
      // console.log("show origen options");
      this.options = {
        options: [
          {
            checked: false,
            disabled: true,
            icon: null,
            value: "1",
            text: "After booking",
          },
          {
            checked: false,
            disabled: false,
            icon: null,
            value: "2",
            text: "Before activity",
          },
          {
            checked: false,
            disabled: false,
            icon: null,
            value: "3",
            text: "During activity",
          },
          {
            checked: false,
            disabled: false,
            icon: null,
            value: "4",
            text: "After activity",
          },
        ],
      };
    } else {
      // console.log("don't show origen options");

      this.options = {
        options: [
          {
            checked: false,
            disabled: false,
            icon: null,
            value: "1",
            text: "After booking",
          },
          {
            checked: false,
            disabled: true,
            icon: null,
            value: "2",
            text: "Before activity",
          },
          {
            checked: false,
            disabled: true,
            icon: null,
            value: "3",
            text: "During activity",
          },
          {
            checked: false,
            disabled: true,
            icon: null,
            value: "4",
            text: "After activity",
          },
        ],
      };
    }
  };

  // -------------New change for origin------------------

  // setSelect_system = (source, data) => {
  //   // console.log("system data:",source,data);
  //   const options_origin = [];
  //   this.state.system_origin_data.map((val, index) => {
  //     if (val.sistema === data) {
  //       options_origin.push({
  //         checked: false,
  //         disabled: false,
  //         icon: null,
  //         value: `${index + 1}`,
  //         text: val.origen,
  //       });
  //       // console.log("system options",val.origen)
  //     }
  //   });

  //   this.setState({
  //     system: {
  //       class_novalid: "invalid-feedback",
  //       class_valid: "valid-feedback_ok",
  //       data: data,
  //     },
  //     origin_data: options_origin,
  //   });

  //   // console.log("select__",source,data);
  // };

  // -------------New change for origin------------------
  progress = (a) => {
    // console.log("progress",a)
    this.setState({
      progress_compresion: a,
    });
    if (a === 100) {
      this.setState({
        progress_compresion: 0,
        start_compresion: false,
      });
      // console.log("finished compress")
    }
  };

  fileInputHandler = async (e, origin) => {
    // console.log("file", e,origin);

    if (e[0] !== undefined) {
      if (e[0].size <= this.state.max_size_file) {
        const ext = path.extname(e[0].name);
        if (
          ext === ".jpg" ||
          ext === ".jpeg" ||
          ext === ".png" ||
          ext === ".gif" ||
          ext === ".mp4" ||
          ext === ".pdf"
        ) {
          // console.log(e[0]);
          if (e[0].type === "image/png") {
            this.setState({
              start_compresion: true,
            });
            const options = {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
              onProgress: (a) => this.progress(a),
            };
            const compress_file_a = await imageCompression(e[0], options);

            this.setState({
              file: compress_file_a,
              ext: "jpg",
            });
          } else {
            this.setState({
              file: e[0],
            });
          }

          this.setState({
            incompatible: false,
          });
        } else {
          // console.log("incompatible file");
          this.setState({
            incompatible: true,
          });
        }
        this.setState({
          max_size: false,
        });
      } else {
        /* file max exceed*/
        // console.log("max size file");
        this.setState({
          max_size: true,
        });
      }
    }
  };

  fileInputHandler_postcard = (e, origin) => {
    // console.log(e,origin);
    if (e[0] !== undefined) {
      const ext = path.extname(e[0].name);
      var img = document.createElement("img");
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      var a = reader.readAsDataURL(e[0]);
      img.onload = () =>
        this.update_state_postal(img.width, img.height, e, origin);
    } else {
      // console.log("can't empty");
    }
  };

  update_state_postal = (width, height, e, origin) => {
    // console.log("size",width,height,e);
    var origin_ =
      origin === "horizontal"
        ? {
            h: 1920,
            v: 1080,
          }
        : {
            h: 1080,
            v: 1920,
          };

    if (width === origin_.h && height === origin_.v) {
      // console.log("validated size")
      this.setState({
        [origin]: {
          state: true,
          file: e[0],
        },
      });
    } else {
      // console.log("invalidated size");
      this.setState({
        [origin]: {
          state: false,
          file: e[0],
        },
      });
    }
    // console.log("state:",this.state.horizontal, this.state.vertical)
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const msg_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const ht = msg_html.trim();
    const t = convertToRaw(editorState.getCurrentContent()).blocks;
    let counterCharactersSMS = 0;
    let counter1 = 0;
    let maxSMS = 153;
    t.map((e) => {
      // console.log("text data",e.text);
      if (!isgsm7(e.text)) {
        maxSMS = 67;
      }
      if (counter1 > 0) {
        counterCharactersSMS = counterCharactersSMS + e.text.length + 1;
      } else {
        counterCharactersSMS = counterCharactersSMS + e.text.length;
      }
      counter1++;
    });
    // console.log("total",counterCharactersSMS);
    const totalSMS = Math.ceil(counterCharactersSMS / maxSMS);
    let characters = counterCharactersSMS + "/" + totalSMS;
    this.setState({
      counterSMS: characters,
    });
    // console.log("change editor html",ht);
    if (ht !== "<p></p>") {
      this.setState({
        valid_html_msg: true,
      });
    }
  };

  update_map = () => {
    // console.log("map state")
    this.setState({
      map: `http://maps.google.com/maps?q=${this.state.latitude},${this.state.longitude}&z=15&output=embed`,
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <form onSubmit={this.submitHandler} noValidate className="text-left">
        {this.state.alert.show ? (
          <MDBAlert color="danger" dismiss>
            {this.state.alert.msg}
          </MDBAlert>
        ) : (
          ""
        )}

        <div className="md-form">
          <MDBInput
            value={this.state.title}
            onChange={this.changeHandler}
            type="text"
            id="materialFormRegisterPasswordEx4"
            name="title"
            label="Title"
            required
          >
            <div className="invalid-feedback">
              Please insert a title for automated message.
            </div>
          </MDBInput>
        </div>

        <MDBRow>
          <MDBCol md="6">
            {/* select Type */}
            <div className="md-form">
              <MDBSelect
                name="type"
                options={this.type.options}
                label="Type"
                getTextContent={this.setSelect_type.bind(this, "type")}
                color="primary"
              ></MDBSelect>
              <div className={this.state.type.class_novalid}>
                select type of message.
              </div>
            </div>
            {/*end select Type */}
          </MDBCol>

          <MDBCol md="6">
            {/* select Topic */}
            <div className="md-form">
              <MDBSelect
                name="command"
                options={this.state.command_data.options} //{this.topic.options}
                label="Command"
                getTextContent={this.setSelect_command.bind(this, "command")}
                color="primary"
              ></MDBSelect>

              <div className={this.state.command.class_novalid}>
                Select Command
              </div>
            </div>
            {/*end select Topic */}
          </MDBCol>
        </MDBRow>

        {this.state.show_origin_options ? (
          <MDBRow>
            {/* <MDBCol md="6">
              <div className="md-form">
                <MDBSelect
                  options={this.state.system_data.options}
                  color="primary"
                  label="System"
                  name="system"
                  className="text_1"
                  getTextContent={this.setSelect_system.bind(this, "system")}
                />

                <div className={this.state.system.class_novalid}>
                  Please select system.
                </div>
              </div>
            </MDBCol> */}

            <MDBCol md="6">
              <div className="md-form">
                <MDBSelect
                  options={this.state.origin_data}
                  search={this.state.origin_data.length > 5 ? true : false}
                  color="primary"
                  label="Origin"
                  name="origin"
                  className="text_1"
                  getTextContent={this.setSelect.bind(this, "origin")}
                />

                <div className={this.state.origin.class_novalid}>
                  Select origin.
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        ) : (
          ""
        )}

        <MDBRow className="espace">
          <MDBCol md="6">
            {/* select action */}
            <div className="md-form">
              <MDBSelect
                name="moment"
                options={this.options.options}
                label="Moment"
                getTextContent={this.setSelect.bind(this, "moment")}
                color="primary"
              ></MDBSelect>

              <div className={this.state.moment.class_novalid}>
                Moment to send
              </div>
            </div>
            {/*end select action */}
          </MDBCol>

          <MDBCol md="3">
            <div className="md-form">
              <MDBSelect
                options={this.state.options}
                color="primary"
                label="Time"
                name="time"
                className="text_1"
                getTextContent={this.setSelect.bind(this, "time")}
              />

              <div className={this.state.time.class_novalid}>
                Please select unity.
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3">
            <div className="md-form">
              <MDBInput
                label="Qty"
                type="number"
                name="number_time"
                value={this.state.number_time}
                onChange={this.changeHandler}
                required
              >
                <div className="invalid-feedback">Insert time</div>
              </MDBInput>
            </div>
          </MDBCol>
        </MDBRow>

        <div className="md-form">
          <Editor
            handlePastedText={() => false}
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              inline: {
                options: ["bold", "italic", "underline", "monospace"],
              },
              list: {
                options: [],
              },
              textAlign: { options: [] },
              link: { options: [] },
              blockType: {
                inDropdown: true,
                options: [],
                className: "ocultar",
              },
              fontSize: {
                options: [],
                className: "ocultar",
              },
              fontFamily: {
                className: "ocultar",
              },
              embedded: {
                className: "ocultar",
              },
              image: {
                className: "ocultar",
              },
              remove: {
                className: "ocultar",
              },
              colorPicker: {
                popupClassName: "popup",
                className: "ocultar",
                colors: ["rgb(255,0,0)", "rgb(0,0,0)"],
              },
            }}
          />
          <p className="text-right">{this.state.counterSMS}</p>
          {this.state.valid_html_msg ? (
            ""
          ) : (
            <div className="novalidate_html">Insert a body message</div>
          )}
        </div>

        <div className="text-center">
          <MDBBtn color="deep-orange" type="submit" className="btn_save">
            {this.state.save ? (
              <div className="d-flex flex-row">
                <div className="p-1">Save</div>
                <div className="p-1">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-1">Save</div>
            )}
          </MDBBtn>
        </div>
      </form>
    );
  }
}

export default New_automated_msg;
