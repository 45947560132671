export default [
  {
    label: "Message",
    field: "typeMessage",
    minimal: "size-col-10",
  },
  {
    label: "Quantity",
    field: "quantity",

    minimal: "size-col-20",
  },
  // {
  //   label: "Percent",
  //   field: "percent",
  //   // sort: "asc",
  //   minimal: "size-col-10 ",
  // },
];
