export default [
  {
    label: "Title",
    field: "title",
    sort: "asc",
    // width: "30%",
    minimal: "size-col-20",
  },
  {
    label: "Product",
    field: "product",
    sort: "asc",
    // width: "40%",
    minimal: "size-col-40",
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    // width: "10%",
    minimal: "size-col-15",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    // width: "20%",
    minimal: "size-col-20",
  },
];
