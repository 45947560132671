import React from "react";

import { MDBSideNav } from "mdbreact";

import CustomComponent from "./custom";

class SideNavPageAdmin extends React.Component {
  state = {
    isOpen: false,
    admin: false,
  };

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      sideNavToggled: true,
    });
  };

  handleClick = (e) => {
    e.stopPropagation();
    // Waves - Get Cursor Position
    let cursorPos = {
      top: e.clientY,
      left: e.clientX,
      time: Date.now(), // time indicates particular clicks
    };
    this.setState({ cursorPos: cursorPos });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <MDBSideNav
        logo="/logo.png"
        mask="strong"
        fixed
        breakWidth={1024}
        style={{ transition: "padding-left .3s" }}
        className="grey lighten-4"
        triggerOpening={this.props.triggerOpening}
        href="/"
      >
        <ul className="side-nav_p">
          <li className="waves-effect line_a ">
            <CustomComponent
              title="User Configuration"
              icon="user"
              type="far"
              link="/ADMIN"
            />
          </li>

          {
            <li className="waves-effect line_a ">
              <CustomComponent
                title="Reports"
                icon="hdd"
                type="far"
                link="/reports"
              />
            </li>
          }
        </ul>
      </MDBSideNav>
    );
  }
}

export default SideNavPageAdmin;
