import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
} from "mdbreact";
import { withRouter, Redirect, Route } from "react-router-dom";
import MDBDataTable from "../../source-pack/DataTable/DataTable";
import Side_modal from "../../components/modal_side";
import Form_new_msg from "../03-products/04-Form_new_msg_products";
import Butons_bar from "../03-products/05-Buttons_bar_products";
import Config_table from "../../components/config_table_automated_msg";
import api from "../../helpers/api";

class Product_table_automate_msg extends React.Component {
  state = {
    redirect: false,
    modals: {
      form: false,
      header: {
        title: "   Create Automate Message",
        color: "color_select",
      },
      component: "aaa", //<Form_new_msg changeStateModal="aaa"/>,
      centered: true,
    },
    open: false,
    title: "",
    hours: 0,
    minutes: 5,
    action: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    time: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "00:05",
    },
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    data: "",
    productName: "",
  };

  toggle = () => {
    // console.log("click toggle");
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });

    toast.success("Automatic msg saved successfully", {
      closeButton: true,
    });
    // call to api for all data message created
    this.load_data();
  };

  open_a = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  open_modal = (data) => () => {
    // console.log("data from click", data);
    if (data === "new") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "   Create Automate Message Product",
            color: "color_select",
          },
          component: (
            <Form_new_msg
              changeStateModal={this.toggle.bind(this)}
              productID={this.props.match.params.id}
            />
          ),
          centered: true,
        },
      });
    }
    if (data === "new-togle") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Create Automate Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "delete") {
      // console.log("state", this.state.modals.form);
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_btn-red",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn color="danger" className="color_select">
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" className="color_btn-red">
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = async () => {
    if (this.props.location.data === undefined || this.props.location.data === null) {
      this.setState({
        productName: localStorage.getItem("productName")
      });
    } else {
      this.setState({
        productName: this.props.location.data,
      });
      localStorage.setItem("productName", this.props.location.data);
    }
    // console.log("refresh");
    // console.log(this.props.match);
    try {
      const res = await api(
        `all_product_automated_msg/${this.props.match.params.id}`,
        "application/json"
      );
      // console.log("query", res);
      // console.log("query1", res.data.data);
      var a = [];
      res.data.data.map(async (val) => {
        // console.log(val.title);
        var remove_msg = val.message.substring(1);
        remove_msg = remove_msg.slice(0, -3);

        let textTable = val.message.replace(/<[^>]*>?/g, "");
        textTable = textTable.replace(/['"]+/g, "");
        textTable = textTable.replace(/&.*;/g, " ");
        textTable = textTable.replace(/\\n/g, " ");
        // console.log("remove ch:", remove_msg);
        let timeOption = "";
        if (parseInt(val.qty) > 1 && val.time === "Days") {
          timeOption = "Days";
        }
        if (parseInt(val.qty) > 1 && val.time === "Hour") {
          timeOption = "Hours";
        }
        if (parseInt(val.qty) > 1 && val.time === "Minutes") {
          timeOption = "Minutes";
        }
        if (parseInt(val.qty) === 1 && val.time === "Days") {
          timeOption = "Day";
        }
        if (parseInt(val.qty) === 1 && val.time === "Hour") {
          timeOption = "Hour";
        }
        if (parseInt(val.qty) === 1 && val.time === "Minutes") {
          timeOption = "Minute";
        }
        a.push({
          title: val.title,
          moment: val.moment,
          time: val.qty + " " + timeOption,
          type: val.type,
          command: val.command,
          msg: textTable,
          buttons: (
            <Butons_bar
              id_={val.id_automated_products}
              refresh={this.load_data.bind(this)}
              data={val}
              msg={remove_msg}
            />
          ),
        });
      });

      this.setState({
        data: {
          columns: Config_table,
          rows: a,
        },
      });
    } catch (e) {
      // console.log("query error", e);
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    // console.log(event.target.name, event.target.value);
    event.target.className += " was-validated";
    // console.log("form data", this.state);

    this.selec_type.map((valor) => {
      if (valor !== "time") {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok");
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach");
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
        }
      } else {
      }
    });
    // console.log("query", this.state);
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  getTime = (data) => {
    // console.log("read data");
    if (data !== `0${this.state.hours}:0${this.state.minutes}`) {
      // console.log("dd", data, `${this.state.hours}:${this.state.minutes}`);
      this.setState({
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback_ok",
          data: data,
        },
      });
    }
    // console.log("gettime:", data);
  };

  goToback = () => {
    // this.props.history.goBack();
    this.props.history.push("/products");
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}
        <Side_modal open={this.state.open} />

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBBtn color="secondary" onClick={this.goToback}>
                Back
              </MDBBtn>
              <MDBIcon far icon="bell" />
              {` Products > ${this.state.productName} > Automated Messages`}
            </MDBCardHeader>
            <MDBCardBody>
              <MDBBtn color="secondary" onClick={this.open_modal("new")}>
                New Automated Message Product
              </MDBBtn>
              <MDBDataTable
                striped
                bordered
                small
                paging={false}
                btn
                maxHeight="300px"
                reponsiveSm
                className="table_warp"
                data={this.state.data}
                custom={"table_warp"}
                fixed
              ></MDBDataTable>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withRouter(Product_table_automate_msg);
