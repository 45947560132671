import React from "react";
import axios from "axios";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";

class ReportsButton extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MDBContainer className="center-button">
          <MDBBtn
            title="Download Reports"
            tag="a"
            className={`btn-square ${
              this.props.buttonStatus ? "color_btn-yellow" : "color_btn-h"
            }`}
            onClick={() =>
              this.props.buttonStatus
                ? this.props.downloadReport(this.props.url)
                : ""
            }
            color="infoa"
          >
            <MDBIcon icon="file-download" className="size_icon" />
          </MDBBtn>

          <MDBBtn
            title="Delete Report"
            tag="a"
            className="btn-square color_btn-red"
            onClick={() =>
              this.props.buttonStatus
                ? this.props.deleteReport(this.props.url, this.props.reportName)
                : ""
            }
            color="infoa"
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

export default ReportsButton;
