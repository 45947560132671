import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";

import api from "../../../helpers/api";

class Buttons_automated_msg extends React.Component {
  state = {
    id: "",
    modals: {
      form: false,
      header: {
        title: "Edit Automated Message",
        color: "color_select",
      },
      component: "",
      centered: true,
    },
  };

  delete_data = async (a) => {
    // console.log("query id delete booking",a);
    const res = await api("delete_booking", "application/json", { id: a });

    // console.log("query 2 delete",res.data);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  reset_data = async (a) => {
    // console.log("query id delete booking",a);
    const res = await api("reset_booking", "application/json", { id: a });

    // console.log("query 2 delete",res.data);
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  modal_data = async (data, id) => {
    // console.log("data from click delete",data,id);
    if (data === "delete") {
      // console.log("state",this.state.modals.form)
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this booking?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.modal_data("new-togle", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.delete_data(id)}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
    if (data === "new-togle") {
      // console.log("state",this.state.modals.form)
      this.setState({
        modals: {
          form: false,
          header: {
            title: "Delete Message",
            color: "color_select",
          },
        },
      });
    }
    if (data === "reset") {
      // console.log("state",this.state.modals.form)
      this.setState({
        id: id,
        modals: {
          form: true,
          header: {
            title: "Reset process",
            color: "color_select",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to reset this booking?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn
                    color="danger"
                    className="color_select"
                    onClick={() => this.modal_data("new-togle", "")}
                  >
                    Cancel
                  </MDBBtn>
                  <MDBBtn
                    color="danger"
                    className="color_btn-red"
                    onClick={() => this.reset_data(id)}
                  >
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  refresh_data = () => {
    // console.log("refresh data");
    this.props.refresh();
    this.modal_data("new-togle", "");
  };

  render() {
    return (
      <Fragment>
        <MDBModal
          isOpen={this.state.modals.form}
          toggle={() => this.modal_data("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            className={
              this.state.modals.header.color + " white-text text-center"
            }
            titleClass="w-100"
          >
            <h5 className="text-center">{this.state.modals.header.title}</h5>
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>
        <MDBContainer className="center-button">
          {/* <MDBBtn
            title='Delete booking'
            tag="a"
            className="btn-square color_btn-red "
            onClick={() =>
              this.modal_data("delete", this.props.data.id_reserva)
            }
            color="danger"
          >
            <MDBIcon far icon="trash-alt" className="size_icon" />
          </MDBBtn> */}
          <MDBBtn
            title='Reset booking'
            tag="a"
            className="btn-square color_btn-d"
            onClick={() => this.modal_data("reset", this.props.data.id_reserva)}
            color="infoa"
          >
            <MDBIcon icon="redo-alt" className="size_icon" />
          </MDBBtn>
          <MDBBtn
            title='Automated messages by customer'
            tag="a"
            className="btn-square color_btn-d"
            href={`/products/automated_msg_booking/${this.props.data.id_reserva}`}
            color="infoa"
          >
            <MDBIcon icon="angle-right" className="size_icon" />
          </MDBBtn>
        </MDBContainer>
      </Fragment>
    );
  }
}

export default Buttons_automated_msg;
