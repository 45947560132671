import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
} from "mdbreact";
import { withRouter, Redirect, Route } from "react-router-dom";
import MDBDataTable from "../../../source-pack/DataTable/DataTable";
import Butons_bar from "./02-Buttons_bar_automaed_msg";
import Config_table from "./01-config_table_automated_msg";
import api from "../../../helpers/api";

class Product_table_booking extends React.Component {
  state = {
    redirect: false,
    modals: {
      form: false,
      header: {
        title: "   Create Automate Message",
        color: "color_select",
      },
      component: "aaa",
      centered: true,
    },
    open: false,
    title: "",
    hours: 0,
    minutes: 5,
    action: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    time: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "00:05",
    },
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    data: {
      columns: Config_table,
      rows: [],
    },
    title: "",
  };

  toggle = () => {
    // console.log("click toggle")
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });

    toast.success("Automatic msg saved successfully", {
      closeButton: true,
    });
    // call to api for all data message created
    this.load_data();
  };

  open_a = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  open_modal = (data) => () => {
    // console.log("data from click",data);

    if (data === "new-togle") {
      // console.log("state",this.state.modals.form)
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Create Automate Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "delete") {
      // console.log("state",this.state.modals.form)
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_btn-red",
          },
          component: (
            <Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn color="danger" className="color_select">
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" className="color_btn-red">
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = async () => {
    // console.log("refresh")
    console.log(this.props.match.params);
    try {
      const res = await api(
        `all_product_automated_msg_booking/${this.props.match.params.id}`,
        "application/json"
      );
      // console.log("query msg automated",res);
      // console.log("query msg automated products data:",res.data.data);
      var a = [];
      if (res.data.data.length > 0) {
        res.data.data.map(async (val) => {
          //console.log(val.title);
          let textTable = val.message.replace(/<[^>]*>?/g, "");
          textTable = textTable.replace(/['"]+/g, "");
          textTable = textTable.replace(/&.*;/g, " ");
          textTable = textTable.replace(/\\n/g, " ");

          a.push({
            id: `${val.id_msg} / ${val.estado_msg}`,
            date_send: val.send,
            creation_time: val.creation,
            msg: textTable,
            command: `${val.command} / ${val.origin_msg}`,
            buttons: (
              <Butons_bar
                id_={val.id_msg}
                refresh={this.load_data.bind(this)}
                data={val}
              />
            ),
          });
        });
      } else {
      }
      
      this.setState({
        data: {
          columns: Config_table,
          rows: a,
        },
        title: `Name: ${res.data.data[0].nombres} ${res.data.data[0].apellidos} / Status: ${res.data.data[0].estado} --> Internal Code Booking: ${res.data.data[0].reserva}`,
      });
    } catch (e) {
      // console.log("query error",e);
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    // console.log(event.target.name,event.target.value)
    event.target.className += " was-validated";
    // console.log("form data",this.state)

    this.selec_type.map((valor) => {
      if (valor !== "time") {
        if (
          this.state[valor].data === "" ||
          this.state[valor].data === undefined
        ) {
          // console.log("ok")
          this.setState({
            [valor]: {
              class_novalid: "novalidate_select",
              class_valid: "valid-feedback",
            },
          });
        } else {
          // console.log("breach")
          this.setState({
            [valor]: {
              class_novalid: "invalid-feedback",
              class_valid: "valid-feedback_ok",
              data: this.state[valor].data,
            },
          });
        }
      } else {
      }
    });
    // console.log("response",this.state)
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(this.state);
  };

  setSelect = (source, data) => {
    this.setState({
      [source]: {
        class_novalid: "invalid-feedback",
        class_valid: "valid-feedback_ok",
        data: data,
      },
    });
  };

  getTime = (data) => {
    // console.log("read data")
    if (data !== `0${this.state.hours}:0${this.state.minutes}`) {
      // console.log("dd",data,(`${this.state.hours}:${this.state.minutes}`))
      this.setState({
        time: {
          class_novalid: "invalid-feedback",
          class_valid: "valid-feedback_ok",
          data: data,
        },
      });
    }
    // console.log("gettime:",data);
  };

  goToback = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>
          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBBtn color="secondary" onClick={this.goToback}>
                Back
              </MDBBtn>
              <MDBIcon fab icon="product-hunt" /> Automate msg /{" "}
              {this.state.title}
            </MDBCardHeader>
            <MDBCardBody>
              <MDBDataTable
                striped
                bordered
                small
                paging={false}
                btn
                maxHeight="300px"
                reponsiveSm
                className="table_warp"
                data={this.state.data}
                custom={"table_warp"}
                fixed
              ></MDBDataTable>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withRouter(Product_table_booking);
