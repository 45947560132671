import React from "react";
import { MDBWaves } from "mdbreact";
import { MDBIcon } from "./icon";
import { NavLink } from "react-router-dom";
import ApiUser from "../helpers/api";
import { config } from "../config/config";
class CustomComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cursorPos: {},
      press: false,
    };
  }

  handleClick = (e) => {
    e.stopPropagation();
    // Waves - Get Cursor Position
    let cursorPos = {
      top: e.clientY,
      left: e.clientX,
      time: Date.now(), // time indicates particular clicks
    };
    this.setState({ cursorPos: cursorPos });
  };

  handleChatRedirection = async () => {
    const newToken = await ApiUser("chat_auth", "application/json");

    window.location.href = this.props.link + newToken.data.tokenChat;
  };

  render() {
    return (
      <>
        {this.props.link &&
        (this.props.link === config.chatAppUrl ||
          this.props.link === config.chat2AppUrl) ? (
          <div className="text-center" onClick={this.handleChatRedirection}>
            <div onMouseUp={this.handleClick} onTouchStart={this.handleClick}>
              <MDBIcon
                far={this.props.type}
                icon={this.props.icon} //"bell p-2"
                size="2x"
                // className={this.props.select?"p-2 white-text":"p-2 black-text"}
                className="p-2 black-text"
              />
              <div className="mx-auto black-text">
                <span>{this.props.title}</span>
              </div>
              <MDBWaves dark cursorPos={this.state.cursorPos} />
            </div>
          </div>
        ) : (
          <NavLink
            to={this.props.link ? this.props.link : "/"}
            className={
              this.props.select
                ? "w-100 p-2 text-center color_select"
                : "w-100 p-2 text-center "
            }
            activeClassName="w-100 p-2 text-center color_select color_text_btn"
            //  exact
          >
            <div onMouseUp={this.handleClick} onTouchStart={this.handleClick}>
              <MDBIcon
                far={this.props.type}
                icon={this.props.icon} //"bell p-2"
                size="2x"
                // className={this.props.select?"p-2 white-text":"p-2 black-text"}
                className="p-2 black-text"
              />
              <div className="mx-auto black-text">
                <span>{this.props.title}</span>
              </div>
              <MDBWaves dark cursorPos={this.state.cursorPos} />
            </div>
          </NavLink>
        )}
      </>
    );
  }
}

export default CustomComponent;
