import React from "react";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
  MDBSpinner,
} from "mdbreact";
import { Redirect } from "react-router-dom";
import MDBDataTable from "../../source-pack/DataTable/DataTable";
import Side_modal from "../modal_side";
import Form_new_msg from "../form_new_automated_message";

import Config_table from "./01-Config-Table-SMSService";
import api from "../../helpers/api";
import moment from "moment";
import ButtonsSMSService from "./02-Buttons-SMSService";

class SMSService extends React.Component {
  state = {
    redirect: false,
    modals: {
      form: false,
      header: {
        title: "   Create Automate Message",
        color: "color_select",
      },
      component: "aaa", //<Form_new_msg changeStateModal="aaa"/>,
      centered: true,
    },
    open: false,
    title: "",
    hours: 0,
    minutes: 5,
    action: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    time: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "00:05",
    },
    type: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    topic: {
      class_novalid: "invalid-feedback",
      class_valid: "valid-feedback",
      data: "",
    },
    modal: false,
    data: "",
    data_1: "",
    load_data: true,
    options: [],
    system: "",
    initial_date: moment().format("YYYY-MM-DD"),
    finishing_date: moment().add(1, "days").format("YYYY-MM-DD"),
    count_booking: "",
    count_booking_1: "",
  };

  toggle = () => {
    this.setState({
      modals: {
        form: !this.state.modals.form,
        header: {
          title: "Create Automate Message",
          color: "color_select",
        },
      },
    });

    toast.success("Automatic msg saved successfully", {
      closeButton: true,
    });
    // call to api for all data message created
  };

  open_a = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  open_modal = (data) => () => {
    if (data === "new") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "   Create Automate Message",
            color: "color_select",
          },
          component: <Form_new_msg changeStateModal={this.toggle.bind(this)} />,
          centered: true,
        },
      });
    }
    if (data === "new-togle") {
      this.setState({
        modals: {
          form: !this.state.modals.form,
          header: {
            title: "Create Automate Message",
            color: "color_select",
          },
        },
      });
    }

    if (data === "delete") {
      this.setState({
        modals: {
          form: true,
          header: {
            title: "Delete Message",
            color: "color_btn-red",
          },
          component: (
            <React.Fragment>
              <MDBRow>
                <MDBCol md="12">
                  <h6>Are you sure you want to delete this message?</h6>
                </MDBCol>
                <MDBCol md="12">
                  <MDBBtn color="danger" className="color_select">
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" className="color_btn-red">
                    Continue
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </React.Fragment>
          ),
          centered: false,
        },
      });
    }
  };

  cargando = () => {
    this.setState({
      load_data: true,
    });
  };

  componentDidMount() {
    // this.load_data();
    this.load_data_table();
  }

  load_data_table = async () => {
    try {
      this.setState({
        load_data: false,
      });
      const res = await api("read_sms_message_chat", "application/json");
      let a = [];
      res.data.data.map(async (val) => {
        a.push({
          id_usuario: val.id_usuario,
          nombre_usuario: val.nombre_usuario,
          email: val.email,
          permisos: val.permisos,
          buttons: <ButtonsSMSService state={val.SMS_Service} />,
        });
      });
      this.setState({
        data_1: {
          columns: Config_table,
          rows: a,
        },
        load_data: true,
      });
    } catch (e) {}
  };

  update_product = async (id, msg, bot) => {
    const data = {
      idusr_tours: id,
      ignore_template_msg: msg,
      ignore_template_bot: bot,
    };
    const res = await api("update_product", "application/json", data);
    this.load_data();
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}
        <Side_modal open={this.state.open} />

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBModal
          isOpen={this.state.modals.form}
          toggle={this.open_modal("new-togle")} //
          centered={this.state.modals.centered}
        >
          <MDBModalHeader
            toggle={this.open_modal("new-togle")}
            className={this.state.modals.header.color + " white-text"}
            titleClass="w-100"
          >
            {this.state.modals.header.title}
          </MDBModalHeader>

          <MDBModalBody className="text-center">
            {this.state.modals.component}
          </MDBModalBody>
        </MDBModal>

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon far icon="envelope" /> SMS Service
            </MDBCardHeader>

            {this.state.load_data ? (
              <MDBCol lg="12">
                <MDBCardBody>
                  <MDBDataTable
                    striped
                    bordered
                    small
                    paging={false}
                    btn
                    maxHeight="300px"
                    reponsiveSm
                    className="table_warp"
                    cargando_json={this.cargando}
                    data={this.state.data_1}
                    custom={"table_warp"}
                    fixed
                  ></MDBDataTable>
                </MDBCardBody>
              </MDBCol>
            ) : (
              <MDBRow center style={{ height: "80vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            )}
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default SMSService;
