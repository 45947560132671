import React, { Component } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { withRouter } from "react-router-dom";

import api from "../helpers/api";
import jwt_ADMIN from "../helpers/jwt_ADMIN";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { MDBBtn } from "../source-pack/Button/Button";

class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      logout: false,
      user_name: "",
      admin_state: false,
    };
    // this.onClick = this.onClick.bind(this);
    // this.toggle = this.toggle.bind(this);
    this.handleToggleClickA = this.handleToggleClickA.bind(this);
  }

  async componentDidMount() {
    try {
      const b = jwt_ADMIN();
      if (b) {
        this.setState({
          admin_state: b,
        });
      }
      // console.log("--->:",b)
      const a = await api("userdata", "application/json");
      // console.log("user content",a);
      this.setState({
        user_name: a.data.data.user.nombre_usuario,
      });
    } catch (e) {}
  }
  // onClick() {
  //   this.setState({
  //     collapse: !this.state.collapse,
  //   });
  // }

  // toggle() {
  //   this.setState({
  //     dropdownOpen: !this.state.dropdownOpen,
  //   });
  // }

  handleToggleClickA() {
    this.props.onSideNavToggleClick();
  }

  render() {
    const navStyle = {
      paddingLeft: this.props.toggle ? "16px" : "240px",
      transition: "padding-left .3s",
    };

    return (
      <Router>
        <MDBNavbar
          className="flexible-MDBNavbar"
          light
          expand="md"
          scrolling
          fixed="top"
          style={{ zIndex: 3 }}
        >
          <div
            onClick={this.handleToggleClickA}
            key="sideNavToggleA"
            style={{
              lineHeight: "32px",
              marginleft: "1em",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="bars" color="white" size="lg" />
          </div>

          <MDBNavbarBrand href="/" style={navStyle}>
            <strong>{this.props.routeName}</strong>
          </MDBNavbarBrand>
          <MDBNavbarNav expand="sm" right style={{ flexDirection: "row" }}>
            <a href="https://touropp.freshdesk.com/support/home" target="_blank" className="help-button mr-3 my-2">
              <MDBIcon className="mr-1" icon="question-circle" size="1x" />
              <span>Help</span>
            </a>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon="user" />{" "}
                <span className="d-none d-md-inline">
                  {this.state.user_name}
                </span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right style={{ minWidth: "200px" }}>
                <MDBDropdownItem
                  onClick={() => this.props.history.push("/user")}
                >
                  <MDBIcon icon="user" /> Settings
                </MDBDropdownItem>
                {this.state.admin_state ? (
                  <MDBDropdownItem
                    onClick={() => {
                      localStorage.removeItem("SMSStatus");
                      this.props.history.push("/admin");
                    }}
                  >
                    <MDBIcon icon="user" /> Admin page
                  </MDBDropdownItem>
                ) : (
                  <MDBDropdownItem
                    onClick={() => this.props.history.push("/logout")}
                  >
                    <MDBIcon icon="user" /> Logout
                  </MDBDropdownItem>
                )}
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarNav>
        </MDBNavbar>
      </Router>
    );
  }
}

export default withRouter(TopNavigation);
