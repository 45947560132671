// import React from "react";
// import { useJwt } from "react-jwt";

// function GetJWT (props) {
//     console.log("ejecutar jwt")
//     const tk= localStorage.getItem("jwt") //constante tiene token completo
//     // const { decodedToken, isExpired } = useJwt(tk);
//     const { decodedToken, isExpired, reEvaluateToken } = useJwt("");
//     reEvaluateToken(tk);
//     if(tk===undefined || tk===null){
//         console.log("--false");
//         return false
//     }else{
//         console.log("--true este este",tk,decodedToken);
//         return tk
//     }

// }

const getJWT = () => {
  // console.log("execute jwt")
  const tk = localStorage.getItem("jwt"); //complete token
  if (tk === undefined || tk === null) {
    // console.log("--false");
    return false;
  } else {
    // console.log("--true",tk);
    return tk;
  }
};

export default getJWT;
