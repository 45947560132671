import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBDataTable,
  MDBModalBody,
  MDBRow,
  MDBSpinner,
} from "mdbreact";

import moment from "moment";
import api from "../../../helpers/api";
import config_table from "./01-config_transactions_received_table";
import config_service_table from "./02-config_service_table";

class TransactionsReceived extends React.Component {
  state = {
    loadData: true,
    bookings: {
      columns: config_table,
      rows: [],
    },

    conversations: {
      columns: config_service_table,
      rows: [],
    },
  };
  ///
  async componentDidMount() {
    // this.loadData();
    const rta = await api("dashboard_statistics_data", "application/json", {
      initialDate: moment().startOf("day").format("YYYY-MM-DD"),
      endDate: moment().add(1, "days").startOf("day").format("YYYY-MM-DD"),
    });
    // console.log("rta :>> ", rta);
    const statistics = rta.data.reduce(
      (obj, element) => {
        obj.newBookings = element.newBookings;
        obj.canceledBookings = element.canceledBookings;
        obj.accepted = element.acceptedConversations;
        obj.stopped = element.stoppedConversations;
        // console.log("element :>> ", element);
        if (element.newBookings === 0) {
          obj.percentCanceled = "---";
        } else {
          obj.percentCanceled = (
            (element.canceledBookings / element.newBookings) *
            100
          ).toFixed(2);
        }

        let totalConersations =
          element.acceptedConversations + element.stoppedConversations;

        if (totalConersations === 0) {
          obj.percentAccepted = "---";
          obj.percentStopped = "---";
        } else {
          obj.percentAccepted = (
            (element.acceptedConversations / totalConersations) *
            100
          ).toFixed(2);
          obj.percentStopped = (
            (element.stoppedConversations / totalConersations) *
            100
          ).toFixed(2);
        }
        return obj;
      },
      {
        newBookings: 0,
        canceledBookings: 0,
        percentCanceled: "",
        accepted: 0,
        percentAccepted: "",
        stopped: 0,
        percentStopped: "",
      }
    );
    // console.log("statistics :>> ", statistics);

    this.setState({
      bookings: {
        ...this.state.bookings,
        rows: [
          {
            transaction: "New",
            quantity: statistics.newBookings,
            percent: "---",
          },
          {
            transaction: "Canceled",
            quantity: statistics.canceledBookings,
            percent: statistics.percentCanceled.toString() + " %",
          },
        ],
      },
      conversations: {
        ...this.state.conversations,
        rows: [
          {
            transaction: "Accepted",
            quantity: statistics.accepted,
            percent: statistics.percentAccepted.toString() + " %",
          },
          {
            transaction: "Stopped",
            quantity: statistics.stopped,
            percent: statistics.percentStopped.toString() + " %",
          },
        ],
      },
      loadData: false,
    });
  }

  loadData = async () => {
    this.setState({
      loadData: true,
    });
    try {
      // console.log("transaction---->", this.props.startDate, this.props.endDate);
      const query = {
        initial_date: this.props.startDate,
        finishing_date: this.props.endDate,
      };
      const a = await api("bookings_dashboard", "application/json", query);

      // console.log(
      //   a.data.canceled,
      //   a.data.booking,
      //   a.data.canceled / a.data.booking
      // );

      let percentCanceled = 0;
      if (a.data.booking === 0) {
        percentCanceled = "---";
      } else {
        percentCanceled = ((a.data.canceled / a.data.booking) * 100).toFixed(2);
      }

      let totalOkStop = a.data.okMessage + a.data.stopMessage;
      let percentOk = 0;
      let percentStop = 0;

      if (totalOkStop === 0) {
        percentOk = "---";
        percentStop = "---";
      } else {
        percentOk = ((a.data.okMessage / totalOkStop) * 100).toFixed(2);
        percentStop = ((a.data.stopMessage / totalOkStop) * 100).toFixed(2);
      }

      this.setState({
        data: {
          ...this.state.data,
          rows: [
            {
              transaction: "New",
              quantity: a.data.booking,
              percent: "---",
            },
            {
              transaction: "Canceled",
              quantity: a.data.canceled,
              percent: percentCanceled.toString() + " %",
            },
          ],
        },
        data1: {
          ...this.state.data1,
          rows: [
            {
              transaction: "Accepted",
              quantity: a.data.okMessage,
              percent: percentOk.toString() + " %",
            },
            {
              transaction: "Stopped",
              quantity: a.data.stopMessage,
              percent: percentStop.toString() + " %",
            },
          ],
        },
        loadData: false,
      });

      // console.log("data dashboard", a.data);
    } catch (e) {}
  };

  // filterData = (allData, dataVerify) => {
  //   return new Promise((resolve, reject) => {
  //     // if (allData.length>0) {
  //     let dataNoVerifyFilter1 = [];

  //     // console.log("filterData-->", allData, dataVerify);

  //     allData.map((val) => {
  //       let noFilterData = true;
  //       dataVerify.map((val1) => {
  //         if (val1.id_sms_message === val.id_sms_message) {
  //           noFilterData = false;
  //         }
  //       });
  //       if (noFilterData) {
  //         dataNoVerifyFilter1.push(val);
  //       }
  //     });

  //     // console.log("dataNoVerifyFilter1", dataNoVerifyFilter1);

  //     let dataNoVerifyFilter2 = [];

  //     dataNoVerifyFilter1.map((val) => {
  //       let totalNumbers = val.body.replace(/[^0]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^1]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^2]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^3]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^4]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^5]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^6]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^7]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^8]/g, "").length;
  //       totalNumbers = totalNumbers + val.body.replace(/[^9]/g, "").length;
  //       if (totalNumbers === 1) {
  //         dataNoVerifyFilter2.push(val);
  //       }
  //     });

  //     // console.log("dataNoVerifyFilter2", dataNoVerifyFilter2);

  //     let dataNoVerifyFilter3 = [];

  //     dataNoVerifyFilter2.map((val) => {
  //       if (val.body.length <= 50) {
  //         dataNoVerifyFilter3.push(val);
  //       }
  //     });

  //     // console.log("dataNoVerifyFilter3", dataNoVerifyFilter3);
  //     resolve(dataNoVerifyFilter3);
  //     // }
  //   });
  // };

  render() {
    // console.log(this.state.data);
    return (
      <React.Fragment>
        <MDBCard className="text-left">
          <MDBCardHeader className="white-text color_select text-left">
            Statistics from the past 30 days
          </MDBCardHeader>
          <MDBCardBody>
            {this.state.loadData ? (
              <MDBRow center style={{ height: "8vh" }}>
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            ) : (
              <React.Fragment>
                <MDBRow>
                  <MDBCol md="12">
                    <MDBDataTable
                      striped
                      searching={false}
                      bordered
                      small
                      paging={false}
                      btn
                      maxHeight="300px"
                      className="table_warp"
                      data={this.state.bookings}
                      fixed
                      sortable={false}
                      noBottomColumns
                    ></MDBDataTable>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="12">
                    <MDBDataTable
                      striped
                      searching={false}
                      bordered
                      small
                      paging={false}
                      btn
                      maxHeight="300px"
                      className="table_warp"
                      data={this.state.conversations}
                      fixed
                      sortable={false}
                      noBottomColumns
                    ></MDBDataTable>
                  </MDBCol>
                </MDBRow>
              </React.Fragment>
            )}
          </MDBCardBody>
        </MDBCard>
      </React.Fragment>
    );
  }
}

export default TransactionsReceived;
