export default [
  {
    label: "Id_msg / Status",
    field: "id",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Shipping time",
    field: "date_send",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Creation Time",
    field: "creation_time",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Command / Origin",
    field: "command",
    sort: "asc",
    minimal: "size-col-10",
  },
  {
    label: "Message",
    field: "msg",
    sort: "asc",
    minimal: "size-col-30",
  },
  {
    label: "Actions",
    field: "buttons",
    sort: "asc",
    minimal: "size-col-10 ",
  },
];
