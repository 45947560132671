import React, { Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  toast,
  ToastContainer,
  MDBSpinner,
  MDBDatePicker,
  MDBSelect,
} from "mdbreact";
import { withRouter, Redirect, Route } from "react-router-dom";
import MDBDataTable from "../../source-pack/DataTable/DataTable";
import Side_modal from "../modal_side";
import Form_new_msg from "../form_new_automated_message";
import Butons_bar from "./03-buttons_bookings";
import ButtonsBarAllBookings from "./04-buttons_all_bookings";

import Config_table from "../04-bookings/01-config_table_products";
import api from "../../helpers/api";
import moment from "moment";

class Products_list extends React.Component {
  state = {
    redirect: false,
    open: false,

    load_data: false,

    stateButtonMoreValid: false,
    stateButtonMoreAll: false,

    startDate: moment().subtract(5, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    startDateToSearch: moment().subtract(5, "d").format("YYYY-MM-DD"),
    endDateToSearch: moment().format("YYYY-MM-DD"),

    dataParams: {
      limit: 50,
      counterValid: 0,
      counterAll: 0,
    },
    dataParamsToSearch: {
      limit: 50,
      counterValid: 0,
      counterAll: 0,
    },
    limitDataArray: [
      { name: "0-50", value: 50, activeMore: false },
      { name: "0-100", value: 100, activeMore: false },
      { name: "All", value: 200, activeMore: true },
    ],
    limitDataSelected: "",
    limitDataOptions: [],
    validTransactionsIds: [],
    allTransactionsIds: [],

    bookingSystemsOptions: [],
    bookingSystemSelected: "",

    validTransactions: { columns: [], rows: [] },
    counterValidTransactions: 0,

    allTransactions: { columns: [], rows: [] },
    counterAllTransactions: 0,
  };

  async componentDidMount() {
    try {
      this.setState({ load_data: false });

      const loadFilter = await this.loadFilterData();

      const [loadValidTransactionsIds, loadAllTransactionsIds] =
        await this.loadAllAndValidIdData(this.state.dataParamsToSearch);

      const loadValidTransactions = await this.loadValidTransactionsData(
        loadValidTransactionsIds
      );

      const loadAllTransactions = await this.loadAllTransactionsData(
        loadAllTransactionsIds
      );

      this.setState({ load_data: true });
    } catch (error) {
      console.log("error :>> ", error);
      console.error("Error in componentDidMount - 00-Booking_list");
    }
  }

  loadFilterData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const getFilterData = await this.getFilterData();

        const validFilterData = await this.validFilterData(getFilterData);

        const [buildBookingSystems, buildLimitDataOptions] =
          await this.buildFilterData(validFilterData);

        const saveBookingSystemsData = await this.saveFilterData(
          buildBookingSystems,
          buildLimitDataOptions
        );

        resolve(true);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `loadBookingSystemData - file 00-Booking_list`,
        });
      }
    });
  };

  getFilterData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const resBookingSystems = await api(
          "bookingsGetBookingSystemsByUser",
          "application/json"
        );

        const getBookingSystems = resBookingSystems.data.data;

        resolve(getBookingSystems);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `getFilterData - file 00-Booking_list`,
        });
      }
    });
  };

  validFilterData = async (bookingSystemsToValid) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!Array.isArray(bookingSystemsToValid)) {
          throw { message: "Data isn't an array" };
        }

        if (bookingSystemsToValid.length === 0) {
          throw { message: "No data to validate in bookingSystemsToValid" };
        }

        bookingSystemsToValid.map((val) => {
          if (typeof val.booking_system !== "string") {
            throw {
              message: `booking system must be a string ${val.booking_system}`,
            };
          }
        });
        resolve(bookingSystemsToValid);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `validFilterData - file 00-Booking_list`,
        });
      }
    });
  };

  buildFilterData = async (bookingSystemsToBuild) => {
    return new Promise(async (resolve, reject) => {
      try {
        const bookingSystemsFirstLetterUp = [];
        bookingSystemsToBuild.map((val) => {
          bookingSystemsFirstLetterUp.push(
            val.booking_system.charAt(0).toUpperCase() +
              val.booking_system.slice(1)
          );
        });

        const bookingSystemsSorted = bookingSystemsFirstLetterUp.sort();

        let builtBookingSystems = [];
        bookingSystemsSorted.map((val, index) => {
          if (index === 0) {
            builtBookingSystems.push({
              checked: true,
              disabled: false,
              icon: null,
              value: `${index + 1}`,
              text: val,
            });
          } else {
            builtBookingSystems.push({
              checked: false,
              disabled: false,
              icon: null,
              value: `${index + 1}`,
              text: val,
            });
          }
        });

        const limitDataArray = this.state.limitDataArray;
        let builtLimitDataOptions = [];

        limitDataArray.map((val, index) => {
          if (index === 0) {
            builtLimitDataOptions.push({
              checked: true,
              disabled: false,
              icon: null,
              value: val.name,
              text: val.name,
            });
          } else {
            builtLimitDataOptions.push({
              checked: false,
              disabled: false,
              icon: null,
              value: val.name,
              text: val.name,
            });
          }
        });

        resolve([builtBookingSystems, builtLimitDataOptions]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `buildFilterData - file 00-Booking_list`,
        });
      }
    });
  };

  saveFilterData = async (bookingSystemsToSave, limitDataOptionsToSave) => {
    return new Promise(async (resolve, reject) => {
      try {
        let bookingSystemSelected = "";
        bookingSystemsToSave.filter((val) => {
          if (val.checked === true) {
            bookingSystemSelected = val.text.toLowerCase();
          }
        });

        this.setState({
          limitDataOptions: limitDataOptionsToSave,
          bookingSystemsOptions: bookingSystemsToSave,
          bookingSystemSelected: bookingSystemSelected,
        });

        // console.log("bookingSystemSelected :>> ", bookingSystemSelected);
        resolve(true);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `saveFilterData - file 00-Booking_list`,
        });
      }
    });
  };

  loadAllAndValidIdData = async (dataParams) => {
    return new Promise(async (resolve, reject) => {
      try {
        const [getValidIds, getAllIds] = await this.getAllAndValidIds();
        const [validValidIds, validAllIds] = await this.validAllAndValidIds(
          getValidIds,
          getAllIds
        );
        const [buildValidIds, buildAllIds] = await this.buildAllAndValidIds(
          validValidIds,
          validAllIds,
          dataParams
        );
        const [lastValidIdsSaved, lastAllIdsSaved] =
          await this.saveAllAndValidIds(buildValidIds, buildAllIds);

        resolve([lastValidIdsSaved, lastAllIdsSaved]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `loadAllAndValidIdData - file 00-Booking_list`,
        });
      }
    });
  };

  getAllAndValidIds = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const resValidTransactionsIds = await api(
          "bookingsGetValidTransactionsIdsByUser",
          "application/json",
          {
            startDate: this.state.startDateToSearch,
            endDate: moment(this.state.endDateToSearch)
              .add(1, "d")
              .format("YYYY-MM-DD"),
            bookingSystem: this.state.bookingSystemSelected,
          }
        );

        const getValidTransactionsIds = resValidTransactionsIds.data.data;

        const resAllTransactionsIds = await api(
          "bookingsGetAllTransactionsIdsByUser",
          "application/json",
          {
            startDate: this.state.startDateToSearch,
            endDate: moment(this.state.endDateToSearch)
              .add(1, "d")
              .format("YYYY-MM-DD"),
            bookingSystem: this.state.bookingSystemSelected,
          }
        );

        const getAllTransactionsIds = resAllTransactionsIds.data.data;

        resolve([getValidTransactionsIds, getAllTransactionsIds]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `getAllAndValidIds - file 00-Booking_list`,
        });
      }
    });
  };

  validAllAndValidIds = async (
    validTransactionsIdsToValid,
    allTransactionsIdsToValid
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!Array.isArray(validTransactionsIdsToValid)) {
          throw { message: "The valid transactions must be an array" };
        }

        if (!Array.isArray(allTransactionsIdsToValid)) {
          throw { message: "The all transactions must be an array" };
        }

        resolve([validTransactionsIdsToValid, allTransactionsIdsToValid]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `validAllAndValidIds - file 00-Booking_list`,
        });
      }
    });
  };

  buildAllAndValidIds = async (
    validTransactionsIdsToBuild,
    allTransactionsIdsToBuild,
    dataParams
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const limitDataPerGroup = dataParams.limit;

        const validTransactionsClean = [];
        let groupValidTransactions = [];

        if (validTransactionsIdsToBuild.length > 0) {
          validTransactionsIdsToBuild.map((val) => {
            validTransactionsClean.push(val.id_webhook);
          });

          const groupNumberValidTransactions = Math.ceil(
            validTransactionsClean.length / limitDataPerGroup
          );

          for (let i = 0; i < groupNumberValidTransactions; i++) {
            groupValidTransactions.push(
              validTransactionsClean.slice(
                i * limitDataPerGroup,
                (i + 1) * limitDataPerGroup
              )
            );
          }
        }

        const allTransactionsClean = [];
        let groupAllTransactions = [];

        if (allTransactionsIdsToBuild.length > 0) {
          allTransactionsIdsToBuild.map((val) => {
            allTransactionsClean.push(val.id_webhook);
          });

          const groupNumberAllTransactions = Math.ceil(
            allTransactionsClean.length / limitDataPerGroup
          );

          for (let i = 0; i < groupNumberAllTransactions; i++) {
            groupAllTransactions.push(
              allTransactionsClean.slice(
                i * limitDataPerGroup,
                (i + 1) * limitDataPerGroup
              )
            );
          }
        }

        resolve([groupValidTransactions, groupAllTransactions]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `buildAllAndValidIds - file 00-Booking_list`,
        });
      }
    });
  };

  saveAllAndValidIds = async (
    validTransactionsIdsToSave,
    allTransactionsIdsToSave
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let validTransactionsIdsData = [];
        let allTransactionsIdsData = [];

        if (validTransactionsIdsToSave.length > 0) {
          validTransactionsIdsData = validTransactionsIdsToSave[0];
        }

        if (allTransactionsIdsToSave.length > 0) {
          allTransactionsIdsData = allTransactionsIdsToSave[0];
        }

        this.setState({
          validTransactionsIds: validTransactionsIdsToSave,
          allTransactionsIds: allTransactionsIdsToSave,
        });

        resolve([validTransactionsIdsData, allTransactionsIdsData]);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source: error.source || `saveAllAndValidIds - file 00-Booking_list`,
        });
      }
    });
  };

  loadValidTransactionsData = async (validTransactionsIds) => {
    return new Promise(async (resolve, reject) => {
      try {
        const getValidTransactions = await this.getValidTransactionsData(
          validTransactionsIds
        );

        const validValidTransactions = await this.validValidTransactionsData(
          getValidTransactions
        );

        const buildValidTransactions = await this.buildValidTransactionsData(
          validValidTransactions
        );

        const saveValidTransactions = await this.saveValidTransactionsData(
          buildValidTransactions
        );

        resolve(saveValidTransactions);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `loadValidTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  getValidTransactionsData = async (validTransactionsIds) => {
    return new Promise(async (resolve, reject) => {
      try {
        let getValidTransactions = [];

        if (validTransactionsIds.length > 0) {
          const resValidTransactions = await api(
            "bookingsGetValidTransactionsByUser",
            "application/json",
            {
              startDate: this.state.startDateToSearch,
              endDate: moment(this.state.endDateToSearch)
                .add(1, "d")
                .format("YYYY-MM-DD"),
              validTransactionsIds: validTransactionsIds,
              bookingSystem: this.state.bookingSystemSelected,
            }
          );

          getValidTransactions = resValidTransactions.data.data;
        }

        resolve(getValidTransactions);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `getValidTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  validValidTransactionsData = async (validTransactionsToValid) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!Array.isArray(validTransactionsToValid)) {
          throw { message: "The valid transactions must be an array" };
        }

        resolve(validTransactionsToValid);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `validValidTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  buildValidTransactionsData = async (validTransactionsToBuild) => {
    return new Promise(async (resolve, reject) => {
      try {
        let buildValidTransactions = [];

        if (validTransactionsToBuild.length > 0) {
          validTransactionsToBuild.map(async (val) => {
            buildValidTransactions.push({
              id_webhook: `${val.id_webhook}`,
              json: val.json,
              estado: val.status,
              sistema: val.system,
              buttons: (
                <Butons_bar
                  id_={val.id_webhook}
                  // refresh={this.load_data.bind(this)}
                  data={val}
                  update={this.update_product.bind(this)}
                />
              ),
            });
          });
        }

        resolve(buildValidTransactions);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `buildValidTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  saveValidTransactionsData = async (validTransactionsToSave) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({
          validTransactions: {
            columns: Config_table,
            rows: this.state.validTransactions.rows.concat(
              validTransactionsToSave
            ),
          },
          counterValidTransactions: this.state.validTransactions.rows.concat(
            validTransactionsToSave
          ).length,
        });
        resolve(validTransactionsToSave.length);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `saveValidTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  loadAllTransactionsData = async (allTransactionsIds) => {
    return new Promise(async (resolve, reject) => {
      try {
        const getAllTransactions = await this.getAllTransactionsData(
          allTransactionsIds
        );

        const validAllTransactions = await this.validAllTransactionsData(
          getAllTransactions
        );

        const buildAllTransactions = await this.buildAllTransactionsData(
          validAllTransactions
        );

        const saveAllTransactions = await this.saveAllTransactionsData(
          buildAllTransactions
        );

        resolve(saveAllTransactions);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `loadAllTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  getAllTransactionsData = async (allTransactionsIds) => {
    return new Promise(async (resolve, reject) => {
      try {
        let getAllTransactions = [];

        if (allTransactionsIds.length > 0) {
          const resAllTransactions = await api(
            "bookingsGetAllBookingsByUser",
            "application/json",
            {
              startDate: this.state.startDateToSearch,
              endDate: moment(this.state.endDateToSearch)
                .add(1, "d")
                .format("YYYY-MM-DD"),
              allTransactionsIds: allTransactionsIds,
              bookingSystem: this.state.bookingSystemSelected,
            }
          );

          getAllTransactions = resAllTransactions.data.data;
        }

        resolve(getAllTransactions);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `getAllTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  validAllTransactionsData = async (transactionsToValid) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!Array.isArray(transactionsToValid)) {
          throw { message: "The transactions must be an array" };
        }

        resolve(transactionsToValid);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `validAllTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  buildAllTransactionsData = async (transactionsToBuild) => {
    return new Promise(async (resolve, reject) => {
      try {
        let buildTransactions = [];

        if (transactionsToBuild.length > 0) {
          transactionsToBuild.map(async (val) => {
            buildTransactions.push({
              id_webhook: `${val.id_webhook}`,
              json: val.json,
              estado: val.status,
              sistema: val.system,
              buttons: (
                <ButtonsBarAllBookings
                  id_={val.id_webhook}
                  // refresh={this.load_data.bind(this)}
                  data={val}
                  update={this.update_product.bind(this)}
                />
              ),
            });
          });
        }

        resolve(buildTransactions);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `buildAllTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  saveAllTransactionsData = async (transactionsToSave) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({
          allTransactions: {
            columns: Config_table,
            rows: this.state.allTransactions.rows.concat(transactionsToSave),
          },
          counterAllTransactions:
            this.state.allTransactions.rows.concat(transactionsToSave).length,
        });
        resolve(transactionsToSave.length);
      } catch (error) {
        reject({
          message: error.message,
          stack: error.stack,
          code: error.code,
          description: error.description,
          file: error.file || __filename,
          source:
            error.source || `saveAllTransactionsData - file 00-Booking_list`,
        });
      }
    });
  };

  update_product = async (id, msg, bot) => {
    const data = {
      idusr_tours: id,
      ignore_template_msg: msg,
      ignore_template_bot: bot,
    };
    // const res = await api("update_product", "application/json", data);
    // this.load_data();
    // console.log("update result:", data);
  };

  search = async () => {
    try {
      this.setState({
        load_data: false,
        validTransactions: { columns: [], rows: [] },
        allTransactions: { columns: [], rows: [] },
      });

      const [loadValidTransactionsIds, loadAllTransactionsIds] =
        await this.loadAllAndValidIdData(this.state.dataParams);

      const loadValidTransactions = await this.loadValidTransactionsData(
        loadValidTransactionsIds
      );

      const loadAllTransactions = await this.loadAllTransactionsData(
        loadAllTransactionsIds
      );

      this.state.limitDataArray.map((val) => {
        if (val.name === this.state.limitDataSelected) {
          this.setState({
            stateButtonMoreValid: val.activeMore,
            stateButtonMoreAll: val.activeMore,
          });
        }
      });

      if (
        this.state.limitDataSelected === "All" &&
        this.state.validTransactionsIds.length < 2
      ) {
        this.setState({
          stateButtonMoreValid: false,
        });
      }

      if (
        this.state.limitDataSelected === "All" &&
        this.state.allTransactionsIds.length < 2
      ) {
        this.setState({
          stateButtonMoreAll: false,
        });
      }

      this.setState({
        load_data: true,
        dataParamsToSearch: this.state.dataParams,
      });
    } catch (error) {
      console.log("error :>> ", error);
      console.error("Error in search - 00-Booking_list");
    }
  };

  moreValidTransactions = async (eventMoreValid) => {
    try {
      eventMoreValid.preventDefault();

      const counterValid = this.state.dataParamsToSearch.counterValid + 1;

      this.setState({
        loaderButtonMoreValid: true,
      });

      const loadValidTransactions = await this.loadValidTransactionsData(
        this.state.validTransactionsIds[counterValid]
      );

      if (loadValidTransactions < this.state.dataParamsToSearch.limit) {
        this.setState({
          stateButtonMoreValid: false,
        });
      }

      this.setState({
        loaderButtonMoreValid: false,
        dataParamsToSearch: {
          ...this.state.dataParamsToSearch,
          counterValid: counterValid,
        },
      });
    } catch (error) {
      console.log("error :>> ", error);
      console.error("Error in moreValidTransactions - 00-Booking_list");
    }
  };

  moreAllTransactions = async (eventMoreAll) => {
    try {
      eventMoreAll.preventDefault();

      const counterAll = this.state.dataParamsToSearch.counterAll + 1;

      this.setState({
        loaderButtonMoreAll: true,
      });

      const loadAllTransactions = await this.loadAllTransactionsData(
        this.state.allTransactionsIds[counterAll]
      );

      if (loadAllTransactions < this.state.dataParamsToSearch.limit) {
        this.setState({
          stateButtonMoreAll: false,
        });
      }

      this.setState({
        loaderButtonMoreAll: false,
        dataParamsToSearch: {
          ...this.state.dataParamsToSearch,
          counterAll: counterAll,
        },
      });
    } catch (error) {
      console.log("error :>> ", error);
      console.error("Error in moreAllTransaction - 00-Booking_list");
    }
  };

  setSelect = (data) => {
    this.setState({
      bookingSystemSelected: data.toLowerCase(),
    });
  };

  setSelectLimit = (data) => {
    this.state.limitDataArray.map((val) => {
      if (val.name === data) {
        this.setState({
          limitDataSelected: val.name,
          dataParams: {
            ...this.state.dataParams,
            limit: val.value,
          },
        });
      }
    });
  };

  render() {
    return (
      <MDBRow>
        {this.state.redirect ? <Redirect to="/logout" /> : ""}
        <Side_modal open={this.state.open} />

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          autoClose={5000}
        />

        <MDBCol lg="12">
          <MDBCard className="text-left">
            <MDBCardHeader className="white-text color_select p-4 text-left">
              <MDBIcon far icon="calendar-check" /> All Bookings
            </MDBCardHeader>

            <MDBCardBody>
              <MDBRow className="espace">
                <MDBCol md="3">
                  <div className="md-form">
                    <MDBDatePicker
                      // value={this.state.initial_date}
                      // getValue={(val) => this.setState({ initial_date: val })}
                      value={this.state.startDate}
                      getValue={(val) =>
                        this.setState({
                          startDate: val,
                          startDateToSearch: moment(val).format("YYYY-MM-DD"),
                        })
                      }
                      format="DD-MM-YYYY"
                    />
                  </div>
                </MDBCol>

                <MDBCol md="3">
                  <div className="md-form">
                    <MDBDatePicker
                      // value={this.state.finishing_date}
                      // getValue={(val) => this.setState({ finishing_date: val })}
                      value={this.state.endDate}
                      getValue={(val) =>
                        this.setState({
                          endDate: val,
                          endDateToSearch: moment(val).format("YYYY-MM-DD"),
                        })
                      }
                      format="DD-MM-YYYY"
                    />
                  </div>
                </MDBCol>

                <MDBCol md="2">
                  <div className="md-form">
                    <MDBSelect
                      // options={this.state.options}
                      options={this.state.bookingSystemsOptions}
                      color="primary"
                      label="System"
                      name="system"
                      className="text_1"
                      getTextContent={this.setSelect.bind(this)}
                    />
                  </div>
                </MDBCol>

                <MDBCol md="2">
                  <div className="md-form">
                    <MDBSelect
                      // options={this.state.limitOptions}
                      options={this.state.limitDataOptions}
                      color="primary"
                      label="Limit"
                      name="limit"
                      className="text_1"
                      getTextContent={this.setSelectLimit.bind(this)}
                    />
                  </div>
                </MDBCol>

                <MDBBtn color="deep-orange" type="button" onClick={this.search}>
                  Search
                </MDBBtn>
              </MDBRow>
            </MDBCardBody>

            {this.state.load_data ? (
              <MDBCol lg="12">
                <MDBCard className="text-left">
                  <MDBCardHeader className="white-text color_select p-4 text-left">
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon far icon="calendar-check" /> Result Valid
                        Bookings...../ {this.state.counterValidTransactions}
                      </MDBCol>
                      <MDBCol className="text-right">
                        {this.state.stateButtonMoreValid ? (
                          <a
                            href=""
                            // onClick={(e) => this.moreDataFilter(e)}
                            onClick={(e) => this.moreValidTransactions(e)}
                            className="view-more mr-3 my-2"
                          >
                            {/* {this.state.loadDataButtonFilter ? ( */}
                            {this.state.loaderButtonMoreValid ? (
                              <MDBSpinner red />
                            ) : (
                              <span>View more</span>
                            )}
                          </a>
                        ) : (
                          ""
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCardHeader>

                  <MDBCardBody>
                    <MDBDataTable
                      // scrollY
                      // maxHeight="500px"
                      striped
                      bordered
                      small
                      paging={false}
                      btn
                      maxHeight="300px"
                      className="table_warp"
                      // data={this.state.data}
                      data={this.state.validTransactions}
                      fixed
                    ></MDBDataTable>
                  </MDBCardBody>
                </MDBCard>
                <br />
                <MDBCard className="text-left">
                  <MDBCardHeader className="white-text color_select p-4 text-left">
                    <MDBRow>
                      <MDBCol>
                        <MDBIcon far icon="calendar-check" /> Result All
                        Bookings / {this.state.counterAllTransactions}
                      </MDBCol>
                      <MDBCol className="text-right">
                        {this.state.stateButtonMoreAll ? (
                          <a
                            href=""
                            onClick={(e) => this.moreAllTransactions(e)}
                            className="view-more mr-3 my-2"
                          >
                            {/* {this.state.loadDataButtonAll ? ( */}
                            {this.state.loaderButtonMoreAll ? (
                              <MDBSpinner red />
                            ) : (
                              <span>View more</span>
                            )}
                          </a>
                        ) : (
                          ""
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCardHeader>

                  <MDBCardBody>
                    <MDBDataTable
                      // scrollY
                      // maxHeight="500px"
                      striped
                      bordered
                      small
                      paging={false}
                      btn
                      maxHeight="300px"
                      className="table_warp"
                      // data={this.state.data_1}
                      data={this.state.allTransactions}
                      fixed
                    ></MDBDataTable>
                  </MDBCardBody>
                </MDBCard>
                <br />
              </MDBCol>
            ) : (
              <MDBRow
                className="page-loading"
                center
                style={{ height: "80vh" }}
              >
                <MDBCol lg="3">
                  <MDBSpinner />
                </MDBCol>
              </MDBRow>
            )}
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default withRouter(Products_list);
